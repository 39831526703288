import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Button} from "../../ui/Button";
import {Textarea} from "../../ui/Textarea";
import {Input} from "../../ui/Input";
import CityInfoTool from "../../cdek/tools/CityInfoTool";

import 'rc-slider/assets/index.css';

import Slider, {Range} from 'rc-slider';

import {
    PARTNERS_OPTIONS,
    PARTNER_TARIFFS_TYPES_OPTIONS,
    CUSTOMER_TARIFFS_TYPES_OPTIONS,
    DELIVERY_TYPES_OPTIONS
} from "../../../helpers/TariffsHelper";

import Select from 'react-select'
import CitySearchableSelect from "../../cdek/tools/CitySearchableSelect";

const DEFAULT_PERC = 10;
const DEFAULT_FIX = 140;


export default function UpdateTariffForm(props) {
    const {
        onSave = d => {

        },
        loading = false,
        canDelete = false,
        onDelete = () => {

        }
    } = props;

    const [delivery_type, setDelivery_type] = useState(props.delivery_type == undefined ? DELIVERY_TYPES_OPTIONS[0].value : props.delivery_type);
    const [partner_discount_type, setPartner_discount_type] = useState(props.partner_discount_type == undefined ? PARTNER_TARIFFS_TYPES_OPTIONS[0].value : props.partner_discount_type);
    const [partner_base_price, setPartner_base_price] = useState(props.partner_base_price == undefined ? 1000 : props.partner_base_price);
    const [partner_fix_discount, setPartner_fix_discount] = useState(props.partner_fix_discount == undefined ? DEFAULT_FIX : props.partner_fix_discount);
    const [partner_percent_discount, setPartner_percent_discount] = useState(props.partner_percent_discount == undefined ? DEFAULT_PERC : props.partner_percent_discount);

    const [customer_percent_discount, setCustomer_percent_discount] = useState(props.customer_percent_discount == undefined ? DEFAULT_PERC : props.customer_percent_discount);

    const [partner, setPartner] = useState(props.partner == undefined ? PARTNERS_OPTIONS[0].value : props.partner);
    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);

    const [partner_base_weight, setPartner_base_weight] = useState(props.partner_base_weight == undefined ? 0.0 : props.partner_base_weight);
    const [partner_ext_price, setPartner_ext_price] = useState(props.partner_ext_price == undefined ? 0.0 : props.partner_ext_price);

    const [from_code, setFrom_code] = useState(props.from_code);
    const [to_code, setTo_code] = useState(props.to_code);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Название тарифа
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Тип доставки
                </Label>
                <Select options={DELIVERY_TYPES_OPTIONS}
                        placeholder={'Выберите партнера'}
                        onChange={a => {
                            setDelivery_type(a.value);
                        }}
                        value={DELIVERY_TYPES_OPTIONS.filter(a => (a.value == delivery_type))[0]}
                />
            </Field>

            <Field>
                <Label>
                    Описание тарифа
                </Label>
                <Textarea value={description} onChange={evt => {
                    setDescription(evt.target.value);
                }}/>
            </Field>

            <Row>
                <Field>
                    <Label>
                        Откуда
                    </Label>
                    {/*<Input value={from_code} onChange={evt => {*/}
                    {/*setFrom_code(evt.target.value);*/}
                    {/*}}/>*/}
                    <CitySearchableSelect code={from_code} onChange={newCode => {
                        setFrom_code(newCode);
                    }}/>
                    <CityInfoTool code={from_code}/>
                </Field>
                <Field>
                    <Label>
                        Куда
                    </Label>
                    {/*<Input value={to_code} onChange={evt => {*/}
                    {/*setTo_code(evt.target.value);*/}
                    {/*}}/>*/}
                    <CitySearchableSelect code={to_code} onChange={newCode => {
                        setTo_code(newCode);
                    }}/>
                    <CityInfoTool code={to_code}/>
                </Field>
            </Row>

            {/*<Row style={{opacity: 0.5}}>*/}
            {/*<Field>*/}
            {/*<Label>*/}
            {/*from region code*/}
            {/*</Label>*/}
            {/*<Input value={fromRegionCode} onChange={evt => {*/}
            {/*setFromRegionCode(evt.target.value);*/}
            {/*}}/>*/}
            {/*</Field>*/}
            {/*<Field>*/}
            {/*<Label>*/}
            {/*to region code*/}
            {/*</Label>*/}
            {/*<Input value={toRegionCode} onChange={evt => {*/}
            {/*setToRegionCode(evt.target.value);*/}
            {/*}}/>*/}
            {/*</Field>*/}
            {/*</Row>*/}

            <Field>
                <Label>
                    Партнер
                </Label>
                <Select options={PARTNERS_OPTIONS}
                        placeholder={'Выберите партнера'}
                        onChange={a => {
                            setPartner(a.value);
                        }}
                        value={PARTNERS_OPTIONS.filter(a => (a.value == partner))[0]}
                />
            </Field>

            <Section>
                <Field>
                    <Label>
                        Тип скидки, которую дает ПАРТНЕР - ПЭЙКИПЕРУ
                    </Label>
                    <Select options={PARTNER_TARIFFS_TYPES_OPTIONS}
                            placeholder={'Выберите тип скидки'}
                            onChange={a => {
                                setPartner_discount_type(a.value);
                            }}
                            value={PARTNER_TARIFFS_TYPES_OPTIONS.filter(a => (a.value == partner_discount_type))[0]}
                    />
                </Field>

                {partner_discount_type != 'fix-price' ? null :
                    <div>
                        <Field>
                            <Label>
                                Фиксированная стоимость доставки
                            </Label>
                            <Input value={partner_base_price} onChange={evt => {
                                setPartner_base_price(evt.target.value);
                            }}/>
                        </Field>
                        <Field>
                            <Label>
                                Базовый вес
                            </Label>
                            <Input value={partner_base_weight} onChange={evt => {
                                setPartner_base_weight(evt.target.value);
                            }}/>
                        </Field>
                        <Field>
                            <Label>
                                Цена за кг сверх базового веса
                            </Label>
                            <Input value={partner_ext_price} onChange={evt => {
                                setPartner_ext_price(evt.target.value);
                            }}/>
                        </Field>
                    </div>
                }
                {partner_discount_type != 'percent-discount' ? null :
                    <Field>
                        <Label>
                            Размер скидки в процентах
                        </Label>
                        <Slider min={0} max={100} value={partner_percent_discount} step={0.1} onChange={a => {
                            setPartner_percent_discount(a);
                        }}/>
                        <div>
                            {`${partner_percent_discount}%`}
                        </div>
                    </Field>
                }
                {partner_discount_type != 'fix-discount' ? null :
                    <Field>
                        <Label>
                            Фиксированная стоимость скидки
                        </Label>
                        <Input value={partner_fix_discount} onChange={evt => {
                            setPartner_fix_discount(evt.target.value);
                        }}/>
                    </Field>
                }
            </Section>

            <Section>

                <Field>
                    <Label>
                        Доля скидки, отдаваемая клиенту
                    </Label>
                    <Slider min={0} max={100} value={customer_percent_discount} step={0.1} onChange={a => {
                        setCustomer_percent_discount(a);
                    }}/>
                    <div>
                        {`${customer_percent_discount}%`}
                    </div>
                </Field>

            </Section>

            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button onClick={() => {
                        onSave({
                            delivery_type,
                            from_code,
                            to_code,
                            name,
                            description,
                            partner,
                            partner_discount_type,
                            partner_base_price: +partner_base_price,
                            partner_ext_price: +partner_ext_price,
                            partner_base_weight: +partner_ext_price,

                            partner_fix_discount: +partner_fix_discount,
                            partner_percent_discount: +partner_percent_discount,
                            customer_percent_discount: +customer_percent_discount
                        });
                    }}>
                        Сохранить
                    </Button>
                }
            </Field>

            {canDelete == false ? null :
                <Field style={{marginTop: 200}}>
                    <DangerSpan onClick={() => {
                        if (window.confirm('Вы уверены?') == false) {
                            return;
                        }
                        onDelete();
                    }}>
                        удалить
                    </DangerSpan>
                </Field>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;


const Field = styled.div`
    margin-bottom: 10px;
    flex: 1;
    :last-of-type{
      padding-left: 5px;
    }
    :first-of-type{
      padding-right: 5px;
    }
`;

const Label = styled.div`
    font-weight: bold;
    margin-bottom: 3px;
`;

const SpanButton = styled.span`
    opacity: 0.5;
    cursor: pointer;
    font-size: 12px;
    font-style: italic;
    margin-left: 10px;
    :hover{
      opacity: 1;
      text-decoration: underline;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const DangerSpan = styled.span`
    cursor: pointer;
    opacity: 0.5;
    color: red;
    :hover{
      opacity: 1;
    }
`;

const Section = styled.div`
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    background-color: whitesmoke;
`;
