import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Button} from "../../ui/Button";
import {Textarea} from "../../ui/Textarea";
import {Input} from "../../ui/Input";

import 'react-datepicker/dist/react-datepicker.min.css'
import DatePicker from 'react-datepicker'

const genSecretKey = () => {
    return `delivery-secret-${moment().format('DD-MM-YYYY')}-${uuid()}`;
}

export const GOOD_PK_ID_REGEXP = /\d{6}-\d{3}-\d+/;

export default function UpdateCustomerForm(props) {

    const {
        onSave = d => {

        },
        loading = false
    } = props;

    const [email, setEmail] = useState(props.email == undefined ? '' : props.email);
    const [phone, setPhone] = useState(props.phone == undefined ? '' : props.phone);
    const [contact_name, setContact_name] = useState(props.contact_name == undefined ? '' : props.contact_name);

    const [inn, setInn] = useState(props.inn == undefined ? '' : props.inn);
    const [website, setWebsite] = useState(props.website == undefined ? '' : props.website);
    const [legal_entity_name, setLegal_entity_name] = useState(props.legal_entity_name == undefined ? '' : props.legal_entity_name);


    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [paykeeper_id, setPaykeeper_id] = useState(props.paykeeper_id == undefined ? '' : props.paykeeper_id);
    const [secret, setSecret] = useState(props.secret == undefined ? genSecretKey() : props.secret);

    let isGoodPkId = GOOD_PK_ID_REGEXP.test(paykeeper_id);

    return (
        <Wrapper>

            <Field>
                <Label color={(isGoodPkId == true) ? 'green' : 'red'}>
                    Номер договора
                </Label>
                <Input value={paykeeper_id} onChange={evt => {
                    setPaykeeper_id(evt.target.value.trim());
                }}/>
            </Field>

            {/*<Field>*/}
            {/*<Label>*/}
            {/*Название*/}
            {/*</Label>*/}
            {/*<Input value={name} onChange={evt => {*/}
            {/*setName(evt.target.value);*/}
            {/*}}/>*/}
            {/*</Field>*/}

            <Field>
                <Label>
                    Наименование организации
                </Label>
                <Input value={legal_entity_name} onChange={evt => {
                    setLegal_entity_name(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    ИНН
                </Label>
                <Input value={inn} onChange={evt => {
                    setInn(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Вебсайт
                </Label>
                <Input value={website} onChange={evt => {
                    setWebsite(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Контактное лицо
                </Label>
                <Input value={contact_name} onChange={evt => {
                    setContact_name(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Телефон
                </Label>
                <Input value={phone} onChange={evt => {
                    setPhone(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Email
                </Label>
                <Input value={email} onChange={evt => {
                    setEmail(evt.target.value);
                }}/>
            </Field>


            <Field>
                <Label>
                    SECRET
                </Label>
                <div>
                    {secret}
                    <SpanButton onClick={() => {
                        setSecret(genSecretKey());
                    }}>
                        сменить SECRET
                    </SpanButton>
                </div>
            </Field>

            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button disabled={!isGoodPkId} onClick={() => {
                        if (isGoodPkId == false) {
                            return;
                        }
                        onSave({
                            secret, name, paykeeper_id,
                            inn, legal_entity_name, website,
                            phone, email, contact_name
                        });
                    }}>
                        Сохранить
                    </Button>
                }
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
    margin-bottom: 3px;
    color: ${props => (props.color == undefined ? 'black' : props.color)};
`;

const SpanButton = styled.span`
    opacity: 0.5;
    cursor: pointer;
    font-size: 12px;
    font-style: italic;
    margin-left: 10px;
    :hover{
      opacity: 1;
      text-decoration: underline;
    }
`;
