import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import HorChessTemplate from '../templates/HorChessTemplate'
import SimpleSearchBarTool from "../cdek/tools/SimpleSearchBarTool";

import Tabs, {TabItem} from "../ui/Tabs";
import CdekCalculatorTool from "../tariffs/tools/CdekCalculatorTool";
import CustomersPanel from "../customers/panels/CustomersPanel";

export default function CustomersApp() {

    return (
        <HorChessTemplate active={`customers`}>

            <CustomersPanel/>

        </HorChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;

const WhiteWrapper = styled.div`
    background-color: white;
    padding: 10px;
    border-radius: 4px;
`;
