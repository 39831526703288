import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import OrderDetailView from "../views/OrderDetailView";

const greyMinus = require('../../../assets/images/grey_minus.png');
const greyPlus = require('../../../assets/images/grey_plus.png');
const orangePlus = require('../../../assets/images/orange_plus.png');

const getTicketImage = (ticket) => {
    if (ticket == undefined) {
        return null;
    }
    let {priority, status, type} = ticket;
    if (priority == 'high') {
        return require('../../../assets/images/attention_sign.svg')
    }
    return require('../../../assets/images/alert.svg')
}

const isCoolAdmin = () => {
    try {
        return (`${window.localStorage.getItem('is_cool_admin')}` == '1');
    } catch (e) {
        return false;
    }
}

export default function OrdersTable(props) {
    const {
        login, password,
        ticketsMap = Map(),
        onDelete = ordId => {

        },
        orders = [
            {
                id: 1,
                order_number: '23432123',
                from_name: 'Сергеева Ирина Геннадьевна',
                to_name: 'Петров Пётр Петрович',
                price_for_customer: 100500,
                status: 'success',
                createdAt: new Date()
            },
            {
                id: 2,
                order_number: '23432123',
                from_name: 'Сергеева Ирина Геннадьевна',
                to_name: 'Петров Пётр Петрович',
                price_for_customer: 100500,
                status: 'success',
                createdAt: new Date()
            }
        ],
        onTicketCreate = (orderId, order) => {

        },
        onTicketsToggle = () => {

        },
        ticketsFilterEnabled = false
    } = props;
    const [expandedSet, setExpandedSet] = useState(Set());


    return (
        <Wrapper>

            <Table className={'table b-table'} id={'delivery-btable'}>
                <Thead>
                <tr>
                    <Th style={{width: 20}}>
                        <ThSpan
                            style={{fontSize: 10, backgroundColor: (ticketsFilterEnabled == true) ? 'green' : 'white'}}>
                            <TicketImg src={require('../../../assets/images/problem.svg')}
                                       style={{opacity: 0.4}}
                                       onClick={() => {
                                           onTicketsToggle()
                                       }}
                            />
                        </ThSpan>
                    </Th>
                    <Th style={{width: 70}}>
                        <ThSpan style={{fontSize: 12}}>
                            Дата
                        </ThSpan>
                    </Th>
                    <Th style={{width: 90}}>
                        <ThSpan style={{fontSize: 12}}>
                            № накладной
                        </ThSpan>
                    </Th>
                    <Th>
                        <ThSpan>
                            PK_ID
                        </ThSpan>
                    </Th>
                    <Th>
                        <ThSpan>
                            Компания
                        </ThSpan>
                    </Th>
                    <Th>
                        <ThSpan>
                            Откуда
                        </ThSpan>
                    </Th>
                    <Th>
                        <ThSpan>
                            Куда
                        </ThSpan>
                    </Th>
                    <Th style={{minWidth: 90}}>
                        <ThSpan>
                            Цена, RUB
                        </ThSpan>
                    </Th>
                    {!isCoolAdmin() ? null :
                        <Th style={{minWidth: 80}}>
                            <ThSpan>
                                Цена, ПК
                            </ThSpan>
                        </Th>
                    }
                    {!isCoolAdmin() ? null :
                        <Th style={{minWidth: 80}}>
                            <ThSpan>
                                Зараб.
                            </ThSpan>
                        </Th>
                    }
                    {!isCoolAdmin() ? null :
                        <Th style={{minWidth: 80}}>
                            <ThSpan>
                                Сум. Зар.
                            </ThSpan>
                        </Th>
                    }
                    <Th>
                        <ThSpan>
                            Сбор Н.П.
                        </ThSpan>
                    </Th>
                    <Th>
                        <ThSpan>
                            Сбор Страх.
                        </ThSpan>
                    </Th>
                    <Th>
                        <ThSpan>
                            Статус
                        </ThSpan>
                    </Th>
                    <LastTh>
                        <PlusImage/>
                    </LastTh>
                </tr>
                </Thead>

                <Tbody>
                {orders.map((ord, k) => {
                    let {
                        customer, price_for_paykeeper, codData, revenue, sumRevenue,
                        from_city_name, to_city_name, to_pvz_code, from_pvz_code,
                        order_number, partner_order_id, paykeeper_id, order_type,
                        createdAt, from_name, to_name, price_for_customer, status, id, status_color, status_text,
                    } = ord;
                    let isYandex = (`${partner_order_id}`[0] == 'Y');
                    let isPvz = (order_type != undefined && order_type.indexOf('-ware') > -1);
                    let expanded = expandedSet.has(id);
                    let insurancePayment = (codData == undefined || codData.goods.length == 0) ? 0 : (codData.insuranceEnabled == true ? 0.0075 * codData.goods.reduce((sm, g) => (+sm + g.pricePerOne * g.quantity), 0) : 0);
                    let codPayment = ((codData == undefined) ? 0 : 0.03 * codData.goods.reduce((sm, g) => (+sm + g.pricePerOne * g.quantity), 0));
                    let ticket = ticketsMap.get(id);
                    return (
                        <React.Fragment key={`${ord.id}_${k}_`}>
                            <Tr expanded={expanded} key={ord.id} onClick={() => {
                                if (expanded == true) {
                                    setExpandedSet(expandedSet.delete(ord.id));
                                } else {
                                    setExpandedSet(expandedSet.add(ord.id));
                                }
                            }}>
                                <TicketTd style={{fontSize: 10, width: 20}}>
                                    {ticket == undefined ? null :
                                        <TicketTdInner status={ticket.status}
                                                       key={`tick_${ticket.priority}_${ticket.updatedTimestamp}`}>
                                            <TicketImg src={getTicketImage(ticket)}/>
                                        </TicketTdInner>
                                    }
                                </TicketTd>
                                <PkTd style={{fontSize: 12, width: 70}}>
                                    {moment(createdAt).format('DD.MM.YYYY')}
                                </PkTd>
                                <FirstTd style={{width: 90, background: (isYandex == true ? 'orange' : 'transparent')}}>
                                    {partner_order_id}
                                </FirstTd>
                                <PkTd>
                                    <span>
                                        {paykeeper_id}
                                    </span>
                                </PkTd>
                                <Td>
                                    {customer == undefined ? null :
                                        <>
                                        {customer.legal_entity_name}
                                        {customer.paying != true ? null :
                                            <>{' ⭐'}</>
                                        }
                                        </>
                                    }
                                </Td>

                                <NameTd>
                                    {from_city_name}
                                    {from_pvz_code == undefined ? null :
                                        <b style={{marginLeft: 5, opacity: 0.3}}>{`ПВЗ ${from_pvz_code}`}</b>}
                                </NameTd>
                                <NameTd>
                                    <span style={{fontStyle: (isPvz == false ? 'normal' : 'italic')}}>
                                        {to_city_name}
                                        {isPvz == false ? null :
                                            <b style={{marginLeft: 5, opacity: 0.3}}>{`ПВЗ ${to_pvz_code}`}</b>}
                                    </span>
                                </NameTd>
                                <Td>
                                    {price_for_customer}
                                </Td>
                                {!isCoolAdmin() ? null :
                                    <Td>
                                        {(+price_for_paykeeper).toFixed(2)}
                                    </Td>
                                }
                                {!isCoolAdmin() ? null :
                                    <Td>
                                        {revenue}
                                    </Td>
                                }
                                {!isCoolAdmin() ? null :
                                    <Td>
                                        {sumRevenue}
                                    </Td>
                                }
                                <Td>
                                    {codData == undefined ? null :
                                        <span>
                                            {(+codPayment).toFixed(2)}
                                            {/*{JSON.stringify(codData)}*/}
                                        </span>
                                    }
                                </Td>
                                <Td>
                                    {codData == undefined ? null :
                                        <span>
                                            {(insurancePayment == undefined ? '' : (+insurancePayment).toFixed(2))}
                                            {/*{JSON.stringify(codData)}*/}
                                        </span>
                                    }
                                </Td>
                                <StatusTd color={status_color}>
                                    {status_text}
                                </StatusTd>
                                <LastTd expanded={expanded}>
                                    {expanded == true ? <MinusImage/> : <PlusImage/>}
                                </LastTd>
                            </Tr>
                            {expanded == false ? null :
                                <tr key={`exp_${ord.id}`} role={'row'} tabIndex={-1} className={'b-table-details'}>
                                    <td colSpan={7} role={'cell'}>
                                        <div>
                                            <div className={'row-detaiReact.Fragmentls-block'}>
                                                <OrderDetailView {...ord}
                                                                 ticketsMap={ticketsMap}
                                                                 ticket={ticket}
                                                                 onTicketCreate={() => {
                                                                     onTicketCreate(ord.id, ord);
                                                                 }}
                                                                 login={login} password={password}
                                                                 onDelete={onDelete}

                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            }
                        </React.Fragment>
                    )
                })}
                </Tbody>

            </Table>

        </Wrapper>
    );
}

const Tbody = styled.tbody`
    
`;

const Wrapper = styled.div`
    width: 100%;
`;

const Table = styled.table`
    width: 100%;
    border-spacing: 0;
    border-radius: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
`;

const Tr = styled.tr`
    background-color: #FFFFFF;
    outline: none;
    cursor: pointer;
`;

const Td = styled.td`
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    vertical-align: middle;
`;

const NameTd = styled.td`
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    vertical-align: middle;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const FirstTd = styled.td`
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    vertical-align: middle;
    font-weight: bold;
    font-size: 12px;
`;

const PkTd = styled.td`
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    vertical-align: middle;
    font-weight: bold;
    font-size: 12px;
    width: 100px;
`;

const StatusTd = styled.td`
    border: 1px solid #dee2e6;
    vertical-align: middle;
    width: 130px;
    text-align: center;
    background: ${props => (props.color == undefined ? 'transparent' : props.color)};
    color: ${props => (props.color == undefined ? 'black' : 'white')} !important;
    font-size: 12px;
    font-weight: bold;
`;

const LastTd = styled.td`
    font-weight: bold;
    padding: 0;
    color: #444444;
    width: 46px;
    min-width: 46px;
    max-width: 46px;
    background-color: ${props => (props.expanded == false ? '#fff3d8' : '#f1f1f1')};
    line-height: 100%;
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #dee2e6;
`;

const LastTh = styled.th`
    font-weight: bold;
    text-align: center;
    padding: 0;
    color: #444444;
    width: 46px;
    min-width: 46px;
    max-width: 46px;
    background-color: #fff3d8;
    line-height: 100%;
    vertical-align: middle;
    padding-bottom: 0.4rem;
    background-color: inherit;
`;

const Thead = styled.thead`
    background-color: #fafafa;
`;

const Th = styled.th`
    background-position: right calc(0.75rem / 2) center;
    padding: 0.75rem;
    padding-right: calc(0.15rem + 0.15em);
    vertical-align: bottom;
    border: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
`;

const PlusImage = styled.div`
    display: inline-block;
    width: 17px;
    min-width: 17px;
    height: 17px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    background-image: url("${greyPlus}");
    :hover{
      background-image: url("${orangePlus}");  
    }
`;

const MinusImage = styled.div`
    width: 17px;
    min-width: 17px;
    height: 17px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    background-image: url("${greyMinus}");
    display: inline-block;
`;

const ThSpan = styled.span`
    font-style: normal;
    border-bottom: 1px dotted #212121;
`;

const TicketTd = styled(PkTd)`
    font-size: 10px;
    width: 20px;
`;

const TicketTdInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: ${props => (props.status == 'in_progress' ? 'yellow' : (props.status == 'resolved' ? 'green' : 'white'))};
`;

const TicketImg = styled.img`
    width: 18px;
    height: 18px;
`;
