import axios from 'axios'
import Dexie from 'dexie';
import {API_ENDPOINT} from "../constants/config";

const OrdersAPI = {

    getAllOrders() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/orders/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    getAllOrdersFresherThen(t) {

        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/orders/fresh/${t}`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    getAllOrdersYoungerThen(t) {

        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/orders/younger/${t}`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    getAllOrdersWithCache() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/orders/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    updateOrderStatus(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/order/${id}/status/update`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld);
            }).catch(err => reject(err));
        });
    },

    getOrder(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/order/${id}`).then(d => d.data).then(doc => {
                if (doc.error != undefined) {
                    return reject(doc.error);
                }
                resolve(doc);
            }).catch(err => reject(err));
        });
    },

    createOrder(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/orders/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateOrder(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/orders/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    cancelOrderCourierInvitations(orderId) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/order/${orderId}/courier_invitations/delete`).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    // deleteOrder(id) {
    //     return new Promise((resolve, reject) => {
    //         axios.post(`${API_ENDPOINT}/orders/delete`, {id: id}).then(d => d.data).then(ex => {
    //             if (ex.error != undefined) {
    //                 return reject(ex.error);
    //             }
    //             resolve(ex);
    //         }).catch(err => reject(err));
    //     });
    // },

    async deleteOrder(id, login, password) {
        try {
            let pld = (await axios.post(`${API_ENDPOINT}/order/${id}/delete`, {id: id}, {
                auth: {
                    username: login,
                    password: password
                }
            })).data;
        } catch (exc) {
            console.log('caught exc during trying to delete the order: exc = ', exc);
        }
    },

    async getOrderCouriers(id) {
        let pld = (await axios.get(`${API_ENDPOINT}/order/${id}/courier_invitations`)).data;
        return pld;
    },

    async deleteInvitation(invId) {
        let pld = (await axios.post(`${API_ENDPOINT}/order/invitation/${invId}/delete`)).data;
        return pld;
    },

    async processOrderInvitation(invId) {
        let pld = (await axios.post(`${API_ENDPOINT}/order/invitation/${invId}/invite`)).data;
        return pld;
    },


}

export default OrdersAPI;
