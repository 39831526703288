import {KVDB_API_ENDPOINT} from "../constants/config";
import axios from 'axios'

import KvdbAPI from './KvdbAPI'
import ls from 'local-storage'

export default class MailAPI {

    static async saveData(key, data) {
        console.log('saveData: key, data = ', key, data);
        let pld = (await axios.post(`${KVDB_API_ENDPOINT}/key/${key}`, {
            data: data
        }, {
            headers: {
                'x-kvdb-token': 'sabirmipt'
            }
        })).data;
        if (pld.error != undefined) {
            throw pld.error;
        }
        return pld.result;
    }

    static async getTemplates() {
        let map = await KvdbAPI.getItemsByPrefix('mail_template_');
        let arr = [];
        for (let key in map) {
            let a = map[key];
            arr.push(a);
        }
        arr = arr.sort((a, b) => (+b.timestamp - +a.timestamp));
        return arr;
    }

    static async sendCormMail(sender = {}, receiver = {}, content = {}, paykeeper_id) {
        let pld = (await axios.post(`${KVDB_API_ENDPOINT}/send_corp_mail`, {
            from: {
                phoneSuffix: sender.phoneSuffix,
                managerName: sender.managerName,
                email: sender.email,
                password: sender.password
            },
            to: {
                email: receiver.email
            },
            content: {
                subject: content.subject,
                html: content.html
            },
            pkId: paykeeper_id
        }, {
            headers: {
                'x-kvdb-token': 'sabirmipt'
            }
        })).data;
        return pld;
    }


}
