import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import UpdateKeyValuePanel from "../../keyv/UpdateKeyValuePanel";

export default function CdekCredsPanel(props) {


    return (
        <Wrapper>

            <Field>
                <Label>
                    Account
                </Label>
                <UpdateKeyValuePanel pKey={`cdek_account`}/>
            </Field>

            <Field>
                <Label>
                    Secure
                </Label>
                <UpdateKeyValuePanel pKey={`cdek_secure_password`}/>
            </Field>

            <Field>
                <Label>
                    Основной базовый URL
                </Label>
                <UpdateKeyValuePanel pKey={`cdek_api_url`}/>
            </Field>

            <Field>
                <Label>
                    Тестовый базовый URL
                </Label>
                <UpdateKeyValuePanel pKey={`cdek_test_api_url`}/>
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.div`
    font-weight: bold;
`;
