import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {Set} from "immutable";

import useDebouncedCallback from 'use-debounce/lib/callback'

import * as customersActions from '../../../redux/actions/CustomersActions'
import {DARK_SIDE} from "../../ui/Colors";

import {KVDB_API_ENDPOINT} from "../../../constants/config";
import StateHelper from "../../../helpers/StateHelper";

import {Input} from "../../ui/Input";

async function loadKvdbMap() {
    console.log('loadKvdbMap occured!');
    let arr = (await axios.get(`${KVDB_API_ENDPOINT}/keys/bank_?token=sabirmipt`)).data.result;
    console.log('arr = ', arr);
    let map = {};
    for (let key in arr) {
        let pkId = key.split('_')[1];
        let val = arr[key];
        map[pkId] = val;
    }
    return map;
}

export default function CustomersBankInfoPanel(props) {
    const {filterPaykeeperId} = props;
    const {
        customers, loading, custSet, currentUser
    } = useMappedState(useCallback(state => {
        let custSet = StateHelper.getUserCustMap(state);
        let custs = StateHelper.getUserCustomers(state);
        console.log('custs = ', custs);
        return {
            loading: state.customers.loading,
            custSet: custSet,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            // customers: state.customers.customersMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)).filter(a => ((filterPaykeeperId == undefined || a.paykeeper_id == filterPaykeeperId)))
            customers: custs.sort((a, b) => (+b.timestamp - +a.timestamp)).filter(a => ((filterPaykeeperId == undefined || a.paykeeper_id == filterPaykeeperId)))
        }
    }, []));
    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState(undefined);
    const [searchString, setSearchString] = useState('');

    const [xMap, setXMap] = useState({});

    const [debouncedSearch] = useDebouncedCallback((a) => {
        console.log('will set ', a);
        setSearchString(a);
    }, 100, []);

    useEffect(() => {
        dispatch(customersActions.loadAllCustomers());
        loadKvdbMap().then(a => {
            setXMap(a);
        });
    }, []);

    if (customers.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    const filteredCustomers = customers.filter(a => {
        let {secret, name, legal_entity_name, paykeeper_id, website, inn} = a;
        let dd = xMap[paykeeper_id];
        let s2 = (dd == undefined) ? `` : `${dd.bik} ${dd.bankName} ${dd.bankAccount}`;
        let s = `${secret} ${name} ${legal_entity_name} ${paykeeper_id} ${website} ${inn} ${s2}`;
        if (searchString == undefined || searchString.trim() == '') {
            return true;
        }
        return (s.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
    });

    return (
        <Wrapper>

            <SearchPlaceholder>
                <Input placeholder={'Поиск по названию, инн или реквизитам'} style={{
                    height: 36,
                    lineHeight: `36px`,
                    fontSize: 16
                }} onChange={evt => {
                    debouncedSearch(evt.target.value);
                }}/>
            </SearchPlaceholder>

            <Table>

                <THead>
                <Tr>
                    <Th>
                        ИНН
                    </Th>
                    <Th>
                        Название организации
                    </Th>
                    <Th>
                        Название банка
                    </Th>
                    <Th>
                        БИК Банка
                    </Th>
                    <Th>
                        Расчетный счет
                    </Th>
                </Tr>
                </THead>

                <TBody>
                {filteredCustomers.map((a, k) => {
                    let {name, paykeeper_id, secret, legal_entity_name} = a;
                    let s = (legal_entity_name != undefined) ? legal_entity_name : name;
                    let dd = xMap[paykeeper_id];
                    return (
                        <Tr selected={(a.id == selectedId)} key={a.id} onClick={() => {
                            setSelectedId(a.id)
                        }}>
                            <Td>
                                {a.inn}
                            </Td>
                            <Td>
                                {legal_entity_name}
                            </Td>
                            <Td>
                                {dd == undefined ? '' : dd.bankName}
                            </Td>
                            <Td>
                                {dd == undefined ? '' : dd.bik}
                            </Td>
                            <Td>
                                {dd == undefined ? '' : dd.bankAccount}
                            </Td>
                        </Tr>
                    )
                })}
                </TBody>

            </Table>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    max-height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 10px;
`;

const ListPlaceholder = styled.div`
    
`;

const Item = styled.div`
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${props => (props.selected == true ? DARK_SIDE : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const Name = styled.div`
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const Description = styled.div`
    
`;

const Left = styled.div`
    
`;

const Right = styled.div`
    
`;

const ExpirationDate = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 20px;
`;

const SearchPlaceholder = styled.div`
    margin-bottom: 20px;
`;

const Table = styled.table`
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid ghostwhite;
    padding: 5px;
`;

const Tr = styled.tr`
    background: ${props => (props.selected == true ? 'rgba(135,206,250,0.5)' : 'white')};
    cursor: pointer;
    :hover{
      background: rgba(135,206,250,0.5);
    }
`;

const Td = styled.td`
    border: 1px solid grey;
    padding: 5px;
`;

const Th = styled.th`
    text-align: left;
    border: 1px solid grey;
    padding: 5px;
`;

const THead = styled.thead`
    border: 1px solid grey;
`;

const TBody = styled.tbody`
    
`;
