import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Button} from "../../ui/Button";
import {Textarea} from "../../ui/Textarea";
import {Input} from "../../ui/Input";

import 'react-datepicker/dist/react-datepicker.min.css'
import DatePicker from 'react-datepicker'


const genApiKey = () => {
    return `delivery-api-key-${moment().format('DD-MM-YYYY')}-${uuid()}`;
}

const genSecretKey = () => {
    return `delivery-secret-${moment().format('DD-MM-YYYY')}-${uuid()}`;
}

export default function UpdateAppForm(props) {

    const {
        onSave = d => {

        },
        loading = false
    } = props;

    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);
    const [expirationTimestamp, setExpirationTimestamp] = useState(props.expirationTimestamp == undefined ? +moment().add(10, 'years') : props.expirationTimestamp);

    const [apiKey, setApiKey] = useState(props.apiKey == undefined ? genApiKey() : props.apiKey);
    const [secretKey, setSecretKey] = useState(props.secretKey == undefined ? genSecretKey() : props.secretKey);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Название
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Описание
                </Label>
                <Textarea value={description} onChange={evt => {
                    setDescription(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Дата истечения срока действия
                </Label>
                <DatePicker
                    dateFormat="dd.MM.yyyy"
                    selected={moment(expirationTimestamp).toDate()}
                    onChange={newDate => {
                        setExpirationTimestamp(+new Date(newDate));
                    }}
                />
            </Field>

            <Field>
                <Label>
                    API KEY
                </Label>
                <div>
                    {apiKey}
                    <SpanButton onClick={() => {
                        setApiKey(genApiKey());
                    }}>
                        сменить API KEY
                    </SpanButton>
                </div>
            </Field>

            <Field>
                <Label>
                    SECRET KEY
                </Label>
                <div>
                    {secretKey}
                    <SpanButton onClick={() => {
                        setSecretKey(genSecretKey());
                    }}>
                        сменить SECRET KEY
                    </SpanButton>
                </div>
            </Field>

            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button onClick={() => {
                        onSave({
                            apiKey, secretKey, expirationTimestamp, name, description
                        });
                    }}>
                        Сохранить
                    </Button>
                }
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
    margin-bottom: 3px;
`;

const SpanButton = styled.span`
    opacity: 0.5;
    cursor: pointer;
    font-size: 12px;
    font-style: italic;
    margin-left: 10px;
    :hover{
      opacity: 1;
      text-decoration: underline;
    }
`;
