import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Button} from "../../ui/Button";
import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";

export default function UpdateOrderFromDetailsForm(props) {

    const {
        loading = false, onSave = d => {

        }
    } = props;

    const [fromName, setFromName] = useState(props.from_name);
    const [fromComment, setFromComment] = useState(props.from_comment);
    const [fromStreet, setFromStreet] = useState(props.from_street);
    const [fromBuilding, setFromBuilding] = useState(props.from_building);
    const [fromEmail, setFromEmail] = useState(props.from_email);
    const [fromPhone, setFromPhone] = useState(props.from_phone);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Имя отправителя
                </Label>
                <Input value={fromName} onChange={evt => {
                    setFromName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Email отправителя
                </Label>
                <Input value={fromEmail} onChange={evt => {
                    setFromEmail(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Телефон отправителя
                </Label>
                <Input value={fromPhone} onChange={evt => {
                    setFromPhone(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Улица отправителя
                </Label>
                <Input value={fromStreet} onChange={evt => {
                    setFromStreet(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Номер дома отправителя
                </Label>
                <Input value={fromBuilding} onChange={evt => {
                    setFromBuilding(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Комментарий отправителя
                </Label>
                <Input value={fromComment} onChange={evt => {
                    setFromComment(evt.target.value);
                }}/>
            </Field>


            <Field>
                {loading == true ? 'Загрузка...' :
                    <Button onClick={() => {
                        onSave({
                            from_name: fromName,
                            from_email: fromEmail,
                            from_phone: fromPhone,
                            from_street: fromStreet,
                            from_building: fromBuilding,
                            from_comment: fromComment,
                        });
                    }}>
                        Сохранить
                    </Button>
                }
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;

