import moment from 'moment-timezone'
import {DEFAULT_TIMEZONE} from "../constants/config";

export const TRANSACTIONS_TYPES_NAMES = {
    'REPLENISHMENT': 'Пополнение',
    'WITHDRAWAL': 'Списание',
    'PAYMENT': 'Оплата'
};

export const TRANSACTIONS_TYPES_SIGNUM = {
    'REPLENISHMENT': '+',
    'WITHDRAWAL': '-',
    'PAYMENT': '-'
};


const TransactionsHelper = {};

export default TransactionsHelper;
