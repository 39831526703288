import axios from 'axios'
import {ANALYTICS_API_ENDPOINT} from "../constants/config";
import CommonHelper from "../helpers/CommonHelper";

const EventsAPI = {

    async getUserEvents(userId, from, to) {
        let events = (await axios.get(`${ANALYTICS_API_ENDPOINT}/events`, {
            params: {
                userId: userId,
                from: from,
                to: to
            }
        })).data.result;
        console.log('getUserEvents: userId = ', userId);
        return events;
    },

    async getEventsForPeriod(from, to) {
        let events = (await axios.get(`${ANALYTICS_API_ENDPOINT}/events`, {
            params: {
                from: from,
                to: to
            }
        })).data.result;
        return events;
    },

    async getEventsForLastTime(duration = 5 * 60 * 1000) {
        let to = +new Date();
        let from = +to - +duration;
        let events = (await axios.get(`${ANALYTICS_API_ENDPOINT}/events`, {
            params: {
                from: from,
                to: to
            }
        })).data.result;
        return events;
    },

    async getEventsForToday() {
        let events = await this.getEventsForLastTime(24 * 3600 * 1000);
        return events;
    },

    async getEventsForLast5Minutes() {
        let events = await this.getEventsForLastTime(5 * 60 * 1000);
        return events;
    },

    async getEventsFrom(from) {
        let to = +new Date();
        let events = (await axios.get(`${ANALYTICS_API_ENDPOINT}/events`, {
            params: {
                from: from,
                to: to
            }
        })).data.result;
        return events;
    },


}

export default EventsAPI;
