import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import ls from 'local-storage'

import {Input} from "../../ui/Input";

const populateParamsMap = parametersDataString => {
    let items = parametersDataString.split(',').map(a => a.replace('{', '').replace('}', '')).map(a => ({
        label: a.split('|')[1].trim(),
        name: a.split('|')[0].trim()
    }));
    let map = {};
    for (let i in items) {
        let a = items[i];
        map[a.name] = ls(a.name);
    }
    return map;
}

export default function FillParametersDataStringTool(props) {
    const {
        parametersDataString = '',
        onChange = d => {

        }
    } = props;
    const [map, setMap] = useState(populateParamsMap(parametersDataString));
    let items = parametersDataString.split(',').map(a => a.replace('{', '').replace('}', '')).map(a => ({
        label: a.split('|')[1].trim(),
        name: a.split('|')[0].trim()
    }));

    return (
        <Wrapper>

            <ItemsPlaceholder>
                {items.map((a, i) => {
                    return (
                        <Item key={a.name}>
                            <Label>
                                {a.label}
                            </Label>
                            <Input value={map[a.name]} onChange={evt => {
                                let s = evt.target.value;
                                let newMap = {...map, [a.name]: s};
                                setMap(newMap);
                                onChange(newMap);
                            }}/>
                        </Item>
                    )
                })}
            </ItemsPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ItemsPlaceholder = styled.div`
    
`;

const Item = styled.div`
    
`;

const Label = styled.div`
    font-weight: bold;
`;

const ButtonPlaceholder = styled.div`
    margin-top: 20px;
`;
