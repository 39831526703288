import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {DARK_SIDE, MAIN_ORANGE_COLOR} from "../ui/Colors";

import CommonHelper from '../../helpers/CommonHelper'

export default function MyTopLinksBar(props) {
    const {
        onLogout = () => {

        },
        active,
        role = 'admin'
    } = props;
    let options = (role == 'admin') ? adminOptions : userOptions;

    return (
        <Wrapper>

            {options.map((opt, k) => {
                let {label, icon, url, name} = opt;
                let isSelected = (active == name);
                return (
                    <OptionItem selected={isSelected} key={k} onClick={() => {
                        CommonHelper.linkTo(url);
                    }}>
                        {label}
                    </OptionItem>
                )
            })}

        </Wrapper>
    );
}

const topHeight = 30;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    position: relative;
    align-self: stretch;
    justify-content: flex-start;
`;

const OptionItem = styled.div`
    line-height: 32px;
    height: 32px;
    border-bottom: 2px solid ${props => (props.selected == true ? '#ae1b01' : 'transparent')};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
    color: #535252;
    opacity: 0.9;
    box-sizing: border-box;
    margin-right: 32px;
    display: flex;
    align-self: center;
    justify-content: center;
    font-size: 16px;
    
    
    :hover{
      opacity: 1;
    }
`;

const userOptions = [
    {
        name: 'index',
        label: 'Главная',
        url: '/'
    },
    {
        name: 'customers',
        label: 'Клиенты',
        url: '/customers'
    },
    {
        name: 'mail',
        label: 'Письма',
        url: '/mail'
    }
];


const adminOptions = [
    {
        name: 'index',
        label: 'Главная',
        url: '/'
    },
    {
        name: 'customers',
        label: 'Клиенты',
        url: '/customers'
    },
    {
        name: 'mail',
        label: 'Письма',
        url: '/mail'
    },
    {
        name: 'orders',
        label: 'Заказы',
        url: '/orders'
    },
    {
        name: 'events',
        label: 'Логи клиентов',
        url: '/events'
    },
    {
        name: 'stats',
        label: 'Статистика',
        url: '/stats'
    },
    {
        name: 'tariffs',
        label: 'Тарифы',
        url: '/tariffs'
    },
    {
        name: 'integration',
        label: 'Интеграция',
        url: '/integration'
    },
    {
        name: 'widget',
        label: 'Виджет',
        url: '/widget'
    },
    {
        name: 'tools',
        label: 'Инструменты',
        url: '/tools'
    },
    {
        name: 'analytics',
        label: 'Анализ',
        url: '/analytics'
    },
];
