import TicketsAPI from '../../api/TicketsAPI'

import * as types from '../ActionTypes'

let loadTickets_ = () => {
    return {
        type: types.LOAD_TICKETS
    }
}
let loadTicketsSuccess = (tickets) => {
    return {
        type: types.LOAD_TICKETS_SUCCESS,
        tickets: tickets
    }
}
let loadTicketsFailed = (error) => {
    return {
        type: types.LOAD_TICKETS_FAIL,
        error: error
    }
}

//thunk
export function loadAllTickets() {
    return (dispatch, getState) => {
        dispatch(loadTickets_());
        return TicketsAPI.getAllTickets().then(
            tickets => dispatch(loadTicketsSuccess(tickets)),
            error => dispatch(loadTicketsFailed(error))
        )
    }
}

export function loadTicketById(id) {
    return (dispatch, getState) => {
        dispatch(loadTickets_());
        return TicketsAPI.getTicket(id).then(
            ticket => dispatch(loadTicketsSuccess([ticket])),
            error => dispatch(loadTicketsFailed(error))
        )
    }
}

let createTicket_ = () => {
    return {
        type: types.CREATE_TICKET
    }
}
let createTicketSuccess = (ticket) => {
    return {
        type: types.CREATE_TICKET_SUCCESS,
        ticket: ticket
    }
}
let createTicketFailed = (error) => {
    return {
        type: types.CREATE_TICKET_FAIL,
        error: error
    }
}

//thunk
export function createTicket(data) {
    return (dispatch, getState) => {
        dispatch(createTicket_());
        return TicketsAPI.createTicket(data).then(
            ticket => dispatch(createTicketSuccess(ticket)),
            error => dispatch(createTicketFailed(error))
        )
    }
}

let updateTicket_ = () => {
    return {
        type: types.UPDATE_TICKET
    }
}
let updateTicketSuccess = (ticket) => {
    return {
        type: types.UPDATE_TICKET_SUCCESS,
        ticket: ticket
    }
}
let updateTicketFailed = (error) => {
    return {
        type: types.UPDATE_TICKET_FAIL,
        error: error
    }
}

//thunk
export function updateTicket(data) {
    return (dispatch, getState) => {
        dispatch(updateTicket_());
        return TicketsAPI.updateTicket(data).then(
            ticket => dispatch(updateTicketSuccess(ticket)),
            error => dispatch(updateTicketFailed(error))
        )
    }
}

let deleteTicket_ = () => {
    return {
        type: types.DELETE_TICKET
    }
}
let deleteTicketSuccess = (id) => {
    return {
        type: types.DELETE_TICKET_SUCCESS,
        id: id
    }
}
let deleteTicketFailed = (error) => {
    return {
        type: types.DELETE_TICKET_FAIL,
        error: error
    }
}

//thunk
export function deleteTicket(id) {
    return (dispatch, getState) => {
        dispatch(deleteTicket_());
        return TicketsAPI.deleteTicket(id).then(
            () => dispatch(deleteTicketSuccess(id)),
            error => dispatch(deleteTicketFailed(error))
        )
    }
}
