import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import SelfLoadingCitySpan from "../../tools/SelfLoadingCitySpan";

export default function OrderDialogView(props) {
  const {
    login, password, from_code, to_code,
    createdAt, updatedAt, from_city_name, to_city_name,
    weight, width, height, length,
    price_for_customer, to_date, to_time_start, to_time_end,
    from_name, from_street, from_building, from_email, from_phone, from_comment,
    to_name, to_street, to_building, to_email, to_phone, to_comment, id, order_type,

    onClose = () => {

    },
    onAddressSaved = () => {

    }

  } = props;
  const [senderSaving, setSenderSaving] = useState(false);
  const [receiverSaving, setReceiverSaving] = useState(false);
  const [senderSaved, setSenderSaved] = useState(false);
  const [receiverSaved, setReceiverSaved] = useState(false);

  return (
    <Wrapper>

      <MainRow>
        <Half>
          <HalfHeading>
            ОТКУДА
          </HalfHeading>

          <Field>
            <FLeft>
              Населенный пункт
            </FLeft>
            <FRight>
              <SelfLoadingCitySpan code={from_code}/>
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Улица
            </FLeft>
            <FRight>
              {from_street}
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Номер дома
            </FLeft>
            <FRight>
              {from_building}
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Отправитель
            </FLeft>
            <FRight>
              {from_name}
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Телефон
            </FLeft>
            <FRight>
              {from_phone}
            </FRight>
          </Field>

          <Field>
            <FLeft>
              E-mail
            </FLeft>
            <FRight>
              {from_email}
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Примечание
            </FLeft>
            <FRight>
              {from_comment}
            </FRight>
          </Field>


        </Half>
        <Half>
          <HalfHeading>
            КУДА
          </HalfHeading>

          <Field>
            <FLeft>
              Населенный пункт
            </FLeft>
            <FRight>
              <SelfLoadingCitySpan code={to_code}/>
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Улица
            </FLeft>
            <FRight>
              {to_street}
            </FRight>
          </Field>
          <Field>
            <FLeft>
              Номер дома
            </FLeft>
            <FRight>
              {to_building}
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Получатель
            </FLeft>
            <FRight>
              {to_name}
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Телефон
            </FLeft>
            <FRight>
              {to_phone}
            </FRight>
          </Field>

          <Field>
            <FLeft>
              E-mail
            </FLeft>
            <FRight>
              {to_email}
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Примечание
            </FLeft>
            <FRight>
              {to_comment}
            </FRight>
          </Field>


        </Half>
      </MainRow>

      <Hr/>

      <MainRow>
        <Half>
          <HalfHeading>
            ГРУЗ
          </HalfHeading>

          <Field>
            <FLeft>
              Дата забора
            </FLeft>
            <FRight>
              {to_date}
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Время забора
            </FLeft>
            <FRight>
              {`с ${to_time_start} по ${to_time_end}`}
            </FRight>
          </Field>


          <Field>
            <FLeft>
              Вес, кг
            </FLeft>
            <FRight>
              {`${weight} кг`}
            </FRight>
          </Field>

          <Field>
            <FLeft>
              Габариты, см
            </FLeft>
            <FRight>
              {`${width} x ${height} x ${length}`}
            </FRight>
          </Field>

        </Half>
        <Half>

          <HalfHeading style={{marginBottom: 17}}>
            СТОИМОСТЬ
          </HalfHeading>

          <PriceValuePlaceholder>
            {`${(+price_for_customer).toFixed(2)} руб.`}
          </PriceValuePlaceholder>

        </Half>
      </MainRow>

      <BottomPlaceholder>

        <ConsignmentLinkSpan onClick={() => {
          openInNewTab(`https://delivery.paykeeper.ru/api/order/${id}/consignment`);
        }}>
          Напечатать накладную
        </ConsignmentLinkSpan>

        <span style={{marginLeft: 20, marginRight: 20}}>
            или
          </span>

        <LinkSpan onClick={() => {
          onClose();
        }}>
          Закрыть
        </LinkSpan>

      </BottomPlaceholder>

    </Wrapper>
  );
}

const Wrapper = styled.div`
    
`;


const LinkSpan = styled.div`
    cursor: ${props => (props.saved == true ? 'default' : 'pointer')};
    border-bottom: ${props => (props.saved == false ? '1px dotted #425684' : 'none')};
    color: ${props => (props.saved == true ? 'inherit' : '#425684')};
    font-size: 14px;
    display: inline-block;
`;

const ConsignmentLinkSpan = styled.div`
    padding: 6px 24px;
    color: #FFFFFF;
    text-transform: uppercase;
    text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.3);
    border: none;
    border-bottom: 1px solid #acacac;
    border-top: 1px solid #fbd1a6;
    cursor: pointer;
    outline: 0;
    border-top-color:${props => (props.disabled == true ? '#FFFFFF' : '#fbd1a6')};
    background-color: ${props => (props.disabled == true ? '#d6d6d6' : '#f7a330')};
    background-image: linear-gradient(0deg, #f7a330 0%, #f58d20 100%);
    box-shadow: 1px 0 1px #dfdfdf;
    font: 400 14px system-ui;
    border-radius: 3px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 24px;
    padding-right: 24px;
`;


const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
`;

const MainRow = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 20px;
    @media(max-width: 720px){
      display: block;
    }
`;

const Hr = styled.div`
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const Half = styled.div`
    flex: 1;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
    :first-of-type{
      padding-left: 0px;
    }
    :last-of-type{
      padding-right: 0px;
    }
`;

const HalfHeading = styled.div`
    color: #a9a9a9;
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TopHeadingAddressBookPlaceholder = styled.span`
    font-weight: normal;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Field = styled.div`
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const FLeft = styled.div`
    width: 170px;
`;

const FRight = styled.div`
    flex: 1;
`;

const PriceValuePlaceholder = styled.div`
    //font-size: 36px;
    font-size: 32px;
    margin-bottom: 10px;
    color: #696969;
    margin-top: -15px;
`;

function openInNewTab(url) {
  let win = window.open(url, '_blank');
  win.focus();
}
