import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CityInfoTool from "../../cdek/tools/CityInfoTool";
import CalculatorResultsTool from "../../cdek/tools/CalculatorResultsTool";
import CdekAPI from "../../../api/CdekAPI";
import {BROWN} from "../../ui/Colors";

import {DELIVERY_TYPES_OPTIONS} from "../../../helpers/TariffsHelper";
import {DEFAULT_GOODS} from "../../../helpers/TariffsHelper";

export default function TariffCalcTool(props) {
    const {
        partner, delivery_type,
        partner_discount_type, partner_fix_price, partner_percent_discount,
        customer_discount_type, customer_fix_discount, customer_percent_discount,
        from_code, to_code
    } = props;

    // const [priceData, setPriceData] = useState(undefined);
    const [guestPrice, setGuestPrice] = useState(undefined);

    useEffect(() => {
        // CdekAPI.getCalcDefaultPriceInfo(from_code, to_code, delivery_type).then(pld => {
        //     console.log('getCalcDefaultPriceInfo: pld = ', pld);
        //     setPriceData(pld);
        // })
        CdekAPI.getCdeckCheapestOptions({from: from_code, to: to_code, goods: DEFAULT_GOODS}).then(pld => {
            let {paykeeper, guest} = pld;
            let {w_w, w_d} = guest;
            if (delivery_type == 'warehouse-warehouse') {
                setGuestPrice(w_w == undefined ? -1 : w_w.price);
            } else {
                setGuestPrice(w_d == undefined ? -1 : w_d.price);
            }
        });
    }, [from_code, to_code, delivery_type]);

    if (guestPrice == undefined) {
        return <Code/>
    }

    return (
        <Wrapper>

            <Row>
                <It>
                    {`${partner}: `}
                </It>
                <It>
                    <CityInfoTool code={from_code} style={toolStyle}/>
                </It>
                <It>
                    {' -> '}
                </It>
                <It>
                    <CityInfoTool code={to_code} style={toolStyle}/>
                </It>
                <It>
                    {DELIVERY_TYPES_OPTIONS.filter(a => (a.value == delivery_type))[0].label}
                </It>
            </Row>

            <Divider/>
            <Row>
                <LIt>
                    Стоимость <b>{partner}</b> (<a href={'https://www.cdek.ru/calculator.html'}
                                                   target={'_blank'}>калькулятор</a>):
                </LIt>
                <It>
                    <b>
                        {`${guestPrice} руб`}
                    </b>
                </It>
            </Row>

            <Row>
                <LIt>
                    Стоимость, которую <b>даем мы</b>:
                </LIt>
                <It>
                    {partner_discount_type != 'fix-price' ? null :
                        <b style={{textDecoration: 'underline'}}>
                            {`${(+guestPrice - +((+guestPrice - +partner_fix_price) * (+customer_percent_discount) / 100.0)).toFixed(2)} руб. `}
                        </b>
                    }
                </It>
            </Row>

            <Divider/>

            {/*<CalculatorResultsTool from={from_code} to={to_code} />*/}

            {partner_discount_type != 'fix-price' ? null :
                <div>
                    <p>
                        {`Сервис ${partner} дает нам индивидуальные условия для данного направления -  `}
                        <b>
                            {`${partner_fix_price} руб. `}
                        </b>

                        <span>
                            {`Таким образом, у нас есть ${guestPrice} - ${partner_fix_price} = `}
                            <b>
                                {`${(+guestPrice - +partner_fix_price).toFixed(2)} руб.,`}
                            </b>
                            {' на которых мы можем заработать.'}
                        </span>

                        {/*{`Клиенту это на ${(+guestPrice - +partner_fix_price).toFixed(2)} руб. выгоднее, чем если бы клиент заказывал у сервиса доставки напрямую.`}*/}

                        {customer_discount_type != 'percent-discount' ? null :
                            <span>
                                {` Из ${(+guestPrice - +partner_fix_price).toFixed(2)} руб. мы выделим в качестве скидки ${customer_percent_discount}%, то есть `}
                                <b>
                                    {`${((+guestPrice - +partner_fix_price) * (+customer_percent_discount) / 100.0).toFixed(2)} руб. `}
                                </b>
                                <span>
                                    {`Итого для клиента доставка обойдется в ${+guestPrice} - ${((+guestPrice - +partner_fix_price) * (+customer_percent_discount) / 100.0)} = `}
                                    <b>{`${(+guestPrice - +((+guestPrice - +partner_fix_price) * (+customer_percent_discount) / 100.0)).toFixed(2)} руб. `}</b>
                                    {` Это на `}
                                    <b>{`${(+guestPrice - +(+guestPrice - +((+guestPrice - +partner_fix_price) * (+customer_percent_discount) / 100.0))).toFixed(2)} руб.`}</b> {` выгоднее для клиента, чем если бы он заказывал не у нас.`}
                                </span>

                                <br/>
                                <br/>
                                <InlineJewSpan>
                                    {`А мы с этого заработаем `}
                                    <b style={{textDecoration: 'underline', fontSize: 16}}>
                                        {`${((+guestPrice - +partner_fix_price) * (100 - +customer_percent_discount) / 100.0).toFixed(2)} руб.`}
                                    </b>
                                </InlineJewSpan>
                            </span>
                        }

                    </p>

                </div>
            }


        </Wrapper>
    );
}

const toolStyle = {
    opacity: 1,
    fontSize: 16,
    fontWeight: 'bold'
}

const Wrapper = styled.div`

`;

const Divider = styled.div`
    width: 100%;
    height: 2px;
    background-color: whitesmoke;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const It = styled.div`
    margin-right: 10px;
`;

const LIt = styled.div`
    width: 280px;
`;

const InlineJewSpan = styled.span`
    padding: 5px;
    border-radius: 4px;
    background-color: whitesmoke;
`;
