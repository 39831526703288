import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import {MAIN_ORANGE_COLOR} from "../ui/Colors";

export default function SimpleDropdown(props) {
  const {
    options = [
      {
        label: 'Опция 1',
        value: 'opt1',
        number: 2
      },
      {
        label: 'Опция 2',
        value: 'opt2',
        number: 15
      },
      {
        label: 'Опция 3',
        value: 'opt3',
        number: 44
      },
      {
        label: 'Опция 4',
        value: 'opt4',
        number: 2312
      }
    ],
    placeholder = '',
    value,
    onChange = a => {

    },
    hasRightNumbers = false
  } = props;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const node = useRef();
  const handleClick = e => {
    if (node == undefined || node.current == undefined) {
      return;
    }
    if (node.current.contains(e.target)) {
      return;
    }
    setDropdownVisible(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  let selectedOption = (value == undefined) ? undefined : options.filter(a => (a.label == value.label))[0];

  return (
    <Wrapper ref={node}>
      <InputPlaceholder>
        <InputDiv selected={dropdownVisible} onClick={() => {
          setDropdownVisible(!dropdownVisible);
        }}>
          <MonthName>
            {selectedOption == undefined ? <span style={{opacity: 0.6}}>{placeholder}</span> : selectedOption.label}
          </MonthName>
          <ArrowImage src={require('../tools/caret-down.svg')}
                      style={{transform: `rotate(${dropdownVisible == true ? 180 : 0}deg)`}}/>
        </InputDiv>
      </InputPlaceholder>
      {dropdownVisible == false ? null :
        <DropdownPlaceholder>

          <ResultsPlaceholder wide={hasRightNumbers} >
            {options.map((opt, k) => {
              return (
                <Item selected={((selectedOption != undefined) && (selectedOption.label == opt.label))}
                      key={`${k}_${opt.label}`} onClick={() => {
                  setDropdownVisible(false);
                  onChange(opt);
                }}>
                  <ItemName>
                    {opt.label}
                  </ItemName>
                  {(hasRightNumbers == false || opt.number == undefined) ? null :
                    <ItemNumber>
                      {opt.number}
                    </ItemNumber>
                  }
                </Item>
              )
            })}
          </ResultsPlaceholder>

        </DropdownPlaceholder>
      }
    </Wrapper>
  );
}


const Wrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const InputPlaceholder = styled.div`
    
`;

const DropdownPlaceholder = styled.div`
    position: absolute;
    top: 23px;
    background: white;
    border: 1px solid #e0dfdf;
    z-index: 1000;
    left: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    
`;

const InputDiv = styled.div`
    font-size: 14px;
    background: #FFFFFF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100%;
    color: #444;
    padding-left: 10px;
    height: 23px;
    line-height: 21px;
    box-sizing: border-box;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    
    border-bottom-right-radius: ${props => (props.selected == true ? 0 : 2)}px;
    border-bottom-left-radius: ${props => (props.selected == true ? 0 : 2)}px;
    
    border: 1px solid ${props => (props.red == true ? 'red' : '#e0dfdf')};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #F9F8F9;
    :hover{
      opacity: 0.85;
    }
`;

const MonthName = styled.div`
    
`;


const ArrowImage = styled.img`
    width: 8px;
    height: 8px;
    margin-right: 8px;
    opacity: 0.5;
    transition-duration: 0.2s;
    margin-left: 8px;
`;

const Input = styled.input`
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    border: 1px solid rgb(170, 170, 170);
    line-height: 16px;
    height: 26px;
    padding: 4px;
    outline: none;
`;

const VoidPlaceholder = styled.div`
    padding: 4px;
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 4px;
    font-family: PT Sans;
`;

const InnerInputPlaceholder = styled.div`
    padding: 4px;
    box-sizing: border-box;
`;

const ResultsPlaceholder = styled.div`
    max-height: 200px;
    overflow-y: auto;
    width: ${props => (props.wide == true ? '130px' : 'auto')};
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: PT Sans;
    width: 100%;
    box-sizing: border-box;
    height: 28px;
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 16px;
    font-size: 14px;
    cursor: pointer;
    background: ${props => (props.selected == true ? 'lightgrey' : 'white')};
    :hover{
      background-color: ${MAIN_ORANGE_COLOR};
      color: white;
    }
`;

const ItemName = styled.div`
    
`;

const ItemNumber = styled.div`
    color: #212121;
    background-color: #ebebeb;
    border-radius: 3px;
    padding: 2px 5px;
    font-weight: 700;
    font-size: 12px;
`;
