import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable'
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import Select from 'react-select'
import {Async} from 'react-select';
import {API_ENDPOINT} from "../../../constants/config";
import CdekAPI from "../../../api/CdekAPI";

const MAX_RESULTS_VALUE = 50;

const DEFAULT_OPTIONS = [
    {
        label: 'Москва',
        value: 44
    },
    {
        label: 'Санкт-Петербург',
        value: 137
    },
]

const loadOptions = (inputValue, callback) => {
    console.log('loadOptions: inputValue = ', inputValue);
    if (inputValue == undefined || inputValue.trim() == '') {
        return callback([]);
    }
    axios.get(`${API_ENDPOINT}/cdek/search/city_options/cache`, {
        params: {
            city: inputValue,
            maxValue: MAX_RESULTS_VALUE
        }
    }).then(d => d.data).then(options => {
        callback(options);
    });
};

const getCityFriendlyName = (obj) => {
    console.log('getCityFriendlyName: obj = ', obj);
    if (obj == undefined) {
        return '';
    }
    let {cityName, region, subRegion} = obj;
    let opts = [cityName, subRegion, region].filter(a => (a != undefined && a.trim() != ''));
    return opts.join(`, `);
}

export default function CitySearchableSelect(props) {
    const {
        code, onChange = newCode => {

        }
    } = props;
    const [cacheMap, setCacheMap] = useState(Map());
    const [loading, setLoading] = useState(true);
    const [defaultOptions, setDefaultOptions] = useState(DEFAULT_OPTIONS);
    const [value, setValue] = useState(props.code == undefined ? undefined : props.code);

    useEffect(() => {
        if (code == undefined) {
            setLoading(false);
            return;
        }
        CdekAPI.getCityByCode(code).then(city => {
            if (city != undefined) {
                setCacheMap(cacheMap.set(city.cityCode, city));
                setDefaultOptions([...DEFAULT_OPTIONS, {label: getCityFriendlyName(city), value: city.cityCode}]);
                setValue(city.cityCode);
            }
            setLoading(false);
        });
    }, [code]);

    if (loading == true) {
        return <Spin visible={loading}/>
    }

    let selOption = (value == undefined && cacheMap.get(`${value}`) == undefined) ? undefined : {
        value: value,
        label: getCityFriendlyName(cacheMap.get(`${value}`))
    };

    console.log('selOption: ', selOption);

    return (
        <Wrapper>

            <Spin visible={loading}/>

            <Async
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions={defaultOptions}
                isClearable={true}
                placeholder={'Название населенного пункта'}
                value={selOption}
                onInputChange={a => {
                    console.log('onInputChange: a = ', a);
                }}
                onChange={a => {
                    console.log('onChange: a = ', a);
                    if (a == undefined) {
                        setValue(undefined);
                    } else {
                        setValue(a.value);
                        onChange(a.value);
                    }

                }}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
