import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {HashRouter, Switch, Route} from 'react-router-dom'
import {useDispatch, useMappedState} from 'redux-react-hook';

import * as usersActions from '../../redux/actions/UsersActions'
import * as ticketsActions from '../../redux/actions/TicketsActions'

import AdminIndexApp from './AdminIndexApp'
import DevApp from './DevApp'
import OrderApp from './OrderApp'
import OrdersApp from './OrdersApp'
import StatsApp from './StatsApp'
import TariffsApp from './TariffsApp'
import IntegrationApp from './IntegrationApp'
import ToolsApp from './ToolsApp'
import CustomersApp from './CustomersApp'
import AnalyticsApp from './AnalyticsApp'
import LoginApp from './LoginApp'
import BankApp from './BankApp'
import EventsApp from './EventsApp'
import MailApp from './MailApp'
import WidgetApp from './WidgetApp'
import FullPagePreloader from "../preloader/FullPagePreloader";

const adminRoute = (
    <Switch>
        <Route exact path='/widget' component={WidgetApp}/>
        <Route exact path='/mail' component={MailApp}/>
        <Route exact path='/events' component={EventsApp}/>
        <Route exact path='/bank' component={BankApp}/>
        <Route exact path='/analytics' component={AnalyticsApp}/>
        <Route exact path='/dev' component={DevApp}/>
        <Route exact path='/integration' component={IntegrationApp}/>
        <Route exact path='/tools' component={ToolsApp}/>
        <Route exact path='/customers' component={CustomersApp}/>
        <Route exact path='/orders' component={OrdersApp}/>
        <Route exact path='/tariffs' component={TariffsApp}/>
        <Route exact path='/stats' component={StatsApp}/>
        <Route exact path='/order/:id' component={OrderApp}/>
        <Route component={AdminIndexApp}/>
    </Switch>
);

const userRoute = (
    <Switch>
        <Route exact path='/mail' component={MailApp}/>
        <Route exact path='/events' component={EventsApp}/>
        <Route exact path='/analytics' component={AnalyticsApp}/>
        <Route exact path='/dev' component={DevApp}/>
        <Route exact path='/integration' component={IntegrationApp}/>
        <Route exact path='/tools' component={ToolsApp}/>
        <Route exact path='/customers' component={CustomersApp}/>
        <Route exact path='/orders' component={OrdersApp}/>
        <Route exact path='/tariffs' component={TariffsApp}/>
        <Route exact path='/stats' component={StatsApp}/>
        <Route exact path='/order/:id' component={OrderApp}/>
        <Route component={AdminIndexApp}/>
    </Switch>
);

const guestRoute = (
    <Switch>
        <Route component={LoginApp}/>
    </Switch>
);


export default function RouterApp(props) {
    const mapState = useCallback(
        state => ({
            initialized: state.users.initialized,
            currentUser: state.users.currentUserId == undefined ? null : state.users.usersMap.get(state.users.currentUserId)
        }), []
    );
    const {initialized, currentUser} = useMappedState(mapState);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(usersActions.initializeAuthorization()).then(pld => {
            if (pld.user != undefined) {
                dispatch(ticketsActions.loadAllTickets());
            }
        });
    }, []);

    if (initialized == false) {
        return (
            <FullPagePreloader visible={true}/>
        )
    }

    let route = guestRoute;
    let isGuest = (currentUser == undefined);
    let isAdmin = (isGuest == false && currentUser.userRole == 'admin');

    if (isGuest == false) {
        route = isAdmin == true ? adminRoute : userRoute;
    }

    return (
        <Wrapper>

            <HashRouter>
                {route}
            </HashRouter>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
