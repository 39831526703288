import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import HorChessTemplate from '../templates/HorChessTemplate'
import SimpleSearchBarTool from "../cdek/tools/SimpleSearchBarTool";

import Tabs, {TabItem} from "../ui/Tabs";
import CdekCalculatorTool from "../tariffs/tools/CdekCalculatorTool";
import CalcDevTool from "../tariffs/tools/CalcDevTool";

export default function ToolsApp() {

    return (
        <HorChessTemplate active={`tools`}>

            <Tabs tabs={[
                {
                    label: 'Отладочный калькулятор',
                    content: (
                        <TabItem>
                            <CalcDevTool/>
                        </TabItem>
                    )
                },
                {
                    label: 'Калькудятор СДЕК',
                    content: (
                        <TabItem>
                            <WhiteWrapper>
                                <CdekCalculatorTool/>
                            </WhiteWrapper>
                        </TabItem>
                    )
                },
                {
                    label: 'Коды населенных пунктов',
                    content: (
                        <TabItem>
                            <WhiteWrapper>
                                <SimpleSearchBarTool/>
                            </WhiteWrapper>
                        </TabItem>
                    )
                }
            ]}/>


        </HorChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;

const WhiteWrapper = styled.div`
    background-color: white;
    padding: 10px;
    border-radius: 4px;
`;
