import querystring from 'querystring'
import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const CdekAPI = {

    async getAddressResults(city) {
        return (await axios.get(`${API_ENDPOINT}/cdek/search/city`, {params: {city: city}})).data;
    },

    async getCityByCode(code) {
        if (code == undefined || code == '') {
            return undefined;
        }
        return (await axios.get(`${API_ENDPOINT}/cdek/search/city/code/cache`, {params: {code: code}})).data;
    },

    async getCalcDefaultPriceInfo(from, to) {
        let result = (await axios.post(`${API_ENDPOINT}/cdek/calculator`, {
            from: from, to: to
        })).data;
        return result.result;
    },

    async getCalcCdekPriceInfo(data) {
        let result = (await axios.post(`${API_ENDPOINT}/cdek/calculator`, {
            ...data
        })).data;
        return result.result;
    },

    async getCdeckCheapestOptions(data) {
        let result = (await axios.post(`${API_ENDPOINT}/cdek/calculator/cheapest_options`, {
            ...data
        })).data;
        return result;
    },

    async getCalcPaykeeperPriceInfo(data) {
        let result = (await axios.post(`${API_ENDPOINT}/paykeeper/calculator`, {
            ...data
        })).data;
        return result.result;
    },

    async getSmartSuggestions(fromCode, toCode) {
        let url = `https://delivery.paykeeper.ru/api2/calc/price/formatted`;
        let getKgPrice = async function (we) {
            let kgPrice = (await axios.get(url, {
                params: {
                    weight: we,
                    width: 10,
                    height: 10,
                    length: 10,
                    from_code: fromCode,
                    to_code: toCode
                }
            })).data;
            return kgPrice;
        }
        let kg1Price = await getKgPrice(1);
        let kg2Price = await getKgPrice(2);
        let kg3Price = await getKgPrice(3);
        let kg4Price = await getKgPrice(4);


        console.log('kg4Price = ', kg4Price);

        let {ww, wd, d, w} = kg1Price;
        let availableTypes = [
            ww == undefined ? undefined : 'warehouse-warehouse',
            wd == undefined ? undefined : 'warehouse-door',
            w == undefined ? undefined : 'door-warehouse',
            d == undefined ? undefined : 'door-door'
        ].filter(a => (a != undefined));

        let getPriceDiff = (obj1, obj2, field = 'price_for_paykeeper') => {
            let diffObj = {
                d: 0,
                w: 0,
                ww: 0,
                wd: 0,
            }
            if (obj1 != undefined && obj2 != undefined) {
                if (obj1.d != undefined && obj2.d != undefined) {
                    diffObj.d = obj2.d[field] - obj1.d[field];
                }
                if (obj1.w != undefined && obj2.w != undefined) {
                    diffObj.w = obj2.w[field] - obj1.w[field];
                }
                if (obj1.wd != undefined && obj2.wd != undefined) {
                    diffObj.wd = obj2.wd[field] - obj1.wd[field];
                }
                if (obj1.ww != undefined && obj2.ww != undefined) {
                    diffObj.ww = obj2.ww[field] - obj1.ww[field];
                }
            }
            return diffObj;
        };
        let diff12 = getPriceDiff(kg1Price, kg2Price);
        let diff23 = getPriceDiff(kg2Price, kg3Price);
        let diff13 = getPriceDiff(kg1Price, kg3Price);
        let diff34 = getPriceDiff(kg3Price, kg4Price);

        let diffObj = diff12;
        let baseObj = kg1Price;

        let custDiffObj = getPriceDiff(kg3Price, kg4Price, 'price_for_customer');

        let baseWeight = 1;
        if (diff12.d != 0) {
            baseWeight = 1;
            diffObj = diff12;
            baseObj = kg1Price;
            custDiffObj = getPriceDiff(kg1Price, kg2Price, 'price_for_customer');
        } else {
            if (diff23.d != 0) {
                baseWeight = 2;
                diffObj = diff23;
                baseObj = kg2Price;
                custDiffObj = getPriceDiff(kg2Price, kg3Price, 'price_for_customer');
            } else {
                baseWeight = 3;
                diffObj = diff34;
                baseObj = kg3Price;
                custDiffObj = getPriceDiff(kg3Price, kg4Price, 'price_for_customer');
            }
        }

        // let custDiff34 = getPriceDiff(kg3Price, kg4Price, 'price_for_customer');


        return {
            availableTypes: availableTypes,
            diff13: diff13,
            diff34: diff34,
            minValues: {
                baseWeight: baseWeight,
                'door-door': availableTypes.indexOf('door-door') == -1 ? undefined : {
                    defaultBasePrice: Math.round(baseObj.d.price_for_customer),
                    basePrice: Math.ceil(baseObj.d.price_for_paykeeper / 0.94) + 5,
                    extPrice: Math.ceil(diffObj.d) + 0,
                    defaultExtPrice: Math.round(custDiffObj.d)

                },
                'door-warehouse': availableTypes.indexOf('door-warehouse') == -1 ? undefined : {
                    defaultBasePrice: Math.round(baseObj.w.price_for_customer),
                    basePrice: Math.ceil(baseObj.w.price_for_paykeeper / 0.94) + 5,
                    extPrice: Math.ceil(diffObj.w) + 0,
                    defaultExtPrice: Math.round(custDiffObj.w)
                },
                'warehouse-door': availableTypes.indexOf('warehouse-door') == -1 ? undefined : {
                    defaultBasePrice: Math.round(baseObj.wd.price_for_customer),
                    basePrice: Math.ceil(baseObj.wd.price_for_paykeeper / 0.94) + 5,
                    extPrice: Math.ceil(diffObj.wd) + 0,
                    defaultExtPrice: Math.round(custDiffObj.wd)
                },
                'warehouse-warehouse': availableTypes.indexOf('warehouse-warehouse') == -1 ? undefined : {
                    defaultBasePrice: Math.round(baseObj.ww.price_for_customer),
                    basePrice: Math.ceil(baseObj.ww.price_for_paykeeper / 0.94) + 5,
                    extPrice: Math.ceil(diffObj.ww) + 0,
                    defaultExtPrice: Math.round(custDiffObj.ww)
                },
            }
        }

    }

}

export default CdekAPI;

