import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";
import CommonHelper from "../helpers/CommonHelper";

const TransactionsAPI = {

    async getCustomerBalance(customerId) {
        let pld = (await axios.get(`${API_ENDPOINT}/customer/${customerId}/balance`)).data;
        return pld.balance;
    },

    async getCustomerTransactions(customerId) {
        let items = (await axios.get(`${API_ENDPOINT}/customer/${customerId}/transactions`)).data;
        return CommonHelper.getSortedSqlItems(items);
    },

    async replenish(customerId, amount) {
        return (await axios.post(`${API_ENDPOINT}/customer/${customerId}/replenish`, {amount})).data;
    },

    async withdraw(customerId, amount) {
        return (await axios.post(`${API_ENDPOINT}/customer/${customerId}/withdraw`, {amount})).data;
    },

    async getCustomerBalanceAndTransactions(customerId) {
        const balance = await this.getCustomerBalance(customerId);
        const transactions = await this.getCustomerTransactions(customerId);
        return {
            balance,
            transactions
        }
    },

    async getAllReplenishmentTransactions() {
        return (await axios.get(`${API_ENDPOINT}/transactions/replenishment/all`)).data;
    },

}

export default TransactionsAPI;
