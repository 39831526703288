import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import HorChessTemplate from '../templates/HorChessTemplate'
import ReleasesPanel from "../widget/panels/ReleasesPanel";

export default function WidgetApp() {

    return (
        <HorChessTemplate active={`widget`}>

            <ReleasesPanel/>

        </HorChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
