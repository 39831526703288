import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import {API_ENDPOINT} from "../../constants/config";

export default function SelfLoadingCitySpan(props) {
    const {code} = props;
    const [loading, setLoading] = useState(true);
    const [city, setCity] = useState(true);

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/cdek/search/city/code`, {params: {code: code}}).then(d => d.data).then(a => {
            setLoading(false);
            setCity(a);
        })
        // (await axios.get(`${API_ENDPOINT}/cdek/search/city/code`, {params: {code: code}})).data;
        // DeliveryAPI.getCityByCode(code).then(a => {
        //   setLoading(false);
        //   setCity(a);
        // })
    }, [code]);

    if (city == undefined) {
        return null;
    }

    return (
        <Wrapper>
            {city.fullName}
        </Wrapper>
    );
}

const Wrapper = styled.span`
    
`;
