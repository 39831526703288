import {combineReducers} from 'redux';

import AppsReducer from './AppsReducer.js';
import OrdersReducer from './OrdersReducer.js';
import TariffsReducer from './TariffsReducer.js';
import CustomersReducer from './CustomersReducer.js';
import UsersReducer from './UsersReducer.js';
import TicketsReducer from './TicketsReducer.js';

export const reducer = combineReducers({
    tickets: TicketsReducer,
    users: UsersReducer,
    orders: OrdersReducer,
    tariffs: TariffsReducer,
    customers: CustomersReducer,
    apps: AppsReducer
});
