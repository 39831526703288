import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import MailAPI from "../../../api/MailAPI";

import ls from 'local-storage'

import Select from 'react-select'

import {Button} from "../../ui/Button";
import {Input} from "../../ui/Input";

import FillParametersDataStringTool from "./FillParametersDataStringTool";
import {GOOD_PK_ID_REGEXP} from "../../customers/forms/UpdateCustomerForm";

const populateParamsMap = parametersDataString => {
    let items = parametersDataString.split(',').map(a => a.replace('{', '').replace('}', '')).map(a => ({
        label: a.split('|')[1].trim(),
        name: a.split('|')[0].trim()
    }));
    let map = {};
    for (let i in items) {
        let a = items[i];
        map[a.name] = ls(a.name);
    }
    return map;
}

export default function SendTemplatedMailTool(props) {
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        MailAPI.getTemplates().then(a => {
            setTemplates(a);
        })
    }, []);

    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);

    const [paykeeper_id, setPaykeeper_id] = useState(props.paykeeper_id == undefined ? '' : props.paykeeper_id);
    let isGoodPkId = GOOD_PK_ID_REGEXP.test(paykeeper_id);

    const [map, setMap] = useState({});

    const [subject, setSubject] = useState(ls('mail_subject'));

    const [phoneSuffix, setPhoneSuffix] = useState(ls('mail_sender_phone_suffix'));
    const [managerName, setManagerName] = useState(ls('mail_sender_name'));

    const [receiverEmail, setReceiverEmail] = useState('');

    const [senderEmail, setSenderEmail] = useState(ls('mail_sender_email'));
    const [senderPassword, setSenderPassword] = useState(ls('mail_sender_password'));

    const [selectedTemplateId, setSelectedTemplateId] = useState(ls('sel_mail_template_id'));
    const OPTIONS = templates.map(a => ({label: a.name, value: a.id}));

    let selectedTemplate = (selectedTemplateId == undefined) ? undefined : templates.filter(a => (a.id == selectedTemplateId))[0];

    let genHtml = (selectedTemplate == undefined) ? '' : getGenHtml(selectedTemplate.html, populateParamsMap(selectedTemplate.parametersDataString));

    return (
        <Wrapper>

            <div style={{marginBottom: 40}}>

                <Field>
                    <Label>
                        Имя менеджера
                    </Label>
                    <Input value={managerName} onChange={evt => {
                        setManagerName(evt.target.value.trim());
                        ls('mail_sender_name', evt.target.value.trim());
                    }}/>
                </Field>

                <Field>
                    <Label>
                        Добавочный номер менеджера
                    </Label>
                    <Input value={phoneSuffix} onChange={evt => {
                        setPhoneSuffix(evt.target.value.trim());
                        ls('mail_sender_phone_suffix', evt.target.value.trim());
                    }}/>
                </Field>

                <Field>
                    <Label>
                        Email отправителя
                    </Label>
                    <Input value={senderEmail} onChange={evt => {
                        setSenderEmail(evt.target.value.trim());
                        ls('mail_sender_email', evt.target.value.trim());
                    }}/>
                </Field>

                <Field>
                    <Label>
                        Пароль почты отправителя
                    </Label>
                    <Input value={senderPassword} onChange={evt => {
                        setSenderPassword(evt.target.value.trim());
                        ls('mail_sender_password', evt.target.value.trim());
                    }}/>
                </Field>

            </div>

            <Field>
                <Label>
                    Номер договора клиента
                </Label>
                <Input value={paykeeper_id} onChange={evt => {
                    setPaykeeper_id(evt.target.value.trim());
                }}/>
            </Field>

            <Field>
                <Select options={OPTIONS}
                        value={OPTIONS.filter(a => (a.value == selectedTemplateId))[0]}
                        onChange={a => {
                            setSelectedTemplateId(a.value);
                            ls('sel_mail_template_id', a.value);
                        }}/>
            </Field>

            {selectedTemplate == undefined ? null :
                <ToolWrapper>
                    <FillParametersDataStringTool {...selectedTemplate} onChange={a => {
                        for (let key in a) {
                            let val = a[key];
                            ls(key, val);
                        }
                        setMap(a);
                    }}/>
                </ToolWrapper>
            }

            <div style={{marginTop: 40, marginBottom: 40, backgroundColor: 'wheat', padding: 20}}>
                <Field>
                    <Label>
                        Тема письма
                    </Label>
                    <Input value={subject} onChange={evt => {
                        setSubject(evt.target.value);
                        ls('mail_subject', evt.target.value);
                    }}/>
                </Field>
                <Field>
                    <Label>
                        Email получателя
                    </Label>
                    <Input value={receiverEmail} onChange={evt => {
                        setReceiverEmail(evt.target.value);
                    }}/>
                </Field>

            </div>


            <PreviewPlaceholder>

                {/*<h3>*/}
                {/*Предпросмотр письма*/}
                {/*</h3>*/}

                <div dangerouslySetInnerHTML={{__html: genHtml}}></div>
            </PreviewPlaceholder>

            {sent == true ?
                <div>
                    Письмо отправлено
                </div>
                :
                <BottomButtonPlaceholder>
                    <Spin visible={sending}/>
                    {sending == true ? null :
                        <Button onClick={async () => {
                            if (isGoodPkId == false) {
                                window.alert('Номер договора введен некорректно');
                                return;
                            }
                            setSending(true);
                            await MailAPI.sendCormMail({
                                phoneSuffix: phoneSuffix,
                                managerName: managerName,
                                email: senderEmail,
                                password: senderPassword
                            }, {
                                email: receiverEmail
                            }, {
                                subject: subject,
                                html: genHtml
                            }, paykeeper_id);

                            await MailAPI.sendCormMail({
                                phoneSuffix: phoneSuffix,
                                managerName: managerName,
                                email: senderEmail,
                                password: senderPassword
                            }, {
                                email: senderEmail
                            }, {
                                subject: `Z-КОПИЯ - ${subject}`,
                                html: genHtml
                            }, paykeeper_id);

                            setSending(false);
                            window.alert('Письмо отправлено');
                            setSent(true);
                        }}>
                            Отправить
                        </Button>
                    }
                </BottomButtonPlaceholder>
            }


        </Wrapper>
    );
}

const getGenHtml = (html, paramsMap = {}) => {
    console.log('getGenHtml: html, paramsMap = ', html, paramsMap);
    if (html == undefined) {
        return '';
    }
    let s = html;
    for (let key in paramsMap) {
        let val = paramsMap[key];
        s = s.split(`{{${key}}}`).join(val);
    }
    return s;
}

const BottomButtonPlaceholder = styled.div`
    margin-top: 20px;
`;

const Wrapper = styled.div`
    
`;

const Label = styled.div`
    font-weight: bold;
`;

const PreviewPlaceholder = styled.div`
    padding: 10px;
    background: white;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const ToolWrapper = styled.div`
    background: white;
    padding: 15px;
    margin-top: 15px;
    border-radius: 4px;
    border: 1px solid whitesmoke;
`;

const Field = styled.div`
    margin-top: 10px;
`;
