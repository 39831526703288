import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import HorChessTemplate from '../templates/HorChessTemplate'

import AppsPanel from '../integration_apps/panels/AppsPanel'

import Tabs, {TabItem} from "../ui/Tabs";
import UpdateKeyValuePanel from "../keyv/UpdateKeyValuePanel";
import CdekCredsPanel from "../cdek/panels/CdekCredsPanel";

export default function OrdersApp() {

    return (
        <HorChessTemplate active={`integration`}>

            <Tabs tabs={[
                {
                    label: 'Интеграция со CDEK',
                    content: (
                        <TabItem>
                            <CdekCredsPanel/>
                        </TabItem>
                    )
                },
                // {
                //     label: 'Интеграция с ЛК Paykeeper',
                //     content: (
                //         <TabItem>
                //             <AppsPanel/>
                //         </TabItem>
                //     )
                // }
            ]}/>


        </HorChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
