import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const CustomersAPI = {

    getAllCustomers() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/customers/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    getCustomer(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/customer/${id}`).then(d => d.data).then(doc => {
                if (doc.error != undefined) {
                    return reject(doc.error);
                }
                resolve(doc);
            }).catch(err => reject(err));
        });
    },

    createCustomer(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/customers/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateCustomer(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/customers/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteCustomer(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/customers/delete`, {id: id}).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default CustomersAPI;
