import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import MySidebar from './MySidebar'

import {useDispatch, useMappedState} from 'redux-react-hook';

import FullPagePreloader from '../preloader/FullPagePreloader'
import CommonHelper from "../../helpers/CommonHelper";

import {Container} from "../ui/Container";
import {Button} from "../ui/Button";

import Sidebar from 'arui-feather/sidebar'

import MyTopLinksBar from "./MyTopLinksBar";

import * as usersActions from '../../redux/actions/UsersActions'


export default function HorChessTemplate(props) {
    let {children, active, isAdmin = false} = props;
    const {customers, loading, userRole, currentUser} = useMappedState(useCallback(state => {
        let allCustomers = state.customers.customersMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp));
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let userRole = (currentUser == undefined) ? undefined : currentUser.userRole;
        return {
            loading: state.customers.loading,
            customers: allCustomers,
            currentUser: currentUser,
            userRole: userRole
        }
    }, []));
    const dispatch = useDispatch();
    const [num, setNum] = useState(0);

    return (
        <Wrapper>

            <Header>
                <VeryTopPlaceholder>
                    <VeryTopInnerPlaceholder>
                        <LogoPlaceholder>
                            <LogoImage src={require('../../assets/images/pk_transparent_logo.png')}/>
                        </LogoPlaceholder>

                        {currentUser == undefined ? null :
                            <CurrentUserPlaceholder>
                                <span onClick={() => {
                                    setNum(+num + 1);
                                    if (+num > 15) {
                                        window.localStorage.setItem('is_cool_admin', '1');
                                        window.location.reload();
                                    }
                                }}>
                                    {`${currentUser.firstName} ${currentUser.lastName}`}
                                </span>

                                <LogoutSpan onClick={async () => {
                                    if (window.confirm('Вы уверены, что хотите выйти из личного кабинета?') == false) {
                                        return;
                                    }
                                    await dispatch(usersActions.logOut());
                                    CommonHelper.linkTo(`/`);
                                }}>
                                    выход
                                </LogoutSpan>

                            </CurrentUserPlaceholder>
                        }

                    </VeryTopInnerPlaceholder>
                </VeryTopPlaceholder>
                <LinksPlaceholder>
                    <LinksInnerPlaceholder>
                        <MyTopLinksBar active={active} role={userRole}/>
                    </LinksInnerPlaceholder>
                </LinksPlaceholder>
            </Header>

            <MainContent>
                <HorContainer>
                    {children}
                </HorContainer>
            </MainContent>

        </Wrapper>
    );
}

const mainBreakWidth = 1080;

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100vw;
    overflow: hidden;
    background: #F6F5F3;
`;

const Header = styled.div`
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px;
`;

const linksHeight = 40;

const LinksPlaceholder = styled.div`
    height: ${linksHeight}px;
    padding-left: 10px;
    padding-right: 10px;
    background: white;
`;

const LinksInnerPlaceholder = styled.div`
    width: 100%;
    margin: 0 auto;
    @media(max-width: ${mainBreakWidth}px){
      width: 100%;
    }
`;

const veryTopHeight = 50;

const VeryTopPlaceholder = styled.div`
    height: ${veryTopHeight}px;
    width: 100%;
    box-sizing: border-box;
    background: #b01c2e;
    color: white;
    padding-left: 10px;
`;

const VeryTopInnerPlaceholder = styled.div`
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-self: center;
    @media(max-width: ${mainBreakWidth}px){
      width: 100%;
    }
`;

const LogoPlaceholder = styled.div`
    height: ${veryTopHeight}px;
    line-height: ${veryTopHeight}px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LogoImage = styled.img`
    display: inline-block;
    vertical-align: top;
    height: ${veryTopHeight * 0.5}px;
    margin-right: 10px;
    border-radius: 2px;
`;

const LogoText = styled.div`
    display: inline-block;
    vertical-align: top;
    opacity: 0.9;
    font-size: 20px;
    line-height: ${veryTopHeight}px;
    height: ${veryTopHeight}px;
`;

const CurrentUserPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    padding-right: 20px;
`;

const LogoutSpan = styled.span`
    opacity: 0.5;
    margin-left: 10px;
    cursor: pointer;
    :hover{
      opacity: 1;
    }
`;

const avaSize = veryTopHeight * 0.8;

const Ava = styled.div`
    background-image: url(${props => props.src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: ${avaSize}px;
    height: ${avaSize}px;
    border-radius: 1000px;
    border: 2px solid whitesmoke;
`;

const CurrentUserText = styled.div`
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 20px;
`;

const MainContent = styled.div`
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    min-height: calc(100vh - ${veryTopHeight + linksHeight}px);
    max-height: calc(100vh - ${veryTopHeight + linksHeight}px);
    overflow-y: auto;
`;

const LogoutPanel = styled.div`
    display: flex;
    margin-right: 40px;
    flex-direction: row;
    opacity: 0.3;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;
    :hover{
      opacity: 1;
    }
`;

const LogoutImg = styled.img`
    width: ${avaSize * 0.5}px;
    height: ${avaSize * 0.5}px;
    margin-right: 10px;
`;

const LogoutText = styled.div`
    font-size: 12px;
    :hover{
      opacity: 1;
    }
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
`;

const HorContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    @media(max-width: ${mainBreakWidth}px){
      width: 100%;
    }
`;
