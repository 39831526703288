import React, {Component} from 'react';
import './App.css';
import styled from 'styled-components'
import moment from 'moment'
import localForage from 'localforage';
import {createStore, applyMiddleware, compose} from 'redux';

import {persistStore, persistReducer} from 'redux-persist'
import {PersistGate} from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'

import immutableTransform from 'redux-persist-transform-immutable'

import {Code} from "react-content-loader";

import logger from 'redux-logger'
import {reducer} from './redux/reducers'
import {default as ReduxThunk} from 'redux-thunk';
import RouterApp from "./components/apps/RouterApp";

import {StoreContext} from 'redux-react-hook';

moment.locale('ru');

const persistConfig = {
    key: 'root',
    storage: localForage,
    transforms: [immutableTransform()]
}


const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(
    persistedReducer,
    undefined,
    compose(
        applyMiddleware(ReduxThunk), applyMiddleware(logger)
    )
)

// const persistor = persistStore(store)

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {date: new Date(), ready: false};
    }

    componentDidMount() {
        persistStore(store, null, () => {
            this.setState({
                ready: true
            });
        });
    }

    render() {
        let {ready} = this.state;

        return (
            <StoreContext.Provider value={store}>
                {ready != true ? <Code/> :
                    <RouterApp/>
                }
            </StoreContext.Provider>
        );
    }

}

export default App;
