import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

export default function EventsTable(props) {
    const {events = [], showUserId = true} = props;


    return (
        <Wrapper>
            <Table>
                <Thead>
                <Tr>
                    {showUserId == false ? null :
                        <Th>
                            Paykeeper ID
                        </Th>
                    }
                    <Th>
                        Дата
                    </Th>
                    <Th>
                        Событие
                    </Th>
                    <Th>
                        Детали
                    </Th>
                </Tr>
                </Thead>

                <Tbody>

                {events.map((ev, j) => {
                    let isError = (ev.action.toLowerCase().indexOf('error') > -1);
                    let {user} = ev;
                    return (
                        <Tr key={`${ev.action}_${ev.t}`} error={isError}>
                            {showUserId == false ? null :
                                <Td>
                                    {`${user == undefined ? '' : user.legal_entity_name} [${ev.userId}]`}
                                </Td>
                            }
                            <Td>
                                {moment(ev.t).format('DD.MM.YYYY HH:mm:ss')}
                            </Td>
                            <Td>
                                {ev.action}
                            </Td>
                            <Td>
                                {JSON.stringify(ev.details)}
                            </Td>
                        </Tr>
                    )
                })}

                </Tbody>

            </Table>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Table = styled.table`
    width: 100%;
    background: white;
    border-collapse: collapse;
`;

const Tr = styled.tr`
    background: ${props => (props.error == true ? 'salmon' : 'transparent')};
`;

const Thead = styled.thead`
    
`;

const Tbody = styled.tbody`
    
`;

const Th = styled.th`
    font-weight: bold;
    text-align: left;
`;

const Td = styled.td`
    border: 1px solid whitesmoke;
    padding: 3px;
`;
