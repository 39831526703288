import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import OrdersAPI from "../../../api/OrdersAPI";
import {API_ENDPOINT} from "../../../constants/config";

import Tabs, {TabItem} from "../../ui/Tabs";
import OrderStatusPanel from "./OrderStatusPanel";
import OrderCouriersPanel from "../../couriers/panels/OrderCouriersPanel";
import UpdateOrderFromDetailsForm from "../forms/UpdateOrderFromDetailsForm";
import PaginatedOrdersTable from "../lists/PaginatedOrdersTable";
import * as customersActions from "../../../redux/actions/CustomersActions";
import * as ordersActions from "../../../redux/actions/OrdersActions";
import * as ticketsActions from "../../../redux/actions/TicketsActions";
import TrafficBarChart from "../../stats/charts/OrdersBarChart";
import AnalysisHelper from "../../../helpers/AnalysisHelper";
import StateHelper from "../../../helpers/StateHelper";

import Select from 'react-select'

export default function AllOrdersPanel(props) {
    // const [orders, setOrders] = useState([]);
    const [profitVisible, setProfitVisible] = useState(false);
    const [selectedPaykeeperId, setSelectedPaykeeperId] = useState(undefined);
    const [ticketsFilterEnabled, setTicketsFilterEnabled] = useState(false);
    const {
        customers, loading, custSet, orders, currentUserId, ticketsMap
    } = useMappedState(useCallback(state => {
        // let allCustomers = state.customers.customersMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp));
        let allCustomers = StateHelper.getUserCustomers(state).sort((a, b) => (+b.timestamp - +a.timestamp));
        let allOrders = state.orders.ordersMap.toArray();
        // console.log('allOrders = ', allOrders);
        let ords = StateHelper.getUserOrders(state);
        let custMap = StateHelper.getUserCustMap(state);
        return {
            currentUserId: state.users.currentUserId,
            custSet: StateHelper.getUserCustSet(state),
            loading: state.customers.loading,
            customers: allCustomers,
            orders: ords.map(a => ({
                ...a,
                customer: custMap.get(a.paykeeper_id)
            })).sort((a, b) => ((+new Date(b.createdAt)) - (+new Date(a.createdAt)))),
            ticketsMap: state.tickets.ticketsMap.toArray().reduce((mp, tick) => mp.set(tick.orderId, tick), Map())
    }
    }, []));
    const dispatch = useDispatch();
    console.log('custSet = ', custSet);
    // console.log('render: orders = ', orders);

    useEffect(() => {
        dispatch(customersActions.loadAllCustomers()).then((pld) => {
            // dispatch(ordersActions.loadAllOrders());
            dispatch(ordersActions.loadYoungOrders()).then(x => {
                dispatch(ordersActions.loadFreshOrders());
            });
            // console.log('loadAllCustomers: pld = ', pld);
            // let pkMap = pld.customers.reduce((mp, c) => mp.set(c.paykeeper_id, c), Map());
            // setTimeout(() => {
            //     OrdersAPI.getAllOrders().then(arr => {
            //         let sArr = arr.filter(a => (custSet.has(a.paykeeper_id))).sort((a, b) => ((+new Date(b.createdAt)) - (+new Date(a.createdAt)))).map(a => ({
            //             ...a,
            //             customer: pkMap.get(a.paykeeper_id)
            //         }));
            //         setOrders(sArr);
            //     });
            // }, 400);
        });
    }, []);
    // let sArr = arr.filter(a => (custSet.has(a.paykeeper_id))).sort((a, b) => ((+new Date(b.createdAt)) - (+new Date(a.createdAt)))).map(a => ({
    //     ...a,
    //     customer: pkMap.get(a.paykeeper_id)
    // }));

    // console.log('AllOrdersPanel: render: orders = ', orders);
    // let revOrders = orders.sort((a, b) => (+new Date(a.createdAt) - +new Date(b.createdAt)));
    // let enhOrders = [], prevSum = 0;
    // console.log('revOrders = ', revOrders);
    // for (let i in revOrders) {
    //     let ord = JSON.parse(JSON.stringify(revOrders[i]));
    //     let {price_for_customer, price_for_paykeeper, codData} = ord;
    //     let insurancePayment = (codData == undefined || codData.goods.length == 0) ? 0 : (codData.insuranceEnabled == true ? 0.0075 * codData.goods.reduce((sm, g) => (+sm + g.pricePerOne * g.quantity), 0) : 0);
    //     let codPayment = ((codData == undefined) ? 0 : 0.03 * codData.goods.reduce((sm, g) => (+sm + g.pricePerOne * g.quantity), 0));
    //     let revenue = Math.round(0.94 * (+price_for_customer) - +price_for_paykeeper - codPayment - insurancePayment);
    //     let sumRevenue = +prevSum + +revenue;
    //     ord.revenue = revenue;
    //     ord.sumRevenue = sumRevenue;
    //     enhOrders.push(ord);
    //     prevSum = sumRevenue;
    // }
    // enhOrders = enhOrders.sort((a, b) => (+new Date(b.createdAt) - +new Date(a.createdAt)));
    // console.log('enhOrders = ', enhOrders);
    let enhOrders = AnalysisHelper.getEnhOrders(orders);

    let filteredOrders = (selectedPaykeeperId == undefined) ? enhOrders : enhOrders.filter(a => (a.paykeeper_id == selectedPaykeeperId));
    filteredOrders = (ticketsFilterEnabled == false) ? filteredOrders : filteredOrders.filter(a => (ticketsMap.get(a.id) != undefined));

    const customersOptions = customers.map(a => ({
        label: `${a.paykeeper_id} ${a.legal_entity_name}`,
        value: a.paykeeper_id
    }));
    const selectedOption = (selectedPaykeeperId == undefined) ? undefined : customersOptions.filter(a => (a.value == selectedPaykeeperId))[0];

    return (
        <Wrapper>

            <Tabs tabs={[
                {
                    label: 'Все заказы',
                    content: (
                        <TabItem>
                            <PaginatedOrdersTable orders={filteredOrders}
                                                  ticketsMap={ticketsMap}
                                                  ticketsFilterEnabled={ticketsFilterEnabled}
                                                  onTicketsToggle={() => {
                                                      setTicketsFilterEnabled(!ticketsFilterEnabled)
                                                  }}
                                                  onTicketCreate={async (ordId, order) => {
                                                      console.log('onTicketCreate: order = ', order);
                                                      await dispatch(ticketsActions.createTicket({
                                                          orderId: ordId,
                                                          managerId: currentUserId,
                                                          paykeeper_id: order.paykeeper_id
                                                      }))
                                                  }}
                                                  onDelete={async ordId => {
                                                      console.log('PaginatedOrdersTable: onDelete: ordId = ', ordId);
                                                      let ord = enhOrders.filter(a => (a.id == ordId))[0];
                                                      if (ord == undefined) {
                                                          return;
                                                      }
                                                      let {paykeeper_id} = ord;
                                                      let c = customers.filter(a => (a.paykeeper_id == paykeeper_id));
                                                      if (c == undefined) {
                                                          return;
                                                      }
                                                      let {secret} = c;
                                                      if (window.confirm('Are you sure?') == false) {
                                                          return;
                                                      }
                                                      await OrdersAPI.deleteOrder(ordId, paykeeper_id, secret);
                                                      window.location.reload();
                                                  }}
                                                  topRightContent={(
                                                      <div style={{width: window.innerWidth * 0.4}}>
                                                          <Select options={customersOptions}
                                                                  placeholder={'Фильтр по клиентам'}
                                                                  onChange={a => {
                                                                      if (a == undefined) {
                                                                          setSelectedPaykeeperId(undefined);
                                                                          return;
                                                                      }
                                                                      setSelectedPaykeeperId(a.value);
                                                                  }}/>
                                                      </div>
                                                  )}
                            />
                        </TabItem>
                    )
                },
                {
                    label: 'График',
                    content: (
                        <TabItem>
                            <Tabs tabs={[
                                // {
                                //     label: 'По дням',
                                //     content: (
                                //         <TabItem>
                                //             <div style={{width: '100%', height: '70vh'}}>
                                //                 <TrafficBarChart orders={orders}/>
                                //             </div>
                                //         </TabItem>
                                //     )
                                // },
                                {
                                    label: 'По неделям',
                                    content: (
                                        <TabItem>
                                            <div style={{width: '100%', height: '70vh'}}>
                                                <TrafficBarChart
                                                    tickFormatter={t => {
                                                        // let fromWeekS = moment().startOf('isoweek');
                                                        return `${moment(t).startOf('isoweek').format('DD.MM')} - ${moment(t).endOf('isoweek').format('DD.MM')}`
                                                    }}
                                                    orders={orders}
                                                    mode={'week'}
                                                />
                                            </div>
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'По месяцам',
                                    content: (
                                        <TabItem>
                                            <div style={{width: '100%', height: '70vh'}}>
                                                <TrafficBarChart
                                                    tickFormatter={t => {
                                                        // let fromWeekS = moment().startOf('isoweek');
                                                        return `${moment(t).startOf('month').format('DD.MM')} - ${moment(t).endOf('month').format('DD.MM')}`
                                                    }}
                                                    orders={orders}
                                                    mode={'month'}
                                                />
                                            </div>
                                        </TabItem>
                                    )
                                }
                            ]}/>
                        </TabItem>
                    )
                }
            ]}/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const OrdersList = styled.div`
    
`;

const OrderItem = styled.div`
    background: white;
    padding: 5px;
    border-radius: 4px;
    margin-bottom: 10px;
`;

const Name = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const Value = styled.div`
    
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`;

const Half = styled.div`
    flex: 1;
`;

const Textarea = styled.textarea`
    width: 100%;
    box-sizing: border-box;
    min-height: 18em;
`;

const UpdateStatusButton = styled.div`
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    background: pink;
`;
