import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CdekAPI from "../../../api/CdekAPI";

export default function CalculatorResultsTool(props) {
    const {from, to} = props;
    const [data, setData] = useState(undefined);
    useEffect(() => {
        CdekAPI.getCalcDefaultPriceInfo(from, to).then(pld => {
            setData(pld);
        })
    }, [from, to])

    return (
        <Wrapper>
            {JSON.stringify(data)}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
