import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {API_ENDPOINT} from "../../constants/config";

import * as usersActions from '../../redux/actions/UsersActions'
import {useDispatch, useMappedState} from "redux-react-hook";
import StyledLoginForm from "../auth/forms/StyledLoginForm";
import RecoverPasswordForm from "../auth/forms/RecoverPasswordForm";
import StyledSignupForm from "../auth/forms/StyledSignupForm";

import axios from 'axios'

export default function BrandedLoginApp(props) {

    const mapState = useCallback(
        state => ({
            initialized: state.users.initialized,
            loading: state.users.loading,
            currentUser: state.users.currentUserId == undefined ? null : state.users.usersMap.get(state.users.currentUserId)
        }), []);
    const {initialized, currentUser, loading} = useMappedState(mapState);
    const dispatch = useDispatch();
    const [mode, setMode] = useState('login');
    const [recovering, setRecovering] = useState(false);
    const [registering, setRegistering] = useState(false);

    return (
        <Wrapper>

            <LoginInnerPanel>

                <LeftBg src={require('../../assets/images/login_scr_left.svg')}/>
                <RightBg src={require('../../assets/images/login_scr_right.svg')}/>

                <Inner>

                    {mode != 'login' ? null :
                        <StyledLoginForm loading={loading} onSubmit={data => {
                            dispatch(usersActions.logIn(data)).then(pld => {
                                console.log('pld = ', pld);
                                if (pld.error != undefined) {
                                    window.alert(pld.error.message);
                                }
                            })
                        }}
                                         onLostPasswordClick={() => {
                                             setMode('lost_password');
                                         }}
                                         onSignUpClick={() => {
                                             setMode('signup');
                                         }}
                        />
                    }

                    {mode != 'signup' ? null :
                        <StyledSignupForm
                            loading={registering}
                            onLostPasswordClick={() => {
                                setMode('lost_password');
                            }}

                            onSignUpClick={() => {
                                setMode('signup');
                            }}
                            onLoginClick={() => {
                                setMode('login');
                            }}
                            onSubmit={data => {
                                setRegistering(true);
                                axios.post(`${API_ENDPOINT}/users/create`, data).then(d => d.data).then(pld => {
                                    if (pld.error != undefined) {
                                        window.alert(pld.error.message);
                                        setRegistering(false);
                                        return;
                                    }
                                    setRegistering(false);
                                    dispatch(usersActions.logIn(data)).then(pld => {
                                        console.log('signing in');
                                    });
                                })
                            }}
                        />
                    }

                    {mode != 'lost_password' ? null :

                        <RecoverPasswordForm
                            loading={recovering}
                            onLostPasswordClick={() => {
                                setMode('lost_password');
                            }}
                            onSignUpClick={() => {
                                setMode('signup');
                            }}
                            onLoginClick={() => {
                                setMode('login');
                            }}
                            onSubmit={email => {
                                setRecovering(true);
                                axios.post(`${API_ENDPOINT}/recover`, {email: email}).then(d => d.data).then(pld => {
                                    console.log('pld = ', pld);
                                    if (pld.error != undefined) {
                                        setRecovering(false);
                                        window.alert(pld.error.message);
                                        return;
                                    }
                                    window.alert('Please check your inbox');
                                    setRecovering(false);
                                })
                            }}
                        />

                    }

                </Inner>

            </LoginInnerPanel>

        </Wrapper>
    );
}


const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    position: absolute;
    z-index: 1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
`;

const LeftBg = styled.img`
    position: fixed;
    left: 0px;
    bottom: 0px;
    top: 0px;
`;

const RightBg = styled.img`
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
`;

const LoginInnerPanel = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #181923;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

const Inner = styled.div`
    background: #171822;
    border: 2px solid rgba(255, 255, 255, 0.03);
    box-sizing: border-box;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    width: 520px;
    padding: 35px;
    padding-left: 47px;
    padding-right: 47px;
    z-index: 10000000;
    @media(max-width: 540px){
      width: 100%;
    }
`;

const TopLogo = styled.img`
    margin-bottom: 40px;
    margin-top: 10px;
    
    width: 107px;
    height: 104.44px;
    @media(max-height: 720px){
      display: none;
    }
`;
