import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import SimpleUploadPanel from './SimpleUploadPanel'

// import u from '@re'
import Spin from 'arui-feather/spin'


export default function CoveredUploadPanel(props) {
    const ref = useRef(null);
    const [loading, setLoading] = useState();
    const {
        url, onChange = uUrl => {
        }
    } = props;

    return (
        <Wrapper ref={ref} className={'CoveredUploadPanel'} >
            <SimpleUploadPanel onUploaded={url => {
                onChange(url);
                setLoading(false);
            }} onStart={() => {
                setLoading(true);
            }} onFail={err => {
                setLoading(false);
                window.alert(JSON.stringify(err));
            }}>
                <Inner src={url}>
                    <Spin visible={loading}/>
                </Inner>
            </SimpleUploadPanel>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    :hover{
      opacity: 0.9;
    }
`;

const Inner = styled.div`
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => props.src});
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
`;

const SpinnerPlaceholder = styled.div`
    
`;
