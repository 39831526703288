import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

export default function DevPriceOptionsView(props) {
    let {guest, paykeeper, custom_paykeeper} = props;
    if (guest == undefined || paykeeper == undefined) {
        return null;
    }

    return (
        <Wrapper>
            <Row>

                <Item>
                    <h3>
                        Гость (по API CDEK)
                    </h3>
                    <Field>
                        {`Склад-Склад: `}
                        {guest.w_w == undefined ? 'N/A' :
                            <span>
                            {`${guest.w_w.price} (тариф ${guest.w_w.tariffId})`}
                        </span>
                        }
                    </Field>
                    <Field>
                        {`Склад-Дверь: `}
                        {guest.w_d == undefined ? 'N/A' :
                            <span>
                            {`${guest.w_d.price} (тариф ${guest.w_d.tariffId})`}
                        </span>
                        }
                    </Field>
                    <Field>
                        <pre dangerouslySetInnerHTML={{__html: JSON.stringify(guest, null, 1)}} ></pre>
                    </Field>
                </Item>

                <Item>
                    <h3>
                        Условия для Paykeeper (по API CDEK)
                    </h3>
                    <Field>
                        {`Склад-Склад: `}
                        {paykeeper.w_w == undefined ? 'N/A' :
                            <span>
                            {`${paykeeper.w_w.price} (тариф ${paykeeper.w_w.tariffId})`}
                        </span>
                        }
                    </Field>
                    <Field>
                        {`Склад-Дверь: `}
                        {guest.w_d == undefined ? 'N/A' :
                            <span>
                            {`${paykeeper.w_d.price} (тариф ${paykeeper.w_d.tariffId})`}
                        </span>
                        }
                    </Field>
                    <Field>
                        <pre dangerouslySetInnerHTML={{__html: JSON.stringify(paykeeper, null, 1)}} ></pre>
                    </Field>
                </Item>

                <Item>
                    <h3>
                        Условия из Экселя
                    </h3>
                    <Field>
                        {`Склад-Склад: `}
                        {(custom_paykeeper.w_w == undefined || custom_paykeeper.w_w.tariff == undefined) ? 'N/A' :
                            <span>
                            {`${custom_paykeeper.w_w.price} (тариф ${custom_paykeeper.w_w.tariff.name})`}
                        </span>
                        }
                    </Field>
                    <Field>
                        {`Склад-Дверь: `}
                        {(custom_paykeeper.w_d == undefined || custom_paykeeper.w_d.tariff == undefined) ? 'N/A' :
                            <span>
                            {`${custom_paykeeper.w_d.price} (тариф ${custom_paykeeper.w_d.tariff.name})`}
                        </span>
                        }
                    </Field>
                    <Field>
                        <pre dangerouslySetInnerHTML={{__html: JSON.stringify(custom_paykeeper, null, 1)}} ></pre>
                    </Field>
                </Item>


            </Row>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`;

const Item = styled.div`
    padding: 10px;
`;

const Field = styled.div`
    
`;
