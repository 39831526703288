import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as tariffsActions from "../../../redux/actions/TariffsActions";
import * as appsActions from "../../../redux/actions/AppsActions";
import {DARK_SIDE} from "../../ui/Colors";
import UpdateTariffForm from "../forms/UpdateTariffForm";

import {Button} from "../../ui/Button";
import {Input} from "../../ui/Input";

import Tabs, {TabItem} from "../../ui/Tabs";
import TariffCalcTool from "../tools/TariffCalcTool";
import PaginatorWrapper from "../../paginator/PaginatorWrapper";
import CdekAPI from "../../../api/CdekAPI";
import useDebouncedCallback from "use-debounce/lib/callback";
import {DELIVERY_TYPES_OPTIONS} from "../../../helpers/TariffsHelper";

const getFriendlyTypeName = tp => {
    let d = (DELIVERY_TYPES_OPTIONS.filter(a => (a.value == tp)))[0];
    return d.label;
}

export default function TariffsPanel(props) {
    const {
        tariffs, loading
    } = useMappedState(useCallback(state => {
        return {
            loading: state.tariffs.loading,
            tariffs: state.tariffs.tariffsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp))
        }
    }, []));
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedId, setSelectedId] = useState(undefined);
    const [addVisible, setAddVisible] = useState(false);

    useEffect(() => {
        dispatch(tariffsActions.loadAllTariffs());
    }, []);

    const [debouncedSearch] = useDebouncedCallback((a) => {
        console.log('searching: a = ', a);
        setSearchQuery(a);
    }, 300, []);

    if (tariffs.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    const selectedTariff = (selectedId == undefined) ? undefined : tariffs.filter(a => (a.id == selectedId))[0];
    const filteredTariffs = tariffs.filter(a => {
        return (searchQuery == undefined || searchQuery.trim() == '' || (a.name != undefined && a.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1));
    });


    return (
        <Wrapper>

            <TopPlaceholder>
                <Button onClick={() => {
                    setAddVisible(true);
                }}>
                    Добавить тариф
                </Button>
            </TopPlaceholder>

            <SearchPlaceholder>
                <Input placeholder={'Поиск'} onChange={evt => {
                    let s = evt.target.value;
                    debouncedSearch(s);
                }}/>
            </SearchPlaceholder>

            <ListPlaceholder>

                <PaginatorWrapper items={filteredTariffs} renderItems={items => items.map((a, k) => {
                    let {
                        name, delivery_type,
                        from_code, to_code, partnerDiscountType, partnerFixDiscount, partnerPercentDiscount,
                        customerDiscountType, customerFixDiscount, customerPercentDiscount, partner, status
                    } = a;
                    return (
                        <Item selected={(selectedId == a.id)} key={a.id} onClick={() => {
                            setSelectedId(a.id);
                        }}>
                            <Left>
                                <Name>
                                    {`${name} (${from_code} -> ${to_code})`}
                                </Name>
                                <Description>
                                    {`${partner}`}
                                </Description>
                            </Left>
                            <Right>
                                <ExpirationDate>
                                    {`${getFriendlyTypeName(delivery_type)}`}
                                </ExpirationDate>
                            </Right>
                        </Item>
                    )
                })}/>


            </ListPlaceholder>

            <Sidebar width={Math.min(1020, window.innerWidth)} visible={addVisible} onCloserClick={() => {
                setAddVisible(false);
            }}>

                {addVisible == false ? null :
                    <UpdateTariffForm loading={loading}
                                      onSave={async d => {
                                          await dispatch(tariffsActions.createTariff(d));
                                          setAddVisible(false);
                                      }}/>
                }

            </Sidebar>

            <Sidebar width={Math.min(1020, window.innerWidth)} visible={(selectedTariff != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedTariff == undefined ? null :

                    <div>
                        <Tabs tabs={[
                            {
                                label: 'Редактирование',
                                content: (
                                    <TabItem>
                                        <UpdateTariffForm {...selectedTariff}
                                                          canDelete={false}
                                                          loading={loading}
                                                          onSave={async d => {
                                                              await dispatch(tariffsActions.updateTariff({
                                                                  ...d,
                                                                  id: selectedTariff.id
                                                              }));
                                                              // setSelectedId(undefined);
                                                          }}
                                                          onDelete={async () => {
                                                              await dispatch(tariffsActions.deleteTariff(selectedTariff.id));
                                                              setSelectedId(undefined);
                                                          }}
                                        />
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Расчет суммы доставки',
                                content: (
                                    <TabItem>
                                        <TariffCalcTool {...selectedTariff} />
                                    </TabItem>
                                )
                            }
                        ]}/>
                    </div>

                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin-bottom: 140px;
`;

const ListPlaceholder = styled.div`
    
`;

const Item = styled.div`
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${props => (props.selected == true ? DARK_SIDE : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const Name = styled.div`
    font-weight: bold;
`;

const Description = styled.div`
    
`;

const Left = styled.div`
    
`;

const Right = styled.div`
    
`;

const ExpirationDate = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 20px;
`;

const SearchPlaceholder = styled.div`
    margin-bottom: 10px;
`;
