import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ui/Input";

import useDebouncedCallback from "use-debounce/lib/callback";
import CdekAPI from "../../../api/CdekAPI";

export default function SimpleSearchBarTool(props) {
    const [text, setText] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const [debouncedSearch] = useDebouncedCallback((a) => {
        console.log('searching: a = ', a);
        if (a == undefined || a.trim() == '') {
            return;
        }
        setText(a);
        setResults([]);
        setLoading(true);
        CdekAPI.getAddressResults(a).then(pld => {
            console.log('pld = ', pld);
            setResults(pld);
            setLoading(false);
        })
    }, 300, []);


    return (
        <Wrapper>

            <InputPlaceholder>
                <InpPlaceholder>
                    <Input placeholder={'Населенный пункт'} onChange={evt => {
                        debouncedSearch(evt.target.value);
                    }}/>
                </InpPlaceholder>
            </InputPlaceholder>

            <Spin visible={loading}/>

            {((text == undefined || text.trim() == '') && results.length == 0) ?
                <ResultsPlaceholder>
                    введите поисковый запрос
                </ResultsPlaceholder> :
                <ResultsPlaceholder>
                    {results.length == 0 ?
                        <div>
                            Нет результатов
                        </div>
                        :
                        <Table>
                            <Thead>
                            <Tr>
                                <Th>
                                    Город
                                </Th>
                                <Th>
                                    Код города
                                </Th>
                                <Th>
                                    Регион
                                </Th>
                                <Th>
                                    Код региона
                                </Th>
                                <Th>
                                    Страна
                                </Th>
                            </Tr>
                            </Thead>

                            <Tbody>
                            {results.map((res, k) => {
                                let {cityUuid, region, regionCode, cityName, cityCode, country} = res;
                                return (
                                    <Tr key={`${k}_${cityUuid}`}>
                                        <Td>
                                            {`${cityName}`}
                                        </Td>
                                        <Td>
                                            {`${cityCode}`}
                                        </Td>
                                        <Td>
                                            {`${region}`}
                                        </Td>
                                        <Td>
                                            {`${regionCode}`}
                                        </Td>
                                        <Td>
                                            {`${country}`}
                                        </Td>
                                    </Tr>
                                )
                            })}
                            </Tbody>

                        </Table>
                    }
                </ResultsPlaceholder>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
    box-sizing: border-box;
    background: white;
    padding: 10px;
    border-radius: 4px;
`;

const InputPlaceholder = styled.div`
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LoaderPlaceholder = styled.div`
    width: 60px;
`;

const InpPlaceholder = styled.div`
    flex: 1;
`;

const ResultsPlaceholder = styled.div`
    
`;

const ResItem = styled.div`
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid lightgrey;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const MRow = styled.div`
    flex: 1;
    text-align: left;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border: 1px solid lightgrey;
`;

const Tbody = styled.tbody`
    
`;

const Thead = styled.thead`
    
`;

const Tr = styled.tr`
    
`;

const Td = styled.td`
    font-weight: normal;
    text-align: center;
    border: 1px solid lightgrey;
    padding: 5px;
`;

const Th = styled.th`
    text-align: center;
    border: 1px solid lightgrey;
    padding: 5px;
`;
