import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    appsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const AppsReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_APP:
        case types.UPDATE_APP:
        case types.DELETE_APP:
        case types.LOAD_APPS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_APP_FAIL:
        case types.UPDATE_APP_FAIL:
        case types.DELETE_APP_FAIL:
        case types.LOAD_APPS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_APP_SUCCESS:
        case types.UPDATE_APP_SUCCESS:
            return {
                ...state,
                loading: false,
                appsMap: state.appsMap.set(action.app.id, action.app)
            }


        case types.DELETE_APP_SUCCESS:
            return {
                ...state,
                loading: false,
                appsMap: state.appsMap.delete(action.id),
            }


        case types.LOAD_APPS_SUCCESS:
            return {
                ...state,
                loading: false,
                appsMap: state.appsMap
                    .merge(action.apps.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default AppsReducer;
