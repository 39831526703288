import styled from 'styled-components'
import React from 'react'

export const Button = styled.div`
    height: 50px;
    background: #E58201;
    border-radius: 5px;
    cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
    opacity: ${props => (props.disabled == true ? 0.5 : 1)};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    :hover{
      opacity: ${props => (props.disabled == true ? 0.5 : 0.8)};
    }
`;

export const Input = styled.input`
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    outline: none;
    text-align: center;
    font-size: 18px;
    :focus{
      border-color: black;
    }
`;


export function LinedText(props) {
    let {text} = props;
    return (
        <LinedTextWrapper>
            <Line/>
            <LinedTextComp>
                <TextInner>
                    {text}
                </TextInner>
            </LinedTextComp>
        </LinedTextWrapper>
    );
}

const LinedTextWrapper = styled.div`
    position: relative;
    height: 50px;
    text-align: center;
    line-height: 50px;
    box-sizing: border-box;
    width: 100%;
`;

const LinedTextComp = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
`;

const TextInner = styled.div`
    background: white;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    z-index: 1;
    font-size: 18px;
    line-height: 50px;
    text-transform: uppercase;
    font-weight: bold;
`;

const Line = styled.div`
    position: relative;
    z-index: 0;
    top: 25px;
    height: 1px;
    width: 100%;
    background: whitesmoke;
`;
