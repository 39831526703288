import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CitySearchableSelect from "../../cdek/tools/CitySearchableSelect";
import CityInfoTool from "../../cdek/tools/CityInfoTool";

import Select from 'react-select'

import {Input} from "../../ui/Input";
import {Button} from "../../ui/Button";
import CdekAPI from "../../../api/CdekAPI";


export const TYPES_OPTIONS = [
    {
        label: 'Дверь-Дверь',
        value: 'door-door'
    },
    {
        label: 'Дверь-ПВЗ',
        value: 'door-warehouse'
    },
    {
        label: 'ПВЗ-ПВЗ',
        value: 'warehouse-warehouse'
    },
    {
        label: 'ПВЗ-Дверь',
        value: 'warehouse-door'
    }
];

export default function UpdateCustomTariffForm(props) {
    const {
        loading = false,
        onSave = d => {

        }
    } = props;


    const [innerLoading, setInnerLoading] = useState(false);
    const [suggestionData, setSuggestionData] = useState(undefined);

    const [type, setType] = useState(props.type == undefined ? 'door-door' : props.type);

    const [fromCode, setFromCode] = useState(props.fromCode == undefined ? '' : props.fromCode);
    const [toCode, setToCode] = useState(props.toCode == undefined ? '' : props.toCode);

    const [basePrice, setBasePrice] = useState(props.basePrice == undefined ? '' : props.basePrice);
    const [baseWeight, setBaseWeight] = useState(props.baseWeight == undefined ? 3 : props.baseWeight);
    const [extPrice, setExtPrice] = useState(props.extPrice == undefined ? '' : props.extPrice);


    useEffect(() => {
        setInnerLoading(true);
        setSuggestionData(undefined);
        CdekAPI.getSmartSuggestions(fromCode, toCode).then(pld => {
            setSuggestionData(pld);
            // setBaseWeight();
            console.log('sugg data = ', pld);
            if (pld != undefined && pld.minValues != undefined) {
                setBaseWeight(pld.minValues.baseWeight);
            }
            setInnerLoading(false);
        })
    }, [fromCode, toCode]);

    console.log('render: suggestionData = ', suggestionData);

    const typesOptions = (innerLoading == true || suggestionData == undefined) ? [] : TYPES_OPTIONS.filter(a => (suggestionData.availableTypes.indexOf(a.value) > -1));

    let minValuesData = (suggestionData == undefined || suggestionData.minValues[type] == undefined) ? undefined : suggestionData.minValues[type];

    return (
        <Wrapper>

            <Row>
                <Field>
                    <Label>
                        Откуда
                    </Label>
                    <CitySearchableSelect code={fromCode} onChange={newCode => {
                        setFromCode(newCode);
                    }}/>
                    <CityInfoTool code={fromCode}/>
                </Field>
                <Field>
                    <Label>
                        Куда
                    </Label>
                    <CitySearchableSelect code={toCode} onChange={newCode => {
                        setToCode(newCode);
                    }}/>
                    <CityInfoTool code={toCode}/>
                </Field>

            </Row>

            <Spin visible={innerLoading}/>

            {(fromCode == undefined || fromCode == '' || toCode == undefined || toCode == '' || suggestionData == undefined) ? null :
                <Row>
                    <Field>
                        <Label>
                            Тип
                        </Label>
                        <Select options={typesOptions}
                                value={typesOptions.filter(a => (a.value == type))[0]}
                                onChange={a => {
                                    setType(a.value);
                                }}
                        />
                    </Field>
                </Row>
            }

            <Row>
                <Field>
                    <Label>
                        Базовый вес
                    </Label>
                    <Input disabled={true} value={baseWeight} onChange={evt => {
                        setBaseWeight(evt.target.value);
                    }}/>
                    {/*{minValuesData == undefined ? null :*/}
                        {/*<MinDiv>*/}
                            {/*{`Необходимое значение: ${minValuesData.basePrice}`}*/}
                        {/*</MinDiv>*/}
                    {/*}*/}
                </Field>
                <Field>
                    <Label>
                        Базовая цена за базовый вес
                    </Label>
                    <Input value={basePrice} onChange={evt => {
                        setBasePrice(evt.target.value);
                    }}/>
                    {minValuesData == undefined ? null :
                        <MinDiv>
                            {`Значение по умолчанию: ${minValuesData.defaultBasePrice}`}
                            <br/>
                            {`Минимальное значение: ${minValuesData.basePrice}`}
                        </MinDiv>
                    }

                </Field>
                <Field>
                    <Label>
                        Цена доп. кг сверх базового веса
                    </Label>
                    <Input value={extPrice} onChange={evt => {
                        setExtPrice(evt.target.value);
                    }}/>
                    {minValuesData == undefined ? null :
                        <MinDiv>
                            {`Значение по умолчанию: ${minValuesData.defaultExtPrice}`}
                            <br/>
                            {`Минимальное значение: ${minValuesData.extPrice}`}
                        </MinDiv>
                    }
                </Field>
            </Row>

            <SaveButtonPlaceholder>

                <Spin visible={loading || innerLoading}/>

                {loading == true || innerLoading == true ? null :
                    <Button onClick={() => {
                        onSave({
                            fromCode,
                            toCode,
                            type,
                            baseWeight: +baseWeight,
                            basePrice: +basePrice,
                            extPrice: +extPrice,
                        });
                    }}>
                        Сохранить
                    </Button>
                }

            </SaveButtonPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const MinDiv = styled.div`
    font-size: 12px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const Field = styled.div`
    flex: 1;
    box-sizing: border-box;
    padding: 5px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const SaveButtonPlaceholder = styled.div`
    margin-top: 20px;
`;
