import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";
import {Button} from "../../ui/Button";
import KvdbAPI from "../../../api/KvdbAPI";


export default function UpdateTemplateForm(props) {
    const {
        loading = false,

        onSave = d => {

        }

    } = props;

    const [parametersDataString, setParametersDataString] = useState(props.parametersDataString == undefined ? '' : props.parametersDataString);

    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [html, setHtml] = useState(props.html == undefined ? '' : props.html);
    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);

    return (
        <Wrapper>
            <Field>
                <Label>
                    Название шаблона
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>


            <Field style={{flex: 1, padding: 5}}>
                <Label>
                    Параметры
                </Label>
                <Textarea value={parametersDataString} onChange={evt => {
                    setParametersDataString(evt.target.value);
                }}/>
            </Field>

            <Row>
                <Field style={{flex: 1, padding: 5}}>
                    <Label>
                        HTML
                    </Label>
                    <Textarea value={html} onChange={evt => {
                        setHtml(evt.target.value);
                    }}/>
                </Field>
                <Field style={{flex: 1, padding: 5}}>
                    <h3>
                        Preview
                    </h3>
                    <div>
                        <div dangerouslySetInnerHTML={{__html: html}}>
                        </div>
                    </div>

                </Field>
            </Row>

            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button onClick={() => {
                        onSave({
                            name,
                            html,
                            description,
                            parametersDataString
                        });
                    }}>
                        Сохранить
                    </Button>
                }
            </Field>
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Field = styled.div`

`;

const Row = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
`;

const Label = styled.div`
font-weight: bold;
margin-bottom: 5px;
`;
