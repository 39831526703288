import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Button} from "../../ui/Button";

import Select from 'react-select'

const ACTIVE_OPTIONS = [
    {
        label: 'Да',
        value: true
    },
    {
        label: 'Нет',
        value: false
    },

]

export default function UpdateWidgetForm(props) {
    const {
        loading,
        onSave = d => {

        }
    } = props;
    const [version, setVersion] = useState(props.version == undefined ? '' : props.version);
    const [widgetUrl, setWidgetUrl] = useState(props.widgetUrl == undefined ? '' : props.widgetUrl);
    const [active, setActive] = useState(props.active == undefined ? false : props.active);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Ссылка на файл виджета
                </Label>
                <Input value={widgetUrl} onChange={evt => {
                    setWidgetUrl(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Версия
                </Label>
                <Input value={version} onChange={evt => {
                    setVersion(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Активен
                </Label>
                <Select options={ACTIVE_OPTIONS} value={ACTIVE_OPTIONS.filter(x => (x.value == active))[0]}
                        onChange={a => {
                            setActive(a.value);
                        }}/>
            </Field>


            <BottomRow>
                <Button onClick={() => {
                    onSave({
                        version: version,
                        widgetUrl: widgetUrl,
                        active: active
                    });
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null : 'Сохранить'}
                </Button>
            </BottomRow>


        </Wrapper>
    );
}

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const Wrapper = styled.div`

`;

const Field = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding-left: 5px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  height: 32px;

  :focus {
    border-color: blue;
  }
`;
