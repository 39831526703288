import moment from 'moment-timezone'
import {DEFAULT_TIMEZONE} from "../constants/config";

export const PARTNER_TARIFFS_TYPES_OPTIONS = [
    {
        label: 'Фиксированная сумма доставки',
        value: 'fix-price'
    },
    {
        label: 'Фиксированная скидка',
        value: 'fix-discount'
    },
    {
        label: 'Скидка в процентах от стоимости доставки',
        value: 'percent-discount'
    }
];

export const CUSTOMER_TARIFFS_TYPES_OPTIONS = [
    {
        label: 'Скидка в процентах',
        value: 'percent-discount'
    },
    {
        label: 'Фиксированная сумма скидки',
        value: 'fix-discount'
    },
];

export const PARTNERS_OPTIONS = [
    {
        label: 'СДЭК',
        value: 'CDEK'
    }
];

export const DELIVERY_TYPES_OPTIONS = [
    {
        label: 'От склада до двери',
        value: 'warehouse-door'
    },
    {
        label: 'От склада до склада',
        value: 'warehouse-warehouse'
    }
];

export const DEFAULT_GOODS = [
    {
        weight: 0.5,
        width: 10,
        height: 10,
        length: 10
    }
];

const TariffsHelper = {};

export default TariffsHelper;
