import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import EventsAPI from "../../../api/EventsAPI";
import EventsTable from "../tools/EventsTable";

export default function UserEventsTable(props) {
    const {userId} = props;
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    useEffect(() => {
        setLoading(true);
        EventsAPI.getUserEvents(userId).then(arr => {
            setEvents(arr.sort((a, b) => (+b.t - +a.t)));
            setLoading(false);
        })
    }, [userId]);

    return (
        <Wrapper>
            <EventsListPlaceholder>

                <EventsTable events={events} showUserId={false} />

            </EventsListPlaceholder>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const EventsListPlaceholder = styled.div`
    
`;

const EventItem = styled.div`
    
`;
