import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ui/Input";

import {DEFAULT_GOOD} from "../../tariffs/tools/CdekCalculatorTool";

export default function GoodsEditorTool(props) {
    // const [goods, setGoods] = useState(props.goods == undefined ? [{weight: 0.5, id: uuid()}] : props.goods);
    const {
        goods = [],
        onChange = newGoods => {

        }
    } = props;

    return (
        <Wrapper>

            <Row>
                <RowItem>
                    Вес (в кг)
                </RowItem>
                <RowItem style={{width: 200}} >
                    Габариты ДхШхВ (в см)
                </RowItem>
            </Row>

            {goods.map((g, k) => {
                let {weight, length, width, height} = g;
                return (
                    <Row key={g.id}>
                        <RowItem>
                            <Input value={weight} onChange={evt => {
                                let s = evt.target.value.replace(',', '.');
                                onChange(goods.map((a, j) => (j == k ? ({...a, weight: s}) : a)));
                            }}/>
                        </RowItem>
                        <RowItem>
                            <Input value={width} onChange={evt => {
                                let s = evt.target.value.replace(',', '.');
                                onChange(goods.map((a, j) => (j == k ? ({...a, width: s}) : a)));
                            }}/>
                        </RowItem>
                        <NarrowRowItem>
                            x
                        </NarrowRowItem>
                        <RowItem>
                            <Input value={height} onChange={evt => {
                                let s = evt.target.value.replace(',', '.');
                                onChange(goods.map((a, j) => (j == k ? ({...a, height: s}) : a)));
                            }}/>
                        </RowItem>
                        <NarrowRowItem>
                            x
                        </NarrowRowItem>
                        <RowItem>
                            <Input value={length} onChange={evt => {
                                let s = evt.target.value.replace(',', '.');
                                onChange(goods.map((a, j) => (j == k ? ({...a, length: s}) : a)));
                            }}/>
                        </RowItem>
                    </Row>
                )
            })}

            <BottomPlaceolder>
                <AddSpan onClick={() => {
                    let newGoods = goods.concat([{...DEFAULT_GOOD, id: uuid()}]);
                    console.log(newGoods);
                    onChange(newGoods);
                }}>
                    Добавить еще место
                </AddSpan>
            </BottomPlaceolder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
`;

const RowItem = styled.div`
    width: 120px;
    margin-right: 10px;
    margin-left: 10px;
    box-sizing: border-box;
    :first-of-type{
      margin-left: 0px;
    }
`;

const NarrowRowItem = styled.div`
    width: 20px;
    text-align: center;
    box-sizing: border-box;
    opacity: 0.5;
`;

const BottomPlaceolder = styled.div`
    margin-top: 10px;
`;

const AddSpan = styled.span`
    opacity: 0.8;
    cursor: pointer;
    text-decoration: underline;
    :hover{
      opacity: 1;
    }
`;

