import {KVDB_API_ENDPOINT} from "../constants/config";
import axios from 'axios'

export default class KvdbAPI {

    static async saveData(key, data) {
        console.log('saveData: key, data = ', key, data);
        let pld = (await axios.post(`${KVDB_API_ENDPOINT}/key/${key}`, {
            data: data
        }, {
            headers: {
                'x-kvdb-token': 'sabirmipt'
            }
        })).data;
        if (pld.error != undefined) {
            throw pld.error;
        }
        return pld.result;
    }

    static async getData(key) {
        let pld = (await axios.get(`${KVDB_API_ENDPOINT}/key/${key}`, {
            headers: {
                'x-kvdb-token': 'sabirmipt'
            }
        })).data;
        if (pld.error != undefined) {
            throw pld.error;
        }
        return pld.result;
    }

    static async getItemsByPrefix(prefix) {
        let pld = (await axios.get(`${KVDB_API_ENDPOINT}/keys/${prefix}`, {
            headers: {
                'x-kvdb-token': 'sabirmipt'
            }
        })).data;
        if (pld.error != undefined) {
            throw pld.error;
        }
        return pld.result;
    }


}
