import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import MonthCalendar from "../calendars/MonthCalendar";
import SimpleSearchBarTool from "../cdek/tools/SimpleSearchBarTool";
import CitySearchableSelect from "../cdek/tools/CitySearchableSelect";
import CdekCalculatorTool from "../tariffs/tools/CdekCalculatorTool";
import UpdateTariffForm from "../tariffs/forms/UpdateTariffForm";
import UpdateCustomTariffForm from "../custom_tariffs/forms/UpdateCustomTariffForm";


export default function DevApp() {
    const [pieces, setPieces] = useState([]);
    const [t, setT] = useState(undefined);

    const areaRef = useRef(undefined);

    return (
        <Wrapper>

            {/*<CdekCalculatorTool/>*/}

            {/*<SimpleSearchBarTool/>*/}

            {/*<CitySearchableSelect/>*/}

            {/*<UpdateTariffForm/>*/}

            <UpdateCustomTariffForm/>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
