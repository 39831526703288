import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment-timezone';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'

import {DEFAULT_TIMEZONE} from "../../../constants/config";

import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import useComponentSize from '@rehooks/component-size'
import CommonHelper from "../../../helpers/CommonHelper";

const gn = a => (a == undefined ? undefined : (+a).toFixed(2))

export default function RevenueBarChart(props) {
    const {
        points = [],
        tickFormatter = t => moment(t).tz(DEFAULT_TIMEZONE).format('MMMM YYYY')

    } = props;
    const ref = useRef(null);
    let size = useComponentSize(ref);
    let {width, height} = size;

    return (
        <Wrapper ref={ref}>
            <BarChart
                width={width}
                height={height}
                data={points}
                margin={{
                    top: 0, right: 0, left: 0, bottom: 0
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="t" tickFormatter={tickFormatter}/>
                <YAxis/>
                <Legend/>
                <Bar dataKey="revenue" fill="#8884d8"/>
            </BarChart>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const MyTooltip = styled.div`
    padding: 5px;
    border: 1px solid whitesmoke;
    background: rgba(255, 255, 255, 0.99);
`;
