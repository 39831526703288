import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";

export default function OrderStatusPanel(props) {
    let {id} = props;
    let [data, setData] = useState({});

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/order/${id}/status`).then(d => d.data).then(pld => {
            setData(pld);
        });
    }, [id]);

    let {states} = data;
    if (states == undefined) {
        return null;
    }

    return (
        <Wrapper>

            <StatesList>

                {states.map((st, k) => {
                    return (
                        <StateItem key={k}>
                            <Date>
                                {moment(st.state_date).format('DD.MM.YYYY HH:mm:ss')}
                            </Date>
                            <City>
                                {st.state_city_name}
                            </City>
                            <Comment>
                                {st.state_description}
                            </Comment>
                        </StateItem>
                    )
                })}

            </StatesList>

            {JSON.stringify(data)}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const StatesList = styled.div`
    
`;

const StateItem = styled.div`
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const Date = styled.div`
    font-weight: bold;
`;

const City = styled.div`
    
`;

const Comment = styled.div`
    
`;
