import axios from 'axios'
import {API_ENDPOINT, AUTH_API_ENDPOINT} from "../constants/config";

const TicketsAPI = {

    getAllTickets() {
        return new Promise((resolve, reject) => {
            axios.get(`${AUTH_API_ENDPOINT}/tickets/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises.result);
            }).catch(err => reject(err));
        });
    },

    updateTicketStatus(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${AUTH_API_ENDPOINT}/ticket/${id}/status/update`).then(d => d.data).then(pld => {
                if (pld.error != undefined) {
                    return reject(pld.error);
                }
                resolve(pld.result);
            }).catch(err => reject(err));
        });
    },

    getTicket(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${AUTH_API_ENDPOINT}/ticket/${id}`).then(d => d.data).then(doc => {
                if (doc.error != undefined) {
                    return reject(doc.error);
                }
                resolve(doc);
            }).catch(err => reject(err));
        });
    },

    createTicket(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${AUTH_API_ENDPOINT}/tickets`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex.result);
            }).catch(err => reject(err));
        });
    },

    updateTicket(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${AUTH_API_ENDPOINT}/tickets`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },


    async deleteTicket(id) {
        try {
            let pld = (await axios.post(`${AUTH_API_ENDPOINT}/tickets/delete/${id}`, {id: id})).data;
        } catch (exc) {
            console.log('caught exc during trying to delete the ticket: exc = ', exc);
        }
    }

}

export default TicketsAPI;
