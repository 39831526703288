import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const KeyValueAPI = {

    async getParam(key, defaultValue) {
        let pld = (await axios.get(`${API_ENDPOINT}/key/${key}`)).data;
        if (pld.value == undefined) {
            return defaultValue;
        }
        return pld.value;
    },

    async saveParam(key, value) {
        let pld = (await axios.post(`${API_ENDPOINT}/key/${key}`, {value: value})).data;
        return pld;
    }

}

export default KeyValueAPI;
