import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import KvdbAPI from "../../../api/KvdbAPI";
import UpdateTemplateForm from "../forms/UpdateTemplateForm";
import {Button} from "../../ui/Button";
import MailAPI from "../../../api/MailAPI";


export default function MailTemplatesPanel(props) {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedId, setSelectedId] = useState(undefined);
    const [addVisible, setAddVisible] = useState(false);

    useEffect(() => {
        setLoading(true);
        MailAPI.getTemplates().then(arr => {
            console.log('');
            setTemplates(arr);
            setLoading(false);
        })
    }, []);

    let selectedTemplate = (selectedId == undefined) ? undefined : templates.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            <TopPlaceholder>
                <Button onClick={() => {
                    setAddVisible(true);
                }}>
                    Добавить шаблон
                </Button>
            </TopPlaceholder>

            <ItemsList>
                {templates.map((a, i) => {
                    let isSelected = (a.id == selectedId);
                    return (
                        <Item key={`${a.id}_${i}`} selected={isSelected} onClick={() => {
                            setSelectedId(a.id);
                        }}>
                            <Name>
                                {a.name}
                            </Name>
                            <Description>
                                {a.description}
                            </Description>
                        </Item>
                    )
                })}
            </ItemsList>

            <Sidebar width={Math.min(1400, window.innerWidth)} visible={addVisible}
                     onCloserClick={() => {
                         setAddVisible(false);
                     }}>

                {addVisible == false ? null :
                    <div>
                        <UpdateTemplateForm
                            loading={loading}
                            onSave={async d => {
                                setLoading(true);
                                let newId = uuid();
                                await KvdbAPI.saveData(`mail_template_${newId}`, {
                                    ...d,
                                    id: newId,
                                    timestamp: +new Date(),
                                    updatedTimestamp: +new Date()
                                });
                                let arr = await MailAPI.getTemplates();
                                setTemplates(arr);
                                setLoading(false);
                            }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar width={Math.min(1400, window.innerWidth)} visible={(selectedTemplate != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedTemplate == undefined ? null :
                    <div>
                        <UpdateTemplateForm {...selectedTemplate}
                                            loading={loading}
                                            onSave={async d => {
                                                setLoading(true);
                                                await KvdbAPI.saveData(`mail_template_${selectedTemplate.id}`, {
                                                    ...d,
                                                    id: selectedTemplate.id,
                                                    updatedTimestamp: +new Date()
                                                });
                                                let arr = await MailAPI.getTemplates();
                                                setTemplates(arr);
                                                setLoading(false);
                                                setSelectedId(undefined);
                                            }}/>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ItemsList = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;
`;

const Name = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`;

const Description = styled.div`
    
`;

const Item = styled.div`
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: pointer;
`;
