/**
 * Created by sabir on 19.07.17.
 */

//users
export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const SIGNUP = 'SIGNUP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAIL = 'SIGNUP_FAIL'

export const LOAD_USERS = 'LOAD_USERS'
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS'
export const LOAD_USERS_FAIL = 'LOAD_USERS_FAIL'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const INITIALIZE_AUTH = 'INITIALIZE_AUTH'
export const INITIALIZE_AUTH_SUCCESS = 'INITIALIZE_AUTH_SUCCESS'
export const INITIALIZE_AUTH_FAIL = 'INITIALIZE_AUTH_FAIL'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'


//phones

export const LOAD_APPS = 'LOAD_APPS'
export const LOAD_APPS_SUCCESS = 'LOAD_APPS_SUCCESS'
export const LOAD_APPS_FAIL = 'LOAD_APPS_FAIL'

export const CREATE_APP = 'CREATE_APP'
export const CREATE_APP_SUCCESS = 'CREATE_APP_SUCCESS'
export const CREATE_APP_FAIL = 'CREATE_APP_FAIL'

export const UPDATE_APP = 'UPDATE_APP'
export const UPDATE_APP_SUCCESS = 'UPDATE_APP_SUCCESS'
export const UPDATE_APP_FAIL = 'UPDATE_APP_FAIL'

export const DELETE_APP = 'DELETE_APP'
export const DELETE_APP_SUCCESS = 'DELETE_APP_SUCCESS'
export const DELETE_APP_FAIL = 'DELETE_APP_FAIL'

//orders

export const CREATE_ORDER = 'CREATE_ORDER'
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL'

export const UPDATE_ORDER = 'UPDATE_ORDER'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL'

export const DELETE_ORDER = 'DELETE_ORDER'
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS'
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL'

export const LOAD_ORDERS = 'LOAD_ORDERS'
export const LOAD_ORDERS_SUCCESS = 'LOAD_ORDERS_SUCCESS'
export const LOAD_ORDERS_FAIL = 'LOAD_ORDERS_FAIL'

//tariffs

export const CREATE_TARIFF = 'CREATE_TARIFF'
export const CREATE_TARIFF_SUCCESS = 'CREATE_TARIFF_SUCCESS'
export const CREATE_TARIFF_FAIL = 'CREATE_TARIFF_FAIL'

export const UPDATE_TARIFF = 'UPDATE_TARIFF'
export const UPDATE_TARIFF_SUCCESS = 'UPDATE_TARIFF_SUCCESS'
export const UPDATE_TARIFF_FAIL = 'UPDATE_TARIFF_FAIL'

export const DELETE_TARIFF = 'DELETE_TARIFF'
export const DELETE_TARIFF_SUCCESS = 'DELETE_TARIFF_SUCCESS'
export const DELETE_TARIFF_FAIL = 'DELETE_TARIFF_FAIL'

export const LOAD_TARIFFS = 'LOAD_TARIFFS'
export const LOAD_TARIFFS_SUCCESS = 'LOAD_TARIFFS_SUCCESS'
export const LOAD_TARIFFS_FAIL = 'LOAD_TARIFFS_FAIL'

//customers

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS'
export const CREATE_CUSTOMER_FAIL = 'CREATE_CUSTOMER_FAIL'

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS'
export const UPDATE_CUSTOMER_FAIL = 'UPDATE_CUSTOMER_FAIL'

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS'
export const DELETE_CUSTOMER_FAIL = 'DELETE_CUSTOMER_FAIL'

export const LOAD_CUSTOMERS = 'LOAD_CUSTOMERS'
export const LOAD_CUSTOMERS_SUCCESS = 'LOAD_CUSTOMERS_SUCCESS'
export const LOAD_CUSTOMERS_FAIL = 'LOAD_CUSTOMERS_FAIL'

// tickets
export const LOAD_TICKETS = 'LOAD_TICKETS'
export const LOAD_TICKETS_SUCCESS = 'LOAD_TICKETS_SUCCESS'
export const LOAD_TICKETS_FAIL = 'LOAD_TICKETS_FAIL'

export const CREATE_TICKET = 'CREATE_TICKET'
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS'
export const CREATE_TICKET_FAIL = 'CREATE_TICKET_FAIL'

export const UPDATE_TICKET = 'UPDATE_TICKET'
export const UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS'
export const UPDATE_TICKET_FAIL = 'UPDATE_TICKET_FAIL'

export const DELETE_TICKET = 'DELETE_TICKET'
export const DELETE_TICKET_SUCCESS = 'DELETE_TICKET_SUCCESS'
export const DELETE_TICKET_FAIL = 'DELETE_TICKET_FAIL'
