import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import HorChessTemplate from '../templates/HorChessTemplate'


export default function OrderApp(props) {
    let {id} = props.match.params;

    return (
        <HorChessTemplate active={`order/${id}`}>


        </HorChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
