import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CdekAPI from "../../../api/CdekAPI";

export default function CityInfoTool(props) {
    const {code, style = {}} = props;
    let [loading, setLoading] = useState(true);
    const [data, setData] = useState(undefined);
    useEffect(() => {
        CdekAPI.getCityByCode(code).then(d => {
            setLoading(false);
            setData(d);
            console.log('CityInfoTool: cb: d = ', d);
        })
    }, [code]);

    console.log('CityInfoTool: render: data = ', data);

    if (data == undefined) {
        return null;
    }

    return (
        <Wrapper style={style} >
            {`${data.cityName}, ${data.region} (код ${data.cityCode})`}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    font-size: 12px;
    opacity: 0.5;
`;
