import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {Set} from "immutable";

import useDebouncedCallback from 'use-debounce/lib/callback'

import * as customersActions from '../../../redux/actions/CustomersActions'
import * as usersActions from '../../../redux/actions/UsersActions'
import {Button} from "../../ui/Button";
import {DARK_SIDE} from "../../ui/Colors";

import UpdateCustomerForm from '../forms/UpdateCustomerForm'

import {Input} from "../../ui/Input";

import Tabs, {TabItem} from "../../ui/Tabs";
import CustomerTransactionsPanel from "../../transactions/panels/CustomerTransactionsPanel";
import {API_ENDPOINT} from "../../../constants/config";
import StateHelper from "../../../helpers/StateHelper";
import BankAccountInfoPanel from "./BankAccountInfoPanel";
import ClientCustomTariffsPanel from "../../custom_tariffs/panels/ClientCustomTariffsPanel";

export default function CustomersPanel(props) {
    const {filterPaykeeperId} = props;
    const {
        customers, loading, custSet, currentUser
    } = useMappedState(useCallback(state => {
        let custSet = StateHelper.getUserCustMap(state);
        let custs = StateHelper.getUserCustomers(state);
        console.log('custs = ', custs);
        return {
            loading: state.customers.loading,
            custSet: custSet,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            // customers: state.customers.customersMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)).filter(a => ((filterPaykeeperId == undefined || a.paykeeper_id == filterPaykeeperId)))
            customers: custs.sort((a, b) => (+b.timestamp - +a.timestamp)).filter(a => ((filterPaykeeperId == undefined || a.paykeeper_id == filterPaykeeperId)))
        }
    }, []));
    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState(undefined);
    const [addVisible, setAddVisible] = useState(false);
    const [searchString, setSearchString] = useState('');

    const [balancesMap, setBalancesMap] = useState({});

    const [debouncedSearch] = useDebouncedCallback((a) => {
        console.log('will set ', a);
        setSearchString(a);
    }, 300, []);

    useEffect(() => {
        dispatch(customersActions.loadAllCustomers());
        axios.get(`${API_ENDPOINT}/balances_map`).then(d => d.data).then(pld => {
            setBalancesMap(pld);
        })
    }, []);

    if (customers.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    const selectedCustomer = (selectedId == undefined) ? undefined : customers.filter(a => (a.id == selectedId))[0];

    const filteredCustomers = customers.filter(a => {
        let {secret, name, paykeeper_id, website, inn, legal_entity_name} = a;
        let s = `${secret} ${name} ${legal_entity_name} ${paykeeper_id} ${website} ${inn}`;
        if (searchString == undefined || searchString.trim() == '') {
            return true;
        }
        return (s.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
    });

    if (currentUser == undefined) {
        return null;
    }

    return (
        <Wrapper>

            {filterPaykeeperId != undefined ? null :
                <>
                <TopPlaceholder>
                    <Button onClick={() => {
                        setAddVisible(true);
                    }}>
                        Добавить клиента
                    </Button>
                </TopPlaceholder>
                <SearchPlaceholder>
                    <Input placeholder={'Поиск'} style={{
                        height: 36,
                        lineHeight: `36px`,
                        fontSize: 16
                    }} onChange={evt => {
                        debouncedSearch(evt.target.value);
                    }}/>
                </SearchPlaceholder>
                </>

            }

            <ListPlaceholder>
                {filteredCustomers.map((a, k) => {
                    let {name, paykeeper_id, secret, legal_entity_name} = a;
                    let balance = balancesMap[a.id];
                    let s = (legal_entity_name != undefined) ? legal_entity_name : name;
                    return (
                        <Item selected={(selectedId == a.id)} key={a.id} onClick={() => {
                            setSelectedId(a.id);
                        }}>
                            <Left>
                                <Name>
                                    <span>
                                        {s}
                                    </span>
                                    <span style={{
                                        marginLeft: 25,
                                        fontWeight: 'normal',
                                        backgroundColor: 'pink',
                                        borderRadius: 4,
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        fontSize: 12,
                                        fontStyle: 'italic'
                                    }}>
                                        {`${balance == undefined ? '...' : balance} руб.`}
                                    </span>
                                </Name>
                                <Description>
                                    {secret}
                                </Description>
                            </Left>
                            <Right>
                                <ExpirationDate>
                                    {paykeeper_id}
                                </ExpirationDate>
                            </Right>
                        </Item>
                    )
                })}
            </ListPlaceholder>

            <Sidebar width={Math.min(820, window.innerWidth)} visible={addVisible} onCloserClick={() => {
                setAddVisible(false);
            }}>

                {addVisible == false ? null :
                    <UpdateCustomerForm loading={loading}
                                        onSave={async d => {
                                            let pld = await dispatch(customersActions.createCustomer(d));
                                            let oldIds = (currentUser.paykeeperIds == undefined) ? [] : currentUser.paykeeperIds;
                                            let newIds = oldIds.filter(a => (a != pld.customer.paykeeper_id)).concat([pld.customer.paykeeper_id]);
                                            await dispatch(usersActions.updateUser({
                                                id: currentUser.id,
                                                paykeeperIds: newIds
                                            }))
                                            setAddVisible(false);
                                        }}/>
                }

            </Sidebar>

            <Sidebar width={Math.min(820, window.innerWidth)} visible={(selectedCustomer != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedCustomer == undefined ? null :
                    <div>
                        <Tabs tabs={[
                            {
                                label: 'Баланс и транзакции',
                                content: (
                                    <TabItem>
                                        <CustomerTransactionsPanel customerId={selectedCustomer.id}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Редактирование',
                                content: (
                                    <TabItem>
                                        <UpdateCustomerForm {...selectedCustomer} loading={loading}
                                                            onSave={async d => {
                                                                await dispatch(customersActions.updateCustomer({
                                                                    ...d,
                                                                    id: selectedCustomer.id
                                                                }));
                                                                setSelectedId(undefined);
                                                            }}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Реквизиты',
                                content: (
                                    <TabItem>
                                        <BankAccountInfoPanel paykeeperId={selectedCustomer.paykeeper_id}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Перс. тарифы',
                                isExtraLink: false,
                                content: (
                                    <TabItem>
                                        <ClientCustomTariffsPanel paykeeperId={selectedCustomer.paykeeper_id} />
                                    </TabItem>
                                )
                            },
                        ]}/>
                    </div>

                }

            </Sidebar>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ListPlaceholder = styled.div`
    
`;

const Item = styled.div`
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${props => (props.selected == true ? DARK_SIDE : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const Name = styled.div`
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const Description = styled.div`
    
`;

const Left = styled.div`
    
`;

const Right = styled.div`
    
`;

const ExpirationDate = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 20px;
`;

const SearchPlaceholder = styled.div`
    margin-bottom: 20px;
`;
