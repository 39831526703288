import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import * as actions from '../../../redux/actions/TicketsActions'
import UpdateTicketForm from "../forms/UpdateTicketForm";

export default function TicketPanel(props) {
    const {id} = props;
    const {
        ticket, loading
    } = useMappedState(useCallback(state => {
        return {
            loading: state.tickets.loading,
            ticket: state.tickets.ticketsMap.get(id)
        }
    }, [id]));
    const dispatch = useDispatch();
    useEffect(() => {
        // dispatch(actions.loadTicketById(id));
        dispatch(actions.loadAllTickets());
    }, [id]);

    if (ticket == undefined) {
        return null;
    }

    return (
        <Wrapper>
            <UpdateTicketForm {...ticket} loading={loading} onSave={async d => {
                await dispatch(actions.updateTicket({...d, id: id}));
            }}
                onDelete={async () => {
                    await dispatch(actions.deleteTicket(id));
                }}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
