import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import TransactionsAPI from "../../../api/TransactionsAPI";
import TransactionInput from "../tools/TransactionInput";
import {DARK_SIDE} from "../../ui/Colors";

import {TRANSACTIONS_TYPES_NAMES, TRANSACTIONS_TYPES_SIGNUM} from "../../../helpers/TransactionsHelper";

export default function CustomerTransactionsPanel(props) {
    const {customerId} = props;
    const [selectedId, setSelectedId] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState(undefined);
    const [transactions, setTransactions] = useState([]);

    const [trMode, setTrMode] = useState(undefined);

    const {
        customers, customersMap
    } = useMappedState(useCallback(state => {
        return {
            customers: state.customers.customersMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            customersMap: state.customers.customersMap
        }
    }, []));

    const load = useCallback(() => {
        setLoading(true);
        TransactionsAPI.getCustomerBalanceAndTransactions(customerId).then(pld => {
            console.log('getCustomerBalanceAndTransactions: pld = ', pld);
            setTransactions(pld.transactions)
            setBalance(pld.balance);
            setLoading(false);
        })
    }, [customerId]);

    useEffect(() => {
        load();
    }, [customerId]);

    const selectedTransaction = (selectedId == undefined) ? undefined : transactions.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            <TopBalancePlaceholder>
                <Spin visible={loading}/>
                {`Баланс: ${balance} руб.`}

                <TrModeSpan style={{color: 'green'}} onClick={() => {
                    setTrMode('replenish');
                }}>
                    пополнить
                </TrModeSpan>

                <TrModeSpan style={{color: 'red'}} onClick={() => {
                    setTrMode('withdraw');
                }}>
                    списать
                </TrModeSpan>

            </TopBalancePlaceholder>

            <TransactionsList>
                {transactions.map((tr, k) => {
                    let {customer_id, type, status, createdAt, amount} = tr;
                    let customer = customersMap.get(customer_id);
                    let isUp = (type == 'REPLENISHMENT');
                    return (
                        <Item up={isUp} selected={selectedId == tr.id} key={tr.id} onClick={() => {
                            setSelectedId(tr.id);
                        }}>
                            <ItLeft>
                                {`${TRANSACTIONS_TYPES_NAMES[type]}: ${TRANSACTIONS_TYPES_SIGNUM[type]}${amount}`}
                            </ItLeft>
                            <ItRight>
                                {`${moment(tr.createdAt).format('DD.MM.YYYY HH:mm:ss')}`}
                            </ItRight>
                        </Item>
                    )
                })}
            </TransactionsList>

            <Sidebar visible={(selectedTransaction != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>
                {selectedTransaction == undefined ? null :
                    <div>
                        <p>
                            {`ID=${selectedTransaction.id}`}
                        </p>
                        <p>
                            {`order_id=${selectedTransaction.order_id}`}
                        </p>
                    </div>
                }
            </Sidebar>

            <Sidebar visible={(trMode != undefined)} onCloserClick={() => {
                setTrMode(undefined);
            }}>
                {trMode == undefined ? null :
                    <div>

                        {trMode == 'replenish' ?
                            <div>
                                <h3>
                                    Пополнение
                                </h3>
                                <div>
                                    <TransactionInput onSave={async v => {
                                        await TransactionsAPI.replenish(customerId, +v);
                                        setTrMode(undefined);
                                        load();
                                    }}/>
                                </div>
                            </div>
                            :
                            <div>
                                <h3>
                                    Списание
                                </h3>
                                <TransactionInput onSave={async v => {
                                    await TransactionsAPI.withdraw(customerId, +v);
                                    load();
                                    setTrMode(undefined);
                                }}
                                                  buttonName={'Списать'}
                                />
                            </div>
                        }

                    </div>
                }
            </Sidebar>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const TopBalancePlaceholder = styled.div`
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;
`;

const TransactionsList = styled.div`
    
`;

const Item = styled.div`
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    background: ${props => (props.selected == true ? DARK_SIDE : (props.up == true ? 'lightgreen' : 'lightcoral'))};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: pointer;
    :hover{
      opacity: 1;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const ItLeft = styled.div`
    
`;

const ItRight = styled.div`
    
`;

const TrModeSpan = styled.span`
    cursor: pointer;
    margin-left: 15px;
    opacity: 0.5;
    text-decoration: underline;
    :hover{
      opacity: 1;
    }
`;
