import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import HorChessTemplate from '../templates/HorChessTemplate'

export default function StatsApp() {

    return (
        <HorChessTemplate active={`stats`}>

            Статистика. Раздел находится в разработке.

        </HorChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
