import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ui/Input";
import GoodsEditorTool from "../../cdek/tools/GoodsEditorTool";
import CitySearchableSelect from "../../cdek/tools/CitySearchableSelect";

import {Button} from "../../ui/Button";
import CdekAPI from "../../../api/CdekAPI";

export const DEFAULT_GOOD = {id: uuid(), weight: 0.5, length: 10, height: 10, width: 10};

export default function CdekCalculatorTool(props) {
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [goods, setGoods] = useState([DEFAULT_GOOD]);
    const [loading, setLoading] = useState(false);

    const [result, setResult] = useState(undefined);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Откуда
                </Label>
                <CitySearchableSelect code={from} onChange={newCode => {
                    setFrom(newCode);
                }}/>
            </Field>

            <Field>
                <Label>
                    Куда
                </Label>
                <CitySearchableSelect code={to} onChange={newCode => {
                    setTo(newCode);
                }}/>
            </Field>

            <GoodsPlaceholder>
                <GoodsEditorTool goods={goods} onChange={newGoods => {
                    console.log('newGoods = ', newGoods);
                    setGoods(newGoods);
                }}/>
            </GoodsPlaceholder>

            {result == undefined ? null :
                <ResultPlaceholder>
                    {`Стоимость: ${result.price} рублей`}
                </ResultPlaceholder>
            }

            {from == undefined || to == undefined || goods.length == 0 ? null :
                <BottomPlaceholder>
                    <Button onClick={async () => {
                        setLoading(true);
                        let pld = await CdekAPI.getCalcCdekPriceInfo({from, to, goods});
                        setResult(pld);
                        setLoading(false);
                    }}>
                        Рассчитать стоимость
                        <Spin visible={loading}/>
                    </Button>
                </BottomPlaceholder>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
    margin-bottom: 3px;
`;

const GoodsPlaceholder = styled.div`
    
`;

const BottomPlaceholder = styled.div`
    margin-top: 20px;
`;

const ResultPlaceholder = styled.div`
    background: lightgoldenrodyellow;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid whitesmoke;
    margin-top: 15px;
    margin-bottom: 15px;
`;
