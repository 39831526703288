import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";

import Select from 'react-select'

import {Button} from "../../ui/Button";

const STATUS_OPTIONS = [
    {
        label: 'Новая проблема',
        value: 'new'
    },
    {
        label: 'В процессе решения',
        value: 'in_progress'
    },
    {
        label: 'Решена',
        value: 'resolved'
    }
];

const TYPE_OPTIONS = [
    {
        label: 'Поврежденный груз',
        value: ' broken'
    },
    {
        label: 'Проблема с приездом курьера',
        value: 'courier'
    },
    {
        label: 'Не дошло вовремя',
        value: 'delivery_delay'
    },
    {
        label: 'Прочее',
        value: 'other'
    }
];

const PRIORITY_OPTIONS = [
    {
        value: 'high',
        label: 'Высокий'
    },
    {
        value: 'medium',
        label: 'Средний'
    },
    {
        value: 'low',
        label: 'Низкий'
    },
];


export default function UpdateTicketForm(props) {
    const {
        onSave = d => {

        },
        loading = false,
        onDelete = () => {

        }
    } = props;

    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);
    const [status, setStatus] = useState(props.status == undefined ? 'new' : props.status);
    const [type, setType] = useState(props.type == undefined ? 'other' : props.type);
    const [priority, setPriority] = useState(props.priority == undefined ? 'medium' : props.priority);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Краткое название проблемы
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Расширенное описание проблемы
                </Label>
                <Textarea value={description} onChange={evt => {
                    setDescription(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Статус
                </Label>
                <Select options={STATUS_OPTIONS} value={STATUS_OPTIONS.filter(a => (a.value == status))}
                        onChange={a => {
                            setStatus(a.value);
                        }}/>
            </Field>

            <Field>
                <Label>
                    Тип
                </Label>
                <Select options={TYPE_OPTIONS} value={TYPE_OPTIONS.filter(a => (a.value == type))} onChange={a => {
                    setType(a.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Приоритет
                </Label>
                <Select options={PRIORITY_OPTIONS} value={PRIORITY_OPTIONS.filter(a => (a.value == priority))}
                        onChange={a => {
                            setPriority(a.value);
                        }}/>
            </Field>

            <DrField>
                <Field>

                    <Spin visible={loading}/>
                    {loading == true ? null :
                        <Button onClick={() => {
                            onSave({
                                name: name,
                                description: description,
                                type: type,
                                status: status,
                                priority: priority
                            });
                        }}>
                            Сохранить
                        </Button>
                    }
                </Field>
                <Field>

                    <Spin visible={loading}/>
                    {loading == true ? null :
                        <span style={{color: 'red', opacity: 0.5, cursor: 'pointer'}} onClick={() => {
                            if (window.confirm('Вы уверены?') == false) {
                                return;
                            }
                            onDelete();
                        }}>
                            удалить тикет
                        </span>
                    }
                </Field>
            </DrField>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const DrField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;
