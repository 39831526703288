import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Button} from "../../ui/Button";
import {Textarea} from "../../ui/Textarea";
import {Input} from "../../ui/Input";

const ie = a => (a == undefined || a.trim() == '');

export default function UpdateBankInformationForm(props) {

    const {
        onSave = d => {

        },
        loading = false
    } = props;

    const [bankName, setBankName] = useState(props.bankName == undefined ? '' : props.bankName);
    const [bik, setBik] = useState(props.bik == undefined ? '' : props.bik);
    const [bankAccount, setBankAccount] = useState(props.bankAccount == undefined ? '' : props.bankAccount);

    const canSave = (!ie(bankName) && !ie(bik) && !ie(bankAccount));

    return (
        <Wrapper>

            <Field>
                <Label>
                    Наименование Банка
                </Label>
                <Input value={bankName} onChange={evt => {
                    setBankName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    БИК Банка
                </Label>
                <Input value={bik} onChange={evt => {
                    setBik(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Рассчетный счет (Р/С)
                </Label>
                <Input value={bankAccount} onChange={evt => {
                    setBankAccount(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Spin visible={loading}/>
                {loading == true ? null :
                    <Button disabled={!canSave} onClick={() => {
                        onSave({
                            bankName: bankName.trim(),
                            bankAccount: bankAccount.trim(),
                            bik: bik.trim(),
                        });
                    }}>
                        Сохранить
                    </Button>
                }
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
    margin-bottom: 3px;
    color: ${props => (props.color == undefined ? 'black' : props.color)};
`;

const SpanButton = styled.span`
    opacity: 0.5;
    cursor: pointer;
    font-size: 12px;
    font-style: italic;
    margin-left: 10px;
    :hover{
      opacity: 1;
      text-decoration: underline;
    }
`;
