import moment from 'moment-timezone'
import {DEFAULT_TIMEZONE} from "../constants/config";

import {Map, Set} from 'immutable'

const AnalysisHelper = {

    getDistributionData(arr, step = 10) {
        console.log('getDistributionData: arr = ', arr);
        if (arr == undefined || arr.length == 0) {
            return [];
        }
        let min = arr.reduce((m, a) => (a < m ? a : m), arr[0]);
        let max = arr.reduce((m, a) => (a > m ? a : m), arr[0]);
        console.log('min, max = ', min, max);
        let from = Math.floor(1.0 * min / step) * step;
        let to = Math.ceil(1.0 * max / step) * step;
        let n = (to - from) / step, result = [];
        console.log('n = ', n);
        for (let i = 0; i < n; i++) {
            let xFrom = i * step;
            let xTo = (+i + 1) * step;
            let k = arr.filter(a => (+a >= +xFrom && +a < +xTo)).length;
            result.push({
                x: xFrom,
                k: k
            });
        }
        return result;
    },

    getEnhOrders(orders = []){
        // console.log('getEnhOrders: orders = ', orders);
        let revOrders = orders.sort((a, b) => (+new Date(a.createdAt) - +new Date(b.createdAt)));
        let enhOrders = [], prevSum = 0;
        // console.log('revOrders = ', revOrders);
        for (let i in revOrders) {
            let ord = JSON.parse(JSON.stringify(revOrders[i]));
            let {price_for_customer, price_for_paykeeper, codData} = ord;
            let insurancePayment = (codData == undefined || codData.goods.length == 0) ? 0 : (codData.insuranceEnabled == true ? 0.0075 * codData.goods.reduce((sm, g) => (+sm + g.pricePerOne * g.quantity), 0) : 0);
            let codPayment = ((codData == undefined) ? 0 : 0.03 * codData.goods.reduce((sm, g) => (+sm + g.pricePerOne * g.quantity), 0));
            let revenue = Math.round(0.94 * (+price_for_customer) - +price_for_paykeeper - codPayment - insurancePayment);
            // let revenue = Math.round(0.94 * (+price_for_customer) - +price_for_paykeeper - insurancePayment);
            let sumRevenue = +prevSum + +revenue;
            ord.revenue = revenue;
            ord.sumRevenue = sumRevenue;
            enhOrders.push(ord);
            prevSum = sumRevenue;
        }
        enhOrders = enhOrders.sort((a, b) => (+new Date(b.createdAt) - +new Date(a.createdAt)));
        // console.log('enhOrders = ', enhOrders);
        return enhOrders;
    }

};

export default AnalysisHelper;
