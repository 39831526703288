import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import EventsAPI from "../../../api/EventsAPI";
import EventsTable from "../tools/EventsTable";
import ReactHelper from "../../../helpers/ReactHelper";
import StateHelper from "../../../helpers/StateHelper";

import * as customersActions from '../../../redux/actions/CustomersActions'

// import h from '../../../helpers/'

export default function AllEventsPanel(props) {
    const {userId} = props;

    const {
        customers, custSet, currentUser, getCustomer
    } = useMappedState(useCallback(state => {
        let pMap = state.customers.customersMap.toArray().reduce((mp, c) => mp.set(c.paykeeper_id, c), Map());
        return {
            loading: state.customers.loading,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            getCustomer: pkId => pMap.get(pkId)
        }
    }, []));
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const [eventsMap, setEventsMap] = useState(Map());

    useEffect(() => {
        setLoading(true);

        dispatch(customersActions.loadAllCustomers());

        EventsAPI.getEventsForLastTime(3 * 86400000).then(arr => {
            let mp = arr.reduce((st, ev) => st.set(`${ev.t}_${ev.userId}`, ev), Map());
            setEventsMap(mp);
            setLoading(false);
        });
    }, []);

    ReactHelper.useInterval(() => {
        setLoading(true);
        EventsAPI.getEventsForLastTime(20 * 1000).then(arr => {
            let mp = arr.reduce((st, ev) => st.set(`${ev.t}_${ev.userId}`, ev), eventsMap);
            setEventsMap(mp);
            setLoading(false);
        });
    }, 10 * 1000);

    let events = eventsMap.toArray().sort((a, b) => (+b.t - +a.t)).map(a => ({...a, user: getCustomer(a.userId)}));

    return (
        <Wrapper>
            <EventsListPlaceholder>

                <EventsTable events={events} showUserId={true}/>

            </EventsListPlaceholder>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const EventsListPlaceholder = styled.div`
    
`;

const EventItem = styled.div`
    
`;
