import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import KvdbAPI from "../../../api/KvdbAPI";
import UpdateBankInformationForm from "../forms/UpdateBankInformationForm";
import {API_ENDPOINT} from "../../../constants/config";

export default function BankAccountInfoPanel(props) {
    const {paykeeperId} = props;
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState(undefined);
    const [updT, setUpdT] = useState(0);

    useEffect(() => {
        KvdbAPI.getData(`bank_${paykeeperId}`).then(d => {
            let pld = (d == undefined) ? {} : d;
            setFormData(pld);
            setUpdT(+new Date());
            setLoading(false);
        });
    }, [paykeeperId]);

    return (
        <Wrapper>
            <div key={`test_${updT}`}>
                <UpdateBankInformationForm {...formData} loading={loading} onSave={async d => {
                    setFormData(d);
                    setLoading(true);
                    await KvdbAPI.saveData(`bank_${paykeeperId}`, d);
                    setLoading(false);
                }}/>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;
