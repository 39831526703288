import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import HorChessTemplate from '../templates/HorChessTemplate'
import PricingDiffDistributionChart from "../analysis/charts/PricingDiffDistributionChart";

import Tabs, {TabItem} from "../ui/Tabs";
import OrdersAPI from "../../api/OrdersAPI";
import AnalysisHelper from "../../helpers/AnalysisHelper";
import RevenueBarChart from "../stats/charts/RevenueBarChart";

const isCoolAdmin = () => {
    try {
        return (`${window.localStorage.getItem('is_cool_admin')}` == '1');
    } catch (e) {
        return false;
    }
}

export default function AnalyticsApp() {
    let isAdmin = isCoolAdmin();
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        OrdersAPI.getAllOrders().then(arr => {
            let sArr = arr.sort((a, b) => ((+new Date(b.createdAt)) - (+new Date(a.createdAt)))).map(a => ({
                ...a
            }));
            setOrders(sArr);
        });
    }, []);

    let enhOrders = AnalysisHelper.getEnhOrders(orders);

    let minT = enhOrders.reduce((min, ord) => (+new Date(ord.createdAt) < min ? +new Date(ord.createdAt) : +min), +new Date());
    let maxT = enhOrders.reduce((max, ord) => (+new Date(ord.createdAt) > +max ? +new Date(ord.createdAt) : +max), 0);
    let months = [];
    let t = +moment(minT).startOf('month');
    while (t < maxT) {
        let from = +moment(t).startOf('month');
        let to = +moment(t).endOf('month');
        let ords = enhOrders.filter(a => (+new Date(a.createdAt) > +from && +new Date(a.createdAt) < to));
        let rev = ords.reduce((sum, o) => (+sum + +o.revenue), 0);
        months.push({
            t: t,
            revenue: +rev
        });
        t = +moment(t).add(1, 'months');
    }
    months = months.sort((a, b) => (+b.t - +a.t));

    return (
        <HorChessTemplate active={`analytics`}>

            <Tabs tabs={[
                {
                    label: 'Old',
                    content: (
                        <TabItem>
                            {/*<PricingDiffDistributionChart/>*/}

                            {/*<PricingDiffDistributionChart to={'w'}/>*/}

                            {/*<PricingDiffDistributionChart weight={3}/>*/}

                            {/*<PricingDiffDistributionChart weight={3} to={'w'}/>*/}
                        </TabItem>
                    )
                },
                {
                    label: (isAdmin == true) ? 'Месячная статистика' : '',
                    content: (
                        <TabItem>
                            {/*{JSON.stringify(months)}*/}
                            <ol>
                                {months.map((a, j) => {
                                    return (
                                        <li key={a.t}>
                                            {`${moment(a.t).format('MMMM YYYY')} - ${a.revenue} RUB`}
                                        </li>
                                    )
                                })}
                            </ol>

                            <MonthChartContentPlaceholder>
                                <RevenueBarChart points={months.sort((a, b) => (+a.t - +b.t))}/>
                            </MonthChartContentPlaceholder>

                        </TabItem>
                    )
                }
            ]}/>

        </HorChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;

const MonthChartContentPlaceholder = styled.div`
    height: 60vh;
    width: 100%;
    box-sizing: border-box;
`;
