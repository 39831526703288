import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {Set} from "immutable";
import UpdateAppForm from "../forms/UpdateAppForm";

import * as appsActions from '../../../redux/actions/AppsActions'
import {Button} from "../../ui/Button";
import {DARK_SIDE} from "../../ui/Colors";

export default function AppsPanel(props) {
    const {
        apps, loading
    } = useMappedState(useCallback(state => {
        return {
            loading: state.apps.loading,
            apps: state.apps.appsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp))
        }
    }, []));
    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState(undefined);
    const [addVisible, setAddVisible] = useState(false);

    useEffect(() => {
        dispatch(appsActions.loadAllApps());
    }, []);

    if (apps.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    const selectedApp = (selectedId == undefined) ? undefined : apps.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            <TopPlaceholder>
                <Button onClick={() => {
                    setAddVisible(true);
                }}>
                    Добавить ключи
                </Button>
            </TopPlaceholder>

            <ListPlaceholder>
                {apps.map((a, k) => {
                    let {name, description, expirationTimestamp, apiKey, secretKey} = a;
                    return (
                        <Item selected={(selectedId == a.id)} key={a.id} onClick={() => {
                            setSelectedId(a.id);
                        }}>
                            <Left>
                                <Name>
                                    {name}
                                </Name>
                                <Description>
                                    {description}
                                </Description>
                            </Left>
                            <Right>
                                <ExpirationDate>
                                    {moment(expirationTimestamp).format('DD.MM.YYYY')}
                                </ExpirationDate>
                            </Right>
                        </Item>
                    )
                })}
            </ListPlaceholder>

            <Sidebar width={Math.min(820, window.innerWidth)} visible={addVisible} onCloserClick={() => {
                setAddVisible(false);
            }}>

                {addVisible == false ? null :
                    <UpdateAppForm loading={loading}
                                   onSave={async d => {
                                       await dispatch(appsActions.createApp(d));
                                       setAddVisible(false);
                                   }}/>
                }

            </Sidebar>

            <Sidebar width={Math.min(820, window.innerWidth)} visible={(selectedApp != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedApp == undefined ? null :
                    <UpdateAppForm {...selectedApp} loading={loading}
                                   onSave={async d => {
                                       await dispatch(appsActions.updateApp({...d, id: selectedApp.id}));
                                       setSelectedId(undefined);
                                   }}/>
                }

            </Sidebar>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ListPlaceholder = styled.div`
    
`;

const Item = styled.div`
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${props => (props.selected == true ? DARK_SIDE : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: pointer;
    :hover{
      opacity: 0.7;
    }
`;

const Name = styled.div`
    font-weight: bold;
`;

const Description = styled.div`
    
`;

const Left = styled.div`
    
`;

const Right = styled.div`
    
`;

const ExpirationDate = styled.div`
    
`;

const TopPlaceholder = styled.div`
    margin-bottom: 20px;
`;
