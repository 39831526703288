import OrdersAPI from '../../api/OrdersAPI'

import * as types from '../ActionTypes'

let loadOrders_ = () => {
    return {
        type: types.LOAD_ORDERS
    }
}
let loadOrdersSuccess = (orders) => {
    return {
        type: types.LOAD_ORDERS_SUCCESS,
        orders: orders
    }
}
let loadOrdersFailed = (error) => {
    return {
        type: types.LOAD_ORDERS_FAIL,
        error: error
    }
}

//thunk
export function loadAllOrders() {
    return (dispatch, getState) => {
        dispatch(loadOrders_());
        return OrdersAPI.getAllOrders().then(
            orders => dispatch(loadOrdersSuccess(orders)),
            error => dispatch(loadOrdersFailed(error))
        )
    }
}

export function loadFreshOrders() {
    return (dispatch, getState) => {
        dispatch(loadOrders_());
        let allSortedOrders = getState().orders.ordersMap.toArray().sort((a, b) => {
            let t1 = +new Date(a.updatedAt);
            let t2 = +new Date(b.updatedAt);
            return (t2 - t1);
        });
        console.log('allSortedOrders = ', allSortedOrders);
        let from = (allSortedOrders.length == 0) ? 0: (+new Date(allSortedOrders[0].updatedAt) - 1000);
        return OrdersAPI.getAllOrdersFresherThen(+from).then(
            orders => dispatch(loadOrdersSuccess(orders)),
            error => dispatch(loadOrdersFailed(error))
        )
    }
}

export function loadYoungOrders() {
    return (dispatch, getState) => {
        dispatch(loadOrders_());
        let allSortedOrders = getState().orders.ordersMap.toArray().sort((a, b) => {
            let t1 = +new Date(a.createdAt);
            let t2 = +new Date(b.createdAt);
            return (t2 - t1);
        });
        console.log('allSortedOrders = ', allSortedOrders);
        let from = (allSortedOrders.length == 0) ? 0 : (+new Date(allSortedOrders[0].createdAt) - 1000);
        return OrdersAPI.getAllOrdersYoungerThen(+from).then(
            orders => dispatch(loadOrdersSuccess(orders)),
            error => dispatch(loadOrdersFailed(error))
        )
    }
}

export function loadOrderById(id) {
    return (dispatch, getState) => {
        dispatch(loadOrders_());
        return OrdersAPI.getOrder(id).then(
            order => dispatch(loadOrdersSuccess([order])),
            error => dispatch(loadOrdersFailed(error))
        )
    }
}

let createOrder_ = () => {
    return {
        type: types.CREATE_ORDER
    }
}
let createOrderSuccess = (order) => {
    return {
        type: types.CREATE_ORDER_SUCCESS,
        order: order
    }
}
let createOrderFailed = (error) => {
    return {
        type: types.CREATE_ORDER_FAIL,
        error: error
    }
}

//thunk
export function createOrder(data) {
    return (dispatch, getState) => {
        dispatch(createOrder_());
        return OrdersAPI.createOrder(data).then(
            order => dispatch(createOrderSuccess(order)),
            error => dispatch(createOrderFailed(error))
        )
    }
}

let updateOrder_ = () => {
    return {
        type: types.UPDATE_ORDER
    }
}
let updateOrderSuccess = (order) => {
    return {
        type: types.UPDATE_ORDER_SUCCESS,
        order: order
    }
}
let updateOrderFailed = (error) => {
    return {
        type: types.UPDATE_ORDER_FAIL,
        error: error
    }
}

//thunk
export function updateOrder(data) {
    return (dispatch, getState) => {
        dispatch(updateOrder_());
        return OrdersAPI.updateOrder(data).then(
            order => dispatch(updateOrderSuccess(order)),
            error => dispatch(updateOrderFailed(error))
        )
    }
}

let deleteOrder_ = () => {
    return {
        type: types.DELETE_ORDER
    }
}
let deleteOrderSuccess = (id) => {
    return {
        type: types.DELETE_ORDER_SUCCESS,
        id: id
    }
}
let deleteOrderFailed = (error) => {
    return {
        type: types.DELETE_ORDER_FAIL,
        error: error
    }
}

//thunk
export function deleteOrder(id) {
    return (dispatch, getState) => {
        dispatch(deleteOrder_());
        return OrdersAPI.deleteOrder(id).then(
            () => dispatch(deleteOrderSuccess(id)),
            error => dispatch(deleteOrderFailed(error))
        )
    }
}
