import moment from 'moment-timezone'
import {DEFAULT_TIMEZONE} from "../constants/config";

import {Map, Set} from 'immutable'

const StateHelper = {

    getPhoneTunnel(state, phoneId) {
        let tunnels = state.tunnels.tunnelsMap.toArray().filter(a => (a.phoneId == phoneId));
        if (tunnels.length == 0) {
            return undefined;
        }
        return tunnels[0];
    },

    getSortedPhonesByStatus(state, phones) {
        let {onlineTunnelsMap} = state.realtime;
        let onlineSet = onlineTunnelsMap.toArray().reduce((st, a) => (st.add(a.phoneId)), Set());
        return phones.sort((a, b) => {
            let k1 = (onlineSet.has(a.phoneId) == true) ? 2566592646768 : 0;
            let k2 = (onlineSet.has(b.phoneId) == true) ? 2566592646768 : 0;
            let n1 = +a.timestamp - k1;
            let n2 = +b.timestamp - k2;
            return (n2 - n1);
        })
    },

    getUserCustomers(state, userId) {
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        let user = state.users.usersMap.get(uId);
        let isAdmin = (user != undefined && user.userRole == 'admin');
        console.log('getUserCustomers: isAdmin = ', isAdmin);
        let ids = (user == undefined) ? [] : (user.paykeeperIds == undefined ? [] : user.paykeeperIds);
        let pMap = state.customers.customersMap.toArray().reduce((mp, c) => mp.set(c.paykeeper_id, c), Map());
        let customers = [];
        if (isAdmin == true) {
            customers = state.customers.customersMap.toArray();
        } else {
            customers = ids.map(a => pMap.get(a)).filter(a => (a != undefined))
        }
        return customers;
    },

    getUserCustMap(state, userId){
        let customers = this.getUserCustomers(state, userId);
        let custMap = customers.reduce((mp, c) => mp.set(c.paykeeper_id, c), Map());
        return custMap;
    },

    getUserCustSet(state, userId){
        let customers = this.getUserCustomers(state, userId);
        let custSet = customers.reduce((mp, c) => mp.add(c.paykeeper_id), Set());
        return custSet;
    },

    getUserOrders(state, userId){
        let allOrders = state.orders.ordersMap.toArray();
        let uId = (userId == undefined) ? state.users.currentUserId : userId;
        let user = state.users.usersMap.get(uId);
        let isAdmin = (user != undefined && user.userRole == 'admin');
        if (isAdmin == true){
            return allOrders;
        }
        let custSet = this.getUserCustSet(state, userId);
        let ords = allOrders.filter(a => (custSet.has(a.paykeeper_id)));
        return ords;
    }

};

export default StateHelper;
