import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input, Button, LinedText} from '../ui'

import Spin from 'arui-feather/spin'

import './style.css'

import Select from 'react-select'

const ROLES_ITEMS = [
    {
        label: 'Provider',
        value: 'provider'
    },
    {
        label: 'Buyer',
        value: 'buyer'
    }

];

export default function StyledSignupForm(props) {
    const {
        onSubmit = () => {

        },
        onLoginClick = () => {
        },
        onLostPasswordClick = () => {
        }, loading = false
    } = props;
    const [userRole, setUserRole] = useState('provider');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [human, setHuman] = useState(false);

    let canSubmit = (human != false);

    return (
        <Wrapper>

            <TopPlaceholder>

                <LogoPlaceholder>
                    <Logo src={require('../../../assets/images/logo_wide_transparent.png')}/>
                </LogoPlaceholder>

                <LinedText text={'REGISTER'}/>
            </TopPlaceholder>

            <ContentPlaceholder>

                <Field>
                    <Left>
                        <Input value={firstName} placeholder={'First Name'} onChange={e => {
                            setFirstName(e.target.value);
                        }}/>
                    </Left>
                    <Right>
                        <Input value={lastName} placeholder={'Last Name'} onChange={e => {
                            setLastName(e.target.value);
                        }}/>
                    </Right>
                </Field>

                <Field>
                    <Left>
                        <Input value={email} type={'email'} placeholder={'Your E-Mail'} onChange={e => {
                            setEmail(e.target.value);
                        }}/>
                    </Left>
                    <Right>
                        <Select options={ROLES_ITEMS}
                                placeholder={'Роль'}
                                value={ROLES_ITEMS.filter(a => (a.value == userRole))[0]}
                                onChange={a => {
                                    setUserRole(a.value);
                                }}
                        />
                    </Right>
                </Field>


                <Field>
                    <Left>
                        <Input value={password} type={'password'} placeholder={'Password'} onChange={e => {
                            setPassword(e.target.value.trim());
                        }}/>
                    </Left>
                    <Right>
                        <Input value={passwordConfirmation} type={'password'} placeholder={'Confirm password'}
                               onChange={e => {
                                   setPasswordConfirmation(e.target.value);
                               }}/>
                    </Right>
                </Field>

                <Field style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    height: 50
                }}>
                    <span style={{marginRight: 5}}>
                        <SquareCheckImage
                            onClick={() => {
                                setHuman(!human);
                            }}
                            src={(human == true) ? require('../../../assets/images/check.svg') : require('../../../assets/images/check-box-empty.svg')}/>
                    </span>
                    <span>
                        Я даю согласие на обработку своих персональных данных
                    </span>
                </Field>

                <Field>
                    <Button disabled={!canSubmit} onClick={() => {
                        const ie = a => (a == undefined || a.trim() == '');
                        if (password != passwordConfirmation || human == false || (ie(firstName) == true) || ie(lastName) == true || password.length < 4) {
                            return;
                        }
                        onSubmit(
                            {
                                email: email.trim().toLowerCase(),
                                password: password.trim(),
                                // userRole: 'user',
                                userRole: userRole,
                                firstName: firstName.trim(),
                                lastName: lastName.trim()
                            })
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            'Sign up'
                        }
                    </Button>
                </Field>

                <VeryBottomPlaceholder>
                    <PersonalDataPlaceholder>
                        <div style={{opacity: 0.5}}>
                            Нажимая кнопку «Зарегистрироваться», вы принимаете
                        </div>
                        <div style={{fontWeight: 'bold'}}>
                            <BrLink href={'https://my.hawk.ru/agreement/'} target={'_blank'}>
                                условия обработки персональных данныx
                            </BrLink>
                        </div>
                    </PersonalDataPlaceholder>
                </VeryBottomPlaceholder>

            </ContentPlaceholder>

            <BottomPlaceholder>
                <Link onClick={() => {
                    onLostPasswordClick();
                }}>
                    Forgot password?
                </Link>
                <Link onClick={() => {
                    onLoginClick();
                }}>
                    Login
                </Link>
            </BottomPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    //width: 100%;
    //height: 100%;
    padding: 40px;
    box-sizing: border-box;
    width: 620px;
    @media(max-width: 720px){
      width: 100vw;
    }
`;

const TopPlaceholder = styled.div`
    width: 100%;
    margin-bottom: 20px;
`;

const ContentPlaceholder = styled.div`
    
`;

const BottomPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;

const Field = styled.div`
    margin-bottom: 10px;
    width: 100%;
`;

const Link = styled.div`
    color: #999999;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    :hover{
      opacity: 0.8;
    }
`;


const LogoPlaceholder = styled.div`
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0px;
`;

const Logo = styled.img`
    display: inline-block;
    height: 80px;
`;

const Left = styled.div`
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    padding-right: 5px;
    width: 50%;
`;

const Right = styled.div`
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    padding-left: 5px;
    width: 50%;
`;

const VeryBottomPlaceholder = styled.div`
    
`;

const PersonalDataPlaceholder = styled.div`
    text-align: center;
    font-size: 12px;  
    color: #999999;
`;

const SquareCheckImage = styled.img`
    height: 18px;
    width: 18px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const BrLink = styled.a`
    text-decoration: none;
    cursor: pointer;
    color: black;
`;
