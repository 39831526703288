import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import OrdersAPI from "../../../api/OrdersAPI";

export default function OrderCouriersPanel(props) {
    const {id} = props;
    const [couriers, setCouriers] = useState([]);

    useEffect(() => {
        OrdersAPI.getOrderCouriers(id).then(arr => {
            setCouriers(arr);
        });
    }, [id]);

    return (
        <Wrapper>
            {couriers.map((c, k) => {
                let {hasProblem} = c;
                return (
                    <CourierItem key={`${k}_${c.objectId}`}>
                        <MomSpan>
                            {moment(c.createdAt).format('DD.MM.YYYY HH:mm:ss')}
                        </MomSpan>
                        <Status>
                            {c.status}
                            {c.callNumber == undefined ? null :
                                <span>
                                    {` #${c.callNumber}`}
                                </span>
                            }
                        </Status>

                        {hasProblem == true ?
                            <Problem>
                                ПРОБЛЕМА
                            </Problem> : null
                        }
                        <ControlsItem>
                            {c.status == 'new' ? null :
                                <button style={{marginRight: 15}} onClick={() => {

                                }}>
                                    Отозвать курьера
                                </button>
                            }
                            {(c.status == 'canceled' || c.status == 'rejected') ? null :
                                <button style={{marginRight: 20}} onClick={async () => {
                                    if (window.confirm('Уверены?') == false) {
                                        return;
                                    }
                                    let pld = await OrdersAPI.processOrderInvitation(c.objectId);
                                    window.alert(JSON.stringify(pld));
                                    console.log('pld = ', pld);
                                    let arr = await OrdersAPI.getOrderCouriers(id);
                                    setCouriers(arr);
                                }}>
                                    вызвать курьера
                                </button>
                            }
                            <button onClick={() => {
                                if (window.confirm('Уверены?') == false) {
                                    return;
                                }
                                OrdersAPI.deleteInvitation(c.objectId).then(arr => {
                                    OrdersAPI.getOrderCouriers(id).then(arr => {
                                        setCouriers(arr);
                                    });
                                });
                            }}>
                                Удалить заявку
                            </button>

                        </ControlsItem>
                    </CourierItem>
                )
            })}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const CourierItem = styled.div`
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const MomSpan = styled.div`
    
`;

const Status = styled.div`
    
`;

const Problem = styled.div`
    color: red;
`;

const ControlsItem = styled.div`
    
`;

const CallNumber = styled.div`
    
`;
