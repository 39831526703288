import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import * as ordersActions from '../../../redux/actions/OrdersActions'
import * as customersActions from '../../../redux/actions/CustomersActions'
import TransactionsAPI from "../../../api/TransactionsAPI";
import StateHelper from "../../../helpers/StateHelper";

export default function KpiPanel(props) {
    const {
        customers, loading, orders, custSet
    } = useMappedState(useCallback(state => {
        let allCustomers = StateHelper.getUserCustomers(state).sort((a, b) => (+b.timestamp - +a.timestamp));
        let custSet = allCustomers.reduce((mp, c) => mp.set(c.paykeeper_id, c), Map());
        let ords = state.orders.ordersMap.toArray().filter(a => (custSet.has(a.paykeeper_id)));
        return {
            loading: state.customers.loading,
            customers: allCustomers,
            orders: ords,
            custSet: custSet
        }
    }, []));
    const [replenishmentTransactions, setReplenishmentTransactions] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(ordersActions.loadAllOrders());
        dispatch(ordersActions.loadYoungOrders()).then(() => {
            dispatch(ordersActions.loadFreshOrders());
        });

        dispatch(customersActions.loadAllCustomers());
        TransactionsAPI.getAllReplenishmentTransactions().then(arr => {
            setReplenishmentTransactions(arr);
        });
    }, []);


    // console.log('KpiPanel: render: orders = ', orders);

    let from = +moment().startOf('day') - 30 * 86400000;
    let orders30 = orders.filter(a => (+new Date(a.createdAt) > from))

    return (
        <Wrapper>

            <Row>
                <Pan>
                    <PanHeading>
                        Заказов за последние 30 дней
                    </PanHeading>
                    <PanContent>
                        {orders30.length}
                    </PanContent>
                </Pan>
                <Pan>
                    <PanHeading>
                        Клиентов с подключенной доставкой
                    </PanHeading>
                    <PanContent>
                        {customers.length}
                    </PanContent>
                </Pan>
                <Pan>
                    <PanHeading>
                        Сделали хотя бы 1 заказ
                    </PanHeading>
                    <PanContent>
                        {customers.filter(a => {
                            let ords = orders.filter(b => (b.paykeeper_id == a.paykeeper_id));
                            return (ords.length > 0);
                        }).length}
                    </PanContent>
                </Pan>
                <Pan>
                    <PanHeading>
                        Пополнили баланс хотя бы 1 раз
                    </PanHeading>
                    <PanContent>
                        {customers.filter(a => {
                            let trs = replenishmentTransactions.filter(b => (b.customer_id == a.id));
                            return (trs.length > 1);
                        }).length}
                    </PanContent>
                </Pan>
            </Row>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Pan = styled.div`
  background: white;
  padding: 20px;
  border-radius: 4px;
  margin: 10px;
  flex: 1;
`;

const PanHeading = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const PanContent = styled.div`
  font-weight: bold;
  font-size: 24px;
`;
