import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    customersMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const CustomersReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_CUSTOMER:
        case types.UPDATE_CUSTOMER:
        case types.DELETE_CUSTOMER:
        case types.LOAD_CUSTOMERS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_CUSTOMER_FAIL:
        case types.UPDATE_CUSTOMER_FAIL:
        case types.DELETE_CUSTOMER_FAIL:
        case types.LOAD_CUSTOMERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_CUSTOMER_SUCCESS:
        case types.UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                customersMap: state.customersMap.set(action.customer.id, action.customer)
            }


        case types.DELETE_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                customersMap: state.customersMap.delete(action.id),
            }


        case types.LOAD_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                customersMap: state.customersMap
                    .merge(action.customers.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default CustomersReducer;
