import axios from 'axios'
import {AUX_API_ENDPOINT} from "../constants/config";

const WidgetAPI = {

    async getAllReleases() {
        let pld = (await axios.get(`${AUX_API_ENDPOINT}/widget/releases`)).data.result;
        return pld;
    },

    async createRelease(data) {
        let pld = (await axios.post(`${AUX_API_ENDPOINT}/widget/releases`, data)).data.result;
        return pld;
    },

    async updateRelease(data) {
        let pld = (await axios.put(`${AUX_API_ENDPOINT}/widget/releases`, data)).data.result;
        return pld;
    },


}

export default WidgetAPI;
