export const DEFAULT_TIMEZONE = 'Europe/Moscow';

export const FACELESS_AVATAR = 'https://www.englishpatient.org/storage/e7d099fe-653b-488e-9970-f693ab585f05.png';

export const AUTH_API_ENDPOINT = 'https://delivery.paykeeper.ru/auth';
export const API_ENDPOINT = process.env.REACT_APP_API_URL;
export const ANALYTICS_API_ENDPOINT = process.env.REACT_APP_ANALYTICS_API_URL || `https://delivery.paykeeper.ru/analytics`;
export const KVDB_API_ENDPOINT = process.env.REACT_APP_KVDB_API_URL || `https://delivery.paykeeper.ru/kvdb`;
export const AUX_API_ENDPOINT = process.env.REACT_APP_AUX_API_URL || `https://delivery.paykeeper.ru/aux`;
export const NCHAN_SUB_ENDPOINT = process.env.NCHAN_SUB_ENDPOINT;
// export const NCHAN_SUB_ENDPOINT = 'https://paykeeper.pro/sub';

export const LOGIN_BACKGROUNR_IMAGE = 'https://www.englishpatient.org/english-patient-files/36208fa0-49a1-406a-8ac7-ecfaeb810a55.jpg';

export const UPLOAD_DO_ENDPOINT_URL = 'https://www.englishpatient.org/api/upload';

export const SUPPORTED_WALLETS = ['btc', 'eth'];

export const GROUPING_MINUTES = [
    {
        label: '1 minute',
        value: 1
    },
    {
        label: '10 minutes',
        value: 10
    },
    {
        label: '30 minutes',
        value: 30
    },
    {
        label: '1 hour',
        value: 60
    },
];
