import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import HorChessTemplate from '../templates/HorChessTemplate'
import MailTemplatesPanel from "../mail/panels/MailTemplatesPanel";

import Tabs, {TabItem} from "../ui/Tabs";
import SendTemplatedMailTool from "../mail/tools/SendTemplatedMailTool";

import ls from 'local-storage'

export default function MailApp() {
    let isCool = (ls('is_cool_admin') == '1' || ls('is_cool_admin') == 1);

    return (
        <HorChessTemplate active={`mail`}>


            {isCool == false ? <SendTemplatedMailTool/> :
                <Tabs tabs={[
                    {
                        label: 'Шаблоны',
                        content: (
                            <TabItem>
                                <MailTemplatesPanel/>
                            </TabItem>
                        )
                    },
                    {
                        label: 'Отправка писем',
                        content: (
                            <TabItem>
                                <SendTemplatedMailTool/>
                            </TabItem>
                        )
                    }
                ]}/>
            }


        </HorChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
