import TariffsAPI from '../../api/TariffsAPI'

import * as types from '../ActionTypes'

let loadTariffs_ = () => {
    return {
        type: types.LOAD_TARIFFS
    }
}
let loadTariffsSuccess = (tariffs) => {
    return {
        type: types.LOAD_TARIFFS_SUCCESS,
        tariffs: tariffs
    }
}
let loadTariffsFailed = (error) => {
    return {
        type: types.LOAD_TARIFFS_FAIL,
        error: error
    }
}

//thunk
export function loadAllTariffs() {
    return (dispatch, getState) => {
        dispatch(loadTariffs_());
        return TariffsAPI.getAllTariffsUsingCache().then(
            tariffs => dispatch(loadTariffsSuccess(tariffs)),
            error => dispatch(loadTariffsFailed(error))
        )
    }
}

export function loadTariffById(id) {
    return (dispatch, getState) => {
        dispatch(loadTariffs_());
        return TariffsAPI.getTariff(id).then(
            tariff => dispatch(loadTariffsSuccess([tariff])),
            error => dispatch(loadTariffsFailed(error))
        )
    }
}

let createTariff_ = () => {
    return {
        type: types.CREATE_TARIFF
    }
}
let createTariffSuccess = (tariff) => {
    return {
        type: types.CREATE_TARIFF_SUCCESS,
        tariff: tariff
    }
}
let createTariffFailed = (error) => {
    return {
        type: types.CREATE_TARIFF_FAIL,
        error: error
    }
}

//thunk
export function createTariff(data) {
    return (dispatch, getState) => {
        dispatch(createTariff_());
        return TariffsAPI.createTariff(data).then(
            tariff => dispatch(createTariffSuccess(tariff)),
            error => dispatch(createTariffFailed(error))
        )
    }
}

let updateTariff_ = () => {
    return {
        type: types.UPDATE_TARIFF
    }
}
let updateTariffSuccess = (tariff) => {
    return {
        type: types.UPDATE_TARIFF_SUCCESS,
        tariff: tariff
    }
}
let updateTariffFailed = (error) => {
    return {
        type: types.UPDATE_TARIFF_FAIL,
        error: error
    }
}

//thunk
export function updateTariff(data) {
    return (dispatch, getState) => {
        dispatch(updateTariff_());
        return TariffsAPI.updateTariff(data).then(
            tariff => dispatch(updateTariffSuccess(tariff)),
            error => dispatch(updateTariffFailed(error))
        )
    }
}

let deleteTariff_ = () => {
    return {
        type: types.DELETE_TARIFF
    }
}
let deleteTariffSuccess = (id) => {
    return {
        type: types.DELETE_TARIFF_SUCCESS,
        id: id
    }
}
let deleteTariffFailed = (error) => {
    return {
        type: types.DELETE_TARIFF_FAIL,
        error: error
    }
}

//thunk
export function deleteTariff(id) {
    return (dispatch, getState) => {
        dispatch(deleteTariff_());
        return TariffsAPI.deleteTariff(id).then(
            () => dispatch(deleteTariffSuccess(id)),
            error => dispatch(deleteTariffFailed(error))
        )
    }
}
