import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import HorChessTemplate from '../templates/HorChessTemplate'
import TariffsPanel from "../tariffs/panels/TariffsPanel";

export default function TariffsApp() {

    return (
        <HorChessTemplate active={`tariffs`}>

            <TariffsPanel/>

        </HorChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
