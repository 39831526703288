import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import HorChessTemplate from '../templates/HorChessTemplate'
import AllOrdersPanel from "../orders/panels/AllOrdersPanel";
import KpiPanel from "../stats/panels/KpiPanel";


export default function IndexApp() {

    return (
        <HorChessTemplate active={'index'}>

            <KpiPanel/>

            <AllOrdersPanel/>

        </HorChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
