import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import HorChessTemplate from '../templates/HorChessTemplate'
import BankAccountInfoPanel from "../customers/panels/BankAccountInfoPanel";
import CustomersBankInfoPanel from "../customers/panels/CustomersBankInfoPanel";

export default function BankApp() {

    return (
        <CustomersBankInfoPanel/>
    );
}

const Wrapper = styled.div`
    
`;

const WhiteWrapper = styled.div`
    background-color: white;
    padding: 10px;
    border-radius: 4px;
`;
