import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    tariffsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const TariffsReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_TARIFF:
        case types.UPDATE_TARIFF:
        case types.DELETE_TARIFF:
        case types.LOAD_TARIFFS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_TARIFF_FAIL:
        case types.UPDATE_TARIFF_FAIL:
        case types.DELETE_TARIFF_FAIL:
        case types.LOAD_TARIFFS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_TARIFF_SUCCESS:
        case types.UPDATE_TARIFF_SUCCESS:
            return {
                ...state,
                loading: false,
                tariffsMap: state.tariffsMap.set(action.tariff.id, action.tariff)
            }


        case types.DELETE_TARIFF_SUCCESS:
            return {
                ...state,
                loading: false,
                tariffsMap: state.tariffsMap.delete(action.id),
            }


        case types.LOAD_TARIFFS_SUCCESS:
            return {
                ...state,
                loading: false,
                tariffsMap: state.tariffsMap
                    .merge(action.tariffs.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default TariffsReducer;
