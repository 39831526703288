import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import KeyValueAPI from "../../api/KeyValueAPI";

import {Input} from "../ui/Input";
import {Button} from "../ui/Button";

export default function UpdateKeyValuePanel(props) {
    const {pKey} = props;
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState('');

    useEffect(() => {
        KeyValueAPI.getParam(pKey).then(val => {
            setValue(val);
            setLoading(false);
        })
    }, []);

    return (
        <Wrapper>
            <Left>
                <Input value={value}
                       style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                       onChange={evt => {
                           setValue(evt.target.value);
                       }}/>
            </Left>
            <Right>
                <Spin loading={loading}/>
                {loading == true ? null :
                    <Button
                        style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, padding: 4, width: '100%', textAlign: 'center'}}
                        onClick={async () => {
                            setLoading(true);
                            await KeyValueAPI.saveParam(pKey, value);
                            setLoading(false);
                        }}>
                        Сохранить
                    </Button>
                }
            </Right>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const Left = styled.div`
    flex: 1;
`;

const Right = styled.div`
    width: 100px;
`;
