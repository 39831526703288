import moment from 'moment-timezone'
import {DEFAULT_TIMEZONE} from "../constants/config";

export const RU_MONTHS_ARRAY = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
];

const CommonHelper = {

    linkTo(patientUrl) {
        let url = window.location.origin + window.location.pathname + '#' + patientUrl;
        window.location.href = url;
    },

    getRuMonthByTimestamp(t) {
        let m = +moment(t).tz(DEFAULT_TIMEZONE).format('M') - 1;
        return RU_MONTHS_ARRAY[+m];
    },

    getEnMonthByTimestamp(t) {
        return moment(t).tz(DEFAULT_TIMEZONE).format('MMMM');
    },

    getRuNameOfRole(role) {
        let map = {
            franchisee: 'Франчайзи',
            admin: 'Админ',
            teacher: 'Тренер',
            student: 'Ученик',
        }
        return map[role];
    },

    getUserName(user) {
        if (user == undefined) {
            return 'Guest';
        }
        let {firstName, lastName, name} = user;
        if (name != undefined && name.trim() != '') {
            return name;
        }
        return `${firstName} ${lastName}`;
    },

    getYandexMapImageUrl(lat, lon, width = 450, height = 450, z = 10) {
        return `https://static-maps.yandex.ru/1.x/?lang=en_US&ll=${lon},${lat}&size=${width},${height}&z=${z}&l=map&pt=${lon},${lat},pm2rdl`;
    },

    roundUp(num, precision) {
        precision = Math.pow(10, precision)
        return Math.ceil(num * precision) / precision
    },

    convertTrafficPoints(points, divider = 1000000.0) {
        let cv = a => +(1.0 * (+a / divider)).toFixed(3);
        return points.map(a => ({...a, in: cv(+a.in), out: cv(+a.out), v: cv(+a.v)}));
    },

    convertTrafficStatsToMbStats(a, fix = 1) {
        const divider = 1000000.0;
        let cv = a => +(1.0 * (+a / divider)).toFixed(fix);
        return {
            in: cv(+a.in), out: cv(+a.out), v: cv(+a.v)
        }
    },

    getStatsOrdersPoints(orders, mode = 'day') {
        if (orders.length == 0) {
            return [];
        }
        let min = orders.reduce((m, o) => (+new Date(o.createdAt) < m ? +new Date(o.createdAt) : m), +new Date(orders[0].createdAt));
        let max = orders.reduce((m, o) => (+new Date(o.createdAt) > m ? +new Date(o.createdAt) : m), +new Date(orders[0].createdAt));

        min = +moment(min).tz(DEFAULT_TIMEZONE).startOf('day');
        max = +moment(max).tz(DEFAULT_TIMEZONE).endOf('day');
        let t = +min;
        let arr = [];
        while (t <= max) {
            let from = t, to = +t + 86400000;

            if (mode == 'week') {
                from = +moment(+from).startOf('isoweek');
                to = +moment(+from).endOf('isoweek').endOf('day');
            }
            if (mode == 'month') {
                from = +moment(+from).startOf('month');
                to = +moment(+from).endOf('month').endOf('day');
            }
            let ords = orders.filter(a => (+from < +new Date(a.createdAt) && +new Date(a.createdAt) < +to));
            arr.push({
                t: t,
                number: ords.length
            });
            if (mode == 'week'){
                t = +moment(t).add(1, 'weeks');
            }else{
                if (mode == 'month'){
                    t = +moment(t).add(1, 'months');
                }else{
                    t = +t + 86400000;
                }
            }
            // t = from + +d;
        }
        return arr;
    },

    getGroupedTrafficPoints(points, dayTimestamp = undefined, groupingMinutesNumber = 10) {
        if (points.length == 0) {
            return [];
        }
        if (dayTimestamp == undefined) {
            dayTimestamp = +points[0].t;
        }
        // console.log('getGroupedTrafficPoints: dayTimestamp = ', dayTimestamp);
        let from = +moment(+dayTimestamp).tz(DEFAULT_TIMEZONE).startOf('day');
        let to = +moment(+dayTimestamp).tz(DEFAULT_TIMEZONE).endOf('day');
        let t = from, step = groupingMinutesNumber * 60000;
        let arr = [], dataMap = {};
        let ge = a => (a == undefined ? {in: 0, out: 0, v: 0} : a);
        // console.log('from = ', from);
        for (let i in points) {
            let pt = points[i];
            let k = Math.floor((+pt.t - from) / step);
            let key = `${k * step}`;
            // console.log('key = ', key);
            dataMap[key] = ge(dataMap[key]);
            dataMap[key].in = +dataMap[key].in + +pt.in;
            dataMap[key].out = +dataMap[key].out + +pt.out;
            dataMap[key].v = +dataMap[key].v + +pt.v;
        }
        // console.log('dataMap = ', dataMap);
        while (t < to) {
            let key = `${+t - +from}`;
            dataMap[key] = ge(dataMap[key]);
            arr.push({t: +t, ...dataMap[key]});
            t = +t + +step;
        }
        return arr;
    },

    getTotalStatsByPoints(points) {
        let gn = a => (a == undefined ? 0 : a);
        let trMb = a => (a == undefined ? undefined : (+a / 1000000.0));
        let res = {
            in: 0,
            out: 0,
            v: 0
        };
        for (let i in points) {
            let p = points[i];
            res.in = +res.in + +gn(p.in);
            res.out = +res.out + +gn(p.out);
            res.v = +res.v + +gn(p.v);
        }
        res.in = trMb(res.in);
        res.out = trMb(res.out);
        res.v = trMb(res.v);
        return res;
    },

    isCurrentReservation(reservation) {
        if (reservation == undefined) {
            return false;
        }
        let now = +new Date();
        let {startTimestamp, endTimestamp} = reservation;
        return !(+now < +startTimestamp || +now > +endTimestamp);
    },

    getSortedSqlItems(arr, dateField = 'createdAt') {
        return arr.sort((a, b) => {
            let a_ = +new Date(a[dateField]);
            let b_ = +new Date(b[dateField]);
            return (+b_ - +a_)
        });
    }


};

export default CommonHelper;
