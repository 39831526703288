import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import UpdateCustomTariffForm from "../forms/UpdateCustomTariffForm";
import CityInfoTool from "../../cdek/tools/CityInfoTool";

import {TYPES_OPTIONS} from "../forms/UpdateCustomTariffForm";

const getFriendlyTypeName = type => {
    let arr = TYPES_OPTIONS.filter(a => (a.value == type));
    if (arr.length > 0) {
        return arr[0].label;
    }
    return 'N/A';
}

export default function ClientCustomTariffsPanel(props) {
    const {paykeeperId} = props;
    const [updT, setUpdT] = useState(0);
    const [tariffs, setTariffs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);
    const [addModalVisible, setAddModalVisible] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.get(`https://delivery.paykeeper.ru/auth/tariffs/pk/${paykeeperId}`).then(d => d.data).then(pld => {
            setTariffs(pld.result);
            setLoading(false);
        })
    }, [paykeeperId, updT]);

    const selectedTariff = (selectedId == undefined) ? undefined : tariffs.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            <ItemsList>

                <Table>
                    <Thead>
                    <Tr>
                        <Th>
                            №
                        </Th>
                        <Th>
                            Направление
                        </Th>
                        <Th>
                            Тип
                        </Th>
                        <Th>
                            Базовая стоимость
                        </Th>
                        <Th>
                            Базовый вес
                        </Th>
                        <Th>
                            Ст-ть за доп. кг
                        </Th>
                    </Tr>
                    </Thead>

                    <Tbody>
                    {tariffs.map((tar, i) => {
                        let {fromCode, toCode, type, basePrice, extPrice, baseWeight} = tar;
                        return (
                            <Tr key={tar.id} selected={(tar.id == selectedId)} onClick={() => {
                                setSelectedId(tar.id);
                            }}>
                                <Td>
                                    {`${+i + 1}`}
                                </Td>
                                <Td>
                                    <CityInfoTool code={fromCode}/>
                                    <span>
                                            {' - '}
                                        </span>
                                    <CityInfoTool code={toCode}/>
                                </Td>
                                <Td>
                                    {getFriendlyTypeName(tar.type)}
                                </Td>
                                <Td style={{textAlign: 'center'}}>
                                    <span style={{fontSize: 20, fontWeight: 'bold'}}>
                                        {`${basePrice}`}
                                    </span>
                                </Td>
                                <Td>
                                    {`${baseWeight} кг`}
                                </Td>
                                <Td>
                                    {`${extPrice}`}
                                </Td>
                            </Tr>
                        );
                    })}
                    </Tbody>

                </Table>

            </ItemsList>

            <AddMorePlaceholder>
                <AddMoreSpan onClick={() => {
                    setAddModalVisible(true);
                }}>
                    Добавить тариф
                </AddMoreSpan>
            </AddMorePlaceholder>

            <div style={{marginTop: 120}}>
                {'Ссылка на калькулятор с персональными ценами: '}
                <a href={`https://delivery.paykeeper.ru/?pkId=${paykeeperId}`} target={'_blank'}>
                    {`https://delivery.paykeeper.ru/?pkId=${paykeeperId}`}
                </a>
            </div>

            <Sidebar width={Math.min(1100, window.innerWidth)} visible={(selectedTariff != undefined)}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedTariff == undefined ? null :
                    <div>
                        <UpdateCustomTariffForm {...selectedTariff}
                                                loading={loading}
                                                canDelete={true}
                                                onSave={async d => {
                                                    setLoading(true);
                                                    let pld = (await axios.put(`https://delivery.paykeeper.ru/auth/tariff/${selectedTariff.id}`, {
                                                        ...d
                                                    }));
                                                    setUpdT(+new Date());
                                                    setLoading(false);
                                                    setSelectedId(undefined);
                                                }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar width={Math.min(1100, window.innerWidth)} visible={addModalVisible} onCloserClick={() => {
                setAddModalVisible(false);
            }}>

                {addModalVisible == false ? null :
                    <div>
                        <UpdateCustomTariffForm
                            loading={loading}
                            canDelete={false}
                            onSave={async d => {
                                setLoading(true);
                                let pld = (await axios.post(`https://delivery.paykeeper.ru/auth/tariffs`, {
                                    paykeeperId: paykeeperId,
                                    ...d
                                }));
                                setUpdT(+new Date());
                                setLoading(false);
                                setAddModalVisible(false);
                            }}/>
                    </div>
                }

            </Sidebar>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const ItemTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ItemContent = styled.div`
    
`;

const ItemsList = styled.div`
    
`;

const AddMorePlaceholder = styled.div`
    
`;

const AddMoreSpan = styled.span`
    cursor: pointer;
    text-decoration: underline;
    :hover{
      opacity: 0.8;
    }
`;

const Table = styled.table`
    width: 100%;
    border: 1px solid whitesmoke;
`;

const Tr = styled.tr`
    background: ${props => (props.selected ? 'blue' : 'white')};
    cursor: ${props => (props.selected ? 'default' : 'pointer')};
    color: ${props => (props.selected ? 'white' : 'black')};
    :hover{
      opacity: 0.8;
    }
`;

const Td = styled.td`
    border: 1px solid whitesmoke;
    padding: 4px`;

const Th = styled.th`
    
`;

const Thead = styled.thead`
    
`;

const Tbody = styled.tbody`
    
`;
