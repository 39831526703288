import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import HorChessTemplate from '../templates/HorChessTemplate'
import TariffsPanel from "../tariffs/panels/TariffsPanel";
import AllEventsPanel from "../events/panels/AllEventsPanel";

export default function EventsApp() {

    return (
        <HorChessTemplate active={`events`}>

            <AllEventsPanel/>

        </HorChessTemplate>
    );
}

const Wrapper = styled.div`
    
`;
