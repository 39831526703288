import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const TariffsAPI = {

    getAllTariffs() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/tariffs/all`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    getAllTariffsUsingCache() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/tariffs/all/cache`).then(d => d.data).then(exercises => {
                if (exercises.error != undefined) {
                    return reject(exercises.error);
                }
                resolve(exercises);
            }).catch(err => reject(err));
        });
    },

    getTariff(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/tariff/${id}`).then(d => d.data).then(doc => {
                if (doc.error != undefined) {
                    return reject(doc.error);
                }
                resolve(doc);
            }).catch(err => reject(err));
        });
    },

    createTariff(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/tariffs/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateTariff(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/tariffs/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteTariff(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/tariffs/delete`, {id: id}).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

}

export default TariffsAPI;
