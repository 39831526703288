import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";

import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import AnalysisHelper from "../../../helpers/AnalysisHelper";

export default function PricingDiffDistributionChart(props) {
    let {fromCode = 44, weight = 0.5, to = 'd'} = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/key/diff_${fromCode}_${weight}_${to}`).then(d => d.data.value).then(d => {
            setData(d);
        })
    }, [fromCode, weight, to]);

    const distrData = AnalysisHelper.getDistributionData(data, 20);

    return (
        <Wrapper>

            {/*{JSON.stringify(distrData)}*/}

            <ChartPlaceholder>
                <h3>
                    Распределение разницы стоимостей доставки от двери до {{'w': 'склада', 'd': 'двери'}[to]} гостю и пейкиперу ({data == undefined ? 0 : data.length} направлений), вес {`${weight} кг`}
                </h3>
                <BarChart
                    width={500}
                    height={300}
                    data={distrData}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="x"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Bar dataKey="k" fill="#8884d8"/>
                </BarChart>
            </ChartPlaceholder>



        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin-bottom: 30px;
`;

const ChartPlaceholder = styled.div`
    padding: 10px;
    background-color: white;
`;
