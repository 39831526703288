import CustomersAPI from '../../api/CustomersAPI'

import * as types from '../ActionTypes'

let loadCustomers_ = () => {
    return {
        type: types.LOAD_CUSTOMERS
    }
}
let loadCustomersSuccess = (customers) => {
    return {
        type: types.LOAD_CUSTOMERS_SUCCESS,
        customers: customers
    }
}
let loadCustomersFailed = (error) => {
    return {
        type: types.LOAD_CUSTOMERS_FAIL,
        error: error
    }
}

//thunk
export function loadAllCustomers() {
    return (dispatch, getState) => {
        dispatch(loadCustomers_());
        return CustomersAPI.getAllCustomers().then(
            customers => dispatch(loadCustomersSuccess(customers)),
            error => dispatch(loadCustomersFailed(error))
        )
    }
}

export function loadCustomerById(id) {
    return (dispatch, getState) => {
        dispatch(loadCustomers_());
        return CustomersAPI.getCustomer(id).then(
            customer => dispatch(loadCustomersSuccess([customer])),
            error => dispatch(loadCustomersFailed(error))
        )
    }
}

let createCustomer_ = () => {
    return {
        type: types.CREATE_CUSTOMER
    }
}
let createCustomerSuccess = (customer) => {
    return {
        type: types.CREATE_CUSTOMER_SUCCESS,
        customer: customer
    }
}
let createCustomerFailed = (error) => {
    return {
        type: types.CREATE_CUSTOMER_FAIL,
        error: error
    }
}

//thunk
export function createCustomer(data) {
    return (dispatch, getState) => {
        dispatch(createCustomer_());
        return CustomersAPI.createCustomer(data).then(
            customer => dispatch(createCustomerSuccess(customer)),
            error => dispatch(createCustomerFailed(error))
        )
    }
}

let updateCustomer_ = () => {
    return {
        type: types.UPDATE_CUSTOMER
    }
}
let updateCustomerSuccess = (customer) => {
    return {
        type: types.UPDATE_CUSTOMER_SUCCESS,
        customer: customer
    }
}
let updateCustomerFailed = (error) => {
    return {
        type: types.UPDATE_CUSTOMER_FAIL,
        error: error
    }
}

//thunk
export function updateCustomer(data) {
    return (dispatch, getState) => {
        dispatch(updateCustomer_());
        return CustomersAPI.updateCustomer(data).then(
            customer => dispatch(updateCustomerSuccess(customer)),
            error => dispatch(updateCustomerFailed(error))
        )
    }
}

let deleteCustomer_ = () => {
    return {
        type: types.DELETE_CUSTOMER
    }
}
let deleteCustomerSuccess = (id) => {
    return {
        type: types.DELETE_CUSTOMER_SUCCESS,
        id: id
    }
}
let deleteCustomerFailed = (error) => {
    return {
        type: types.DELETE_CUSTOMER_FAIL,
        error: error
    }
}

//thunk
export function deleteCustomer(id) {
    return (dispatch, getState) => {
        dispatch(deleteCustomer_());
        return CustomersAPI.deleteCustomer(id).then(
            () => dispatch(deleteCustomerSuccess(id)),
            error => dispatch(deleteCustomerFailed(error))
        )
    }
}
