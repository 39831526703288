import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Sidebar from 'arui-feather/sidebar'
import WidgetAPI from "../../../api/WidgetAPI";

import {Button} from "../../ui/Button";
import UpdateWidgetForm from "../forms/UpdateWidgetForm";

export default function ReleasesPanel(props) {
    const [releases, setReleases] = useState([]);
    const [selectedId, setSelectedId] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [addVisible, setAddVisible] = useState(false);

    useEffect(() => {
        setLoading(true);
        WidgetAPI.getAllReleases().then(arr => {
            setReleases(arr);
            setLoading(false);
        })
    }, []);

    let selectedWidget = (selectedId == undefined) ? undefined : releases.filter(x => (x.id == selectedId))[0];

    return (
        <Wrapper>

            <TopPlaceholder>
                <Button onClick={() => {
                    setAddVisible(true);
                }}>
                    Добавить
                </Button>
            </TopPlaceholder>

            <ListPlaceholder>
                {releases.map((r, i) => {
                    return (
                        <Item key={r.id} selected={(r.id == selectedId)} onClick={() => {
                            setSelectedId(r.id);
                        }}>
                            <Name>
                                {r.version}
                            </Name>
                            <UrlPlaceholder>
                                {r.widgetUrl}
                            </UrlPlaceholder>
                        </Item>
                    )
                })}
            </ListPlaceholder>

            <Sidebar visible={addVisible} onCloserClick={() => {
                setAddVisible(false);
            }}>

                {addVisible == false ? null :
                    <div>
                        <UpdateWidgetForm loading={loading} onSave={async d => {
                            setLoading(true);
                            await WidgetAPI.createRelease(d);
                            setAddVisible(false);
                            setLoading(false);
                        }}/>
                    </div>
                }

            </Sidebar>

            <Sidebar visible={(selectedWidget != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>

                {selectedWidget == undefined ? null :
                    <div>
                        <UpdateWidgetForm loading={loading} {...selectedWidget} onSave={async d => {
                            setLoading(true);
                            await WidgetAPI.updateRelease({...d, id: selectedWidget.id});
                            let arr = await WidgetAPI.getAllReleases();
                            setSelectedId(undefined);
                            setLoading(false);
                            setReleases(arr);
                        }}/>
                    </div>
                }

            </Sidebar>


        </Wrapper>
    );
}

const TopPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const Name = styled.div`
    
`;

const UrlPlaceholder = styled.div`
    
`;

const Wrapper = styled.div`
    
`;

const ListPlaceholder = styled.div`
    
`;

const Item = styled.div`
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid lightgrey;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: pointer;
`;
